import { injectable } from "inversify";
import * as Mustache from "mustache";
import * as moment from "moment";
import { NotificationApiClient } from "../ts/clients/notifications-api-client";
import template from "./obvestila-view.html";
import { TranslationService } from "../ts/translation-service";
import { TableWidget, TableWidgetTableMap } from "../../../skupno/src/ts/widgets/table-widget";
import ObvestiloPodrobnoModal from "./obvestila-podrobno-modal.html";
import { UrlHelper } from "../../../skupno/src/ts/utils/url-helper";

@injectable()
export class ObvestilaView {
    private _apiClient: NotificationApiClient;
    private _translationService: TranslationService;
    private notifications: Array<any>
    private itemsPerPage = 25;

    public constructor(notificationApiClient: NotificationApiClient, translationService: TranslationService) {
        this._apiClient = notificationApiClient;
        this._translationService = translationService;
        this.notifications = new Array<any>;
    }

    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {
        try {
            // Render new content
            this._renderData();
            var tableWidget = new TableWidget({
                tableMap: this.dataTableModel(),
                rootElement: $("#main").find(".obvestila-objavljena-table-placeholder"),
                getData: async (currentPage) => {
                    if (currentPage == 1) {
                        var page = UrlHelper.getPageFromUrl();
                        if (page != 1) {
                            currentPage = page;
                        }
                    }
                    
                    var data = await this._apiClient.getList(currentPage, this.itemsPerPage)
                    this.notifications = data.items;                    
                    data.items.forEach(function (item) {
                        if (item && item.created)
                            item.createdStr = moment(item.created).format("DD.MM.YYYY HH:mm");
                        else
                            item.createdStr = "";
                    });
                    return data;
                },
                onRender: (data) => {
                    $("#main .circle-number").html(data.totalCount.toString()); 
                    this.initSubscribtionModalButton();
                }
            });
            tableWidget.refresh(1, this._translationService.currentTranslations);

        } catch (e) {
            console.debug("error", e);
            // Clear previous content on error
            $('#main').text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _renderData(): void {

        // Build a view model from the API data
        const viewModel = {
            "imageUrlLogout": "../img/icon-logout.svg",
            "imageUrlArrowDown": "../img/icon-arrow-down.svg",
            translations: this._translationService.currentTranslations

        } as any;

        // Construct a template
        const htmlTemplate = template;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#main').html(html);

    }

    private _renderDetailsModal(notification: any): void {
        const viewModel = {
            data: notification,
            translations: this._translationService.currentTranslations
        } as any;
        const htmlTemplate = ObvestiloPodrobnoModal;
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#notifications-details-modal').html(html);
    }

    public initSubscribtionModalButton() {
        var self = this;
        $("#main").find(".notification-details-btn").on("click", function (ev) {
            const target = ev.currentTarget as HTMLInputElement;
            var dataId = target.getAttribute("data-id");
            if (!dataId) {
                return;
            }
            var id = parseInt(dataId);
            
            var notification = self.notifications.find((el) => el.id == id);
            if (!notification) {
                return;
            }
            self._renderDetailsModal(notification);
            $("#notifications-details-modal").modal("show");
        });
    }


    private dataTableModel(): TableWidgetTableMap {
        var translations = this._translationService.currentTranslations;
        const tableMap: TableWidgetTableMap = {
            title: translations.AllNotifications + '<span class="circle-number">0</span>',
            controls: "",
            noDataContent: translations.NoData,
            classInsert: "table-wrapper",
            columns: [
                {
                    name: translations.Subject,
                    //html: '<a class="notification-details-btn" data-id="{{id}}" href="javascript:void(0)">{{subject}}</a>'
                    html: '<a href="#/obvestila/{{id}}">{{subject}}</a>'
                },
                {
                    name: translations.From,
                    value: "author"
                },
                {
                    name: translations.Published,
                    class: "col-sort",
                    value: "createdStr"
                }
            ]
        };

        return tableMap;
    }
}