
import { injectable, inject } from "inversify";
import { TranslationService } from "../../ts/translation-service";
// import { UrlHelper } from "../../../../skupno/src/ts/utils/url-helper";
import * as Mustache from "mustache";
// import {ApiClient } from "../../ts/clients/-api-client";
import template from "./reservations.view.html";
import { Action, Domain, parseEnum } from "../../ts/models/reservations/reservations-enums";
// import * as moment from "moment";
// import * as DateRangePicker from 'daterangepicker';

@injectable()
export class ReservationsView {
    // private _apiClient: RevisionApiClient;
    public conferenceRoom = document.getElementById('conferenceRoom');
    public conferneceRoomReserved = false;
    public desk1Reserved = false;
    public desk2Reserved = false;
    public desk3Reserved = false;
    public desk4Reserved = false;


    public constructor(
        // @inject(RevisionApiClient) ApiClient: RevisionApiClient,
        @inject(TranslationService) private translationService: TranslationService,
    ) {
        // this._apiClient = ApiClient;
    }
    /*
     * Wait for data then render it
     */
    public async load(): Promise<any> {
        try {
            // var translations: any = await this.translationService.currentTranslations
            this._renderData();
            // var rootElement = $("#main").find(".revision-table-placeholder");
        } catch (e) {
            $('#main').text(this.translationService.currentTranslations["LoadingError"]);
            throw e;
        }

        this.initReservationFields();
        this.initButtons();
    }

    private _renderData(): void {
        // Build a view model from the API data
        const viewModel = {
            "imageUrlLogout": "../img/icon-logout.svg",
            "imageUrlDownload": "../img/icon-download.svg",
            "imageUrlArrowDown": "../img/icon-arrow-down.svg",
            "imageUrlDocument": "../img/icon-document.svg",
            "imageUrlGreenDot": "../img/icon-green-dot.svg",
            "imageUrlVerticalDots": "../img/icon-vertical-dots.svg",
            "imageUrlCalendarSmall": "../img/icon-calendar-small.svg",
            "imageUrlLocationSmall": "../img/icon-location-small.svg",
            translations: this.translationService.currentTranslations,
            actions: parseEnum(Action).map(action => ({
                value: action.value,
                label: this.translationService.currentTranslations[action.label] || action.label
            })),
            
            // Map domains directly if only value is needed
            domains: parseEnum(Domain).map(domain => ({
                value: domain.value,
                label: this.translationService.currentTranslations[domain.label] || domain.label
            }))
        } as any;

        // Construct a template
        const htmlTemplate = template;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#main').html(html);
    }

    public initButtons() {
        $("#conferenceRoom").on('click', async() => {
            this.reserveConferenceRoom();
        });
        $("#desk1").on('click', async (e) => {
            var id: any = e.target.getAttribute("value");
            if (id != null) {
                $("#import-coupons-form").find("#id").val(id);
                $("#import-modal").modal("show");
            }
        });
    }

    public initReservationFields() {
        this.conferneceRoomReserved = false;
        this.desk1Reserved = false;
        this.desk2Reserved = false;
        this.desk3Reserved = false;
        this.desk4Reserved = false;
    }
   
    public reserveConferenceRoom(): void {
        if (!this.conferneceRoomReserved) {
            this.conferneceRoomReserved = true;

            if (this.conferenceRoom) {
                this.conferenceRoom.style.backgroundColor = 'rgba(197, 112, 15, 0.5)';
            }
        } else {
            this.conferneceRoomReserved = false;

            if (this.conferenceRoom) {
                this.conferenceRoom.style.backgroundColor = '';
            }
        }
    }
}