import { inject, injectable, interfaces } from "inversify";
import { PageBase } from "../../../../skupno/src/ts/routing/page-base";
import { MainLayout } from "../../views/main-layout";
import { Router } from "../../../../skupno/src/ts/routing/router";
import { SportEventApiClient } from "../clients/sport-event-api-client";
import { TranslationService } from "../translation-service";
import { Notyf } from "notyf";
import { ErrorLoadingView } from "../../views/error-loading-view";
import { SportDogodekPrijavljeniView } from "../../views/sport-dogodek-prijavljeni-view";

@injectable()
export class SportDogodekPrijavljeniPage extends PageBase {
    public name = "SportDogodekPrijavljeniPage";
    private _apiClient: SportEventApiClient;
    private _mainLayoutProvider: interfaces.Provider<MainLayout>;
    private _router: Router;
    private _translationService: TranslationService;
    private _sportDogodekPrijavljeniView: SportDogodekPrijavljeniView;
    private _notyf: Notyf;
    private _errorLoadingView: ErrorLoadingView;

    public constructor(@inject("Provider<MainLayout>") mainLayoutProvider: interfaces.Provider<MainLayout>,
        @inject("SportEventApiClient") apiClient: SportEventApiClient,
        @inject("Router") router: Router,
        @inject("TranslationService") translationService: TranslationService,
        @inject("Notyf") notyf: Notyf
    ) {
        super();
        this._mainLayoutProvider = mainLayoutProvider;
        this._apiClient = apiClient;
        this._notyf = notyf;
        this._router = router;
        this._translationService = translationService;
        this._sportDogodekPrijavljeniView = new SportDogodekPrijavljeniView(this._apiClient, this._translationService, this._notyf);
        this._errorLoadingView = new ErrorLoadingView(this._translationService);
    }

    public async execute(): Promise<boolean> {
        var url = this._router.getVirtualUrl();
        if (!url.pathname.startsWith("/sport/dogodek/prijavljeni/")) {
            return true;
        }
        var eventId: number | null = null;
        var eventIdString: string = url.pathname.substr("/sport/dogodek/prijavljeni/".length);
        if (eventIdString) {
            eventId = parseInt(eventIdString, 10);
        }
        if (!eventId) {
            return true;
        }

        var mainLayout = await this._mainLayoutProvider() as MainLayout; try {
            var item = await this._apiClient.getEvent(eventId);
            var translations = this._translationService.currentTranslations;
            mainLayout.pageTitle = translations.Sport + " - <span class=\"purple\">" + translations.Subscriptions + " - " + item.title + "</span>";
            mainLayout.htmlTitle = translations.Sport + " - " + translations.Subscriptions + "-" + item.title;
            mainLayout.profileSidebarViewHide();
            await this._sportDogodekPrijavljeniView.load(item);
        } catch (e) {
            // Report failures
            console.log(e);
            this._notyf.error(this._translationService.currentTranslations.LoadingError);
            this._errorLoadingView.load();
        }

        return false;
    }
}