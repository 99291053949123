import { injectable } from "inversify";
import * as Mustache from "mustache";
import { Notyf } from "notyf";
import { RoleApiClient } from "../ts/clients/roles-api-client";
import { VlogaDetailsResponse } from "../ts/models/vloga-details-response";
import { TranslationService } from "../ts/translation-service";
import template from "./management-vloge-pregled-view.html";

@injectable()
export class ManagementVlogePregledView {
    private _apiClient: RoleApiClient;

    public constructor(roleApiClient: RoleApiClient, private translationService: TranslationService, private notyf: Notyf)
    {
        this._apiClient = roleApiClient;
    }
    /*
     * Wait for data then render it
     */
    public async load(vlogaId: number): Promise<void> {

        try {
            await this._apiClient.roleDetails(vlogaId)
                .then((vloga) => {
                    // Render new content
                    this._renderData(vloga);
                })
                .catch((ex) => {
                    $('#main').text(this.translationService.currentTranslations[ex.message]);
                });

        } catch (e) {

            // Clear previous content on error
            $('#main').text(this.translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _renderData(data: VlogaDetailsResponse): void {

        // Build a view model from the API data
        const viewModel = {
            model: data.vloga,
            permissions: this.razporediPoStolpcih(data),
            userCount: data.userCount,
            allPermissions: data.allPermissions,
            translations: this.translationService.currentTranslations
        };

        // Construct a template
        const htmlTemplate = template;
        
        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#main').html(html);
        $("#vloge-pregled-form").on("submit", (ev) => this.onVlogeSubmit(ev, data.vloga.id));
    }

    private onVlogeSubmit(ev: JQuery.SubmitEvent, userId: number) {
        ev.preventDefault();

        var izbraneVloge = $("#vloge-pregled-form [name=permission]:checked").map((_index, element) => parseInt($(element).attr("value") as string, 10)).toArray();
        this._apiClient.roleShrani(userId, {
            selectedPermissions: izbraneVloge            
        })
            .then(() => {
                this.load(userId);
                this.notyf.success(this.translationService.currentTranslations.SaveSuccessful);
            })
            .catch(() => {
                this.notyf.error(this.translationService.currentTranslations.SaveError);
            });
    }


    private razporediPoStolpcih(vloge: VlogaDetailsResponse): Array<Array<any>> {
        var izbraneVloge = vloge.selectedPermissions;
        var vseVloge = vloge.allPermissions;

        var stolpec1Length = Math.ceil(vseVloge.length / 2);
        var stolpec2Length = vseVloge.length - stolpec1Length;
        if (stolpec1Length == 0) {
            return new Array(0);
        }

        var rezultat: Array<Array<any>> = new Array(stolpec2Length > 0 ? 2 : 1);
        var trenutniStolpec = new Array<any>(stolpec1Length);
        rezultat[0] = trenutniStolpec;
        var trenutniStolpecIndex = 0;
        for (var i = 0; i < vseVloge.length; i++, trenutniStolpecIndex++) {
            var vloga = vseVloge[i];
            var vlogaSelected = izbraneVloge.indexOf(vloga.id) >= 0;

            trenutniStolpec[trenutniStolpecIndex] = {
                id: vloga.id,
                name: vloga.name,
                selected: vlogaSelected
            };

            if (i == stolpec1Length - 1) {
                trenutniStolpec = new Array<any>(stolpec2Length);
                rezultat[1] = trenutniStolpec;
                trenutniStolpecIndex = -1;
            }
        } 
        return rezultat;
    }
}