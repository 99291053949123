import { inject, injectable } from "inversify";
import { PageBase } from "../../../../skupno/src/ts/routing/page-base";
import { Router } from "../../../../skupno/src/ts/routing/router";
import { PotrditevEmailaView } from "../../views/potrditev-emaila-view";
import { TranslationService } from "../translation-service";
import { Notyf } from "notyf";
import { ErrorLoadingView } from "../../views/error-loading-view";
import { UserApiClient } from "../clients/users-api-client";
import { FullscreenLoader } from "../../views/fullscreen-loader";

@injectable()
export class PotrditevEmailaPage extends PageBase {
    public name = "potrditevEmailaPage";
    private _router: Router;
    private _potrditevEmailaView: PotrditevEmailaView;
    private _apiClient: UserApiClient;
    private _translationService: TranslationService;
    private _notyf: Notyf;
    private _errorLoadingView: ErrorLoadingView;

    public constructor(@inject("UserApiClient") apiClient: UserApiClient,
        @inject("Router") router: Router,
        @inject("TranslationService") translationService: TranslationService,
        @inject("Notyf") notyf: Notyf,
        @inject("FullscreenLoader") loader: FullscreenLoader
    ) {
        super();
        this._router = router;
        this._notyf = notyf;
        this._apiClient = apiClient;
        this._translationService = translationService;
        this._potrditevEmailaView = new PotrditevEmailaView(this._apiClient, translationService, loader);
        this._errorLoadingView = new ErrorLoadingView(this._translationService);
    }

    public async execute(): Promise<boolean> {
        var url = this._router.getVirtualUrl();
        if (!url.pathname.startsWith("/email-confirmation/")) {
            return true;
        }
        var hash: string = url.pathname.substr("/email-confirmation/".length);

        try {
            await this._potrditevEmailaView.load(hash);
        } catch (e) {
            // Report failures
            this._notyf.error(this._translationService.currentTranslations.LoadingError);
            this._errorLoadingView.load();
        }

        return false;
    }
}