import { inject, injectable, interfaces } from "inversify";
import { PageBase } from "../../../../skupno/src/ts/routing/page-base";
import { Notyf } from "notyf";
import { Router } from "../../../../skupno/src/ts/routing/router";
import { ErrorLoadingView } from "../../views/error-loading-view";
import { MainLayout } from "../../views/main-layout";
import { TranslationService } from "../translation-service";
import { RevisionView } from "../../views/revision/revision.view";
import { RevisionApiClient } from "../clients/revision-api-client";

@injectable()
export class RevisionViewPage extends PageBase {
    public name = "RevisionViewPage";
    private _mainLayoutProvider: interfaces.Provider<MainLayout>;
    private _router: Router;
    private _apiClient: RevisionApiClient;
    private _translationService: TranslationService;
    private _revisionView: RevisionView;
    private _notyf: Notyf;
    private _errorLoadingView: ErrorLoadingView;

    public constructor(
        @inject("Provider<MainLayout>") mainLayoutProvider: interfaces.Provider<MainLayout>,
        @inject("Router") router: Router,
        @inject("RevisionApiClient") apiClient: RevisionApiClient,
        @inject("Notyf") notyf: Notyf,
        @inject("TranslationService") translationService: TranslationService,
    ) {
        super();
        this._mainLayoutProvider = mainLayoutProvider;
        this._router = router;
        this._apiClient = apiClient;
        this._translationService = translationService;
        this._notyf = notyf;
        this._revisionView = new RevisionView(this._apiClient, this._translationService);
        this._errorLoadingView = new ErrorLoadingView(this._translationService);
    }

    public async execute(): Promise<boolean> {
        var url = this._router.getVirtualUrl();
        if (!url.pathname.startsWith("/revizija/pogled/")) {
            return true;
        }

        var revisionId: number | null = null;
        var revisionIdSting: string = url.pathname.substr("/revizija/pogled/".length);
        if (revisionIdSting) {
            revisionId = parseInt(revisionIdSting, 10);
        }
        if (!revisionId) {
            return true;
        }

        var mainLayout = await this._mainLayoutProvider() as MainLayout; try {
            var translations = this._translationService.currentTranslations;
            mainLayout.pageTitle = translations.Revision + " - <span class=\"purple\">" + translations.Overview + "</span>";
            mainLayout.htmlTitle = translations.Revision + " - " + translations.Overview + "-" + translations.Overview;
            mainLayout.profileSidebarViewHide();
            await this._revisionView.load(revisionId);
        } catch (e) {
            // Report failures
            console.log(e);
            this._notyf.error(this._translationService.currentTranslations.LoadingError);
            this._errorLoadingView.load();
        }

        return false;
    }
}