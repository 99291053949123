import * as Mustache from "mustache";

export class ModalConfirm {
    static html: string = `
    <div class="modal actions modal-confirm" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <div class="title">{{options.content}}</div>
          <div class="btn-row">
            <button type="button" class="btn btn-light" data-bs-dismiss="modal">{{options.cancelText}}</button>
            <button type="button" class="btn confirm {{btnConfirmClass}}">{{options.confirmText}}</button>
          </div>
      </div>
    </div>
  </div>
</div>`;

    public static confirm(options: ModalConfirmOptions): Promise<boolean> {

        var modalElement = $("body .modal-confirm");
        var modalElementExist = modalElement.length > 0;


        var modalHtml = Mustache.render(ModalConfirm.html, { options: options, btnConfirmClass: options.confirmType == 'splosno' ? 'btn-primary' : 'btn-danger btn-delete' });
        if (modalElementExist) {
            modalElement.replaceWith(modalHtml);
        }
        else {
            modalElement = $(modalHtml);
            $("body").append(modalElement);
        }

        var promise = new Promise<boolean>((resolve, reject) => {
            var resolved = false;
            modalElement.find(".confirm").on("click", () => {
                resolved = true;
                resolve(true);
                modalElement.modal("hide");
            });
            modalElement.on("hide.bs.modal", () => {
                if (!resolved) {
                    reject(false);
                }
            });
        });
        modalElement.modal("show");
        return promise;
    }
}

export interface ModalConfirmOptions {
    content: string;
    cancelText: string;
    confirmText: string;
    confirmType: 'splosno' | 'brisanje';
}