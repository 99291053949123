import { inject, injectable } from "inversify";
import * as moment from "moment";
import { TranslationsApiClient } from "./clients/translations-api-client";
import { Lock } from "../../../skupno/src/ts/utils/lock";

@injectable()
export class TranslationService {
    public constructor(@inject(TranslationsApiClient) private translationsApiClient: TranslationsApiClient) {

    }

    public async initialize(): Promise<void> {
        await this.setCurrentLanguage("sl"); //TODO: prebrati iz pi�kotka
    }

    private _currentLanguage: string = "sl";
    private translationsEn: any | null = null;
    private translationsSl: any | null = null;
    private _currentTranslations: any;
    private _languageLock: Lock = new Lock();

    public getTranslation(translationKey: string): string {
        return this._currentTranslations[translationKey] || translationKey;
    }

    public get currentTranslations(): any {
        return this._currentTranslations;
    }

    public get currentLanguage(): string {
        return this._currentLanguage;
    }

    public setTranslation(translationKey: string, translationValue: string): void {
        if (!this._currentTranslations) {
            this._currentTranslations = {};
        }
        this._currentTranslations[translationKey] = translationValue;
    }
    

    public async setCurrentLanguage(currentLanguage: string): Promise<void> { 
        if (!currentLanguage) {
            return;
        }
        this._currentLanguage = currentLanguage;

        if (currentLanguage == "en") {
            if (this.translationsEn == null) {
                const releaser = await this._languageLock.acquire();
                try {
                    if (this.translationsEn == null) {
                        this.translationsEn = await this.translationsApiClient.getTranslations(currentLanguage);
                    }
                }
                finally {
                    releaser();
                }
                this._currentTranslations = this.translationsEn;
            }
            this._currentTranslations = this.translationsEn;
        }
        else if (currentLanguage == "sl") {
            if (this.translationsSl == null) {
                const releaser = await this._languageLock.acquire();                
                try {
                    if (this.translationsSl == null) {
                        this.translationsSl = await this.translationsApiClient.getTranslations(currentLanguage);
                    }
                }
                finally {
                    releaser();
                }
            }
            this._currentTranslations = this.translationsSl;
        }
        var momentjsLocale = (currentLanguage == "en" ? "en-gb" : currentLanguage);
        require("moment/locale/" + momentjsLocale);

        if (currentLanguage != "en") {
            require("jquery-validation/dist/localization/messages_" + currentLanguage);
        }
        document.documentElement.lang = currentLanguage;

        moment.locale(momentjsLocale);
    }
    

}