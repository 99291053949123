export class FullscreenLoader {
    private static htmlTemplate = `<div class="fullscreen-loader"><div class="spinner-grow" style="width: 3rem; height: 3rem;" role="status"></div></div>`;
    private static backdropHtml = '<div class="fullscreen-backdrop fade"></div>';
    private loaderElement: JQuery<HTMLElement> | null = null;
    private backdropElement: JQuery<HTMLElement> | null = null;
    private init: boolean = true;
    constructor(private rootElement: JQuery<HTMLElement>) {
    }

    public show() {
        if (this.init) {
            return;
        }
        if (this.loaderElement == null) {
            this.loaderElement = $(FullscreenLoader.htmlTemplate).appendTo(this.rootElement);
        }
        if (this.backdropElement == null) {
            this.backdropElement = $(FullscreenLoader.backdropHtml).appendTo(this.rootElement);
        }
        this.loaderElement.show();
        this.backdropElement.addClass("show").show();
    }

    public hide() {
        if (this.init) {
            //odstranimo za?etni loader
            $(".fullscreen-loader").remove();
            $(".fullscreen-backdrop").remove();
            this.init = false;
        }
        if (this.loaderElement != null) {
            this.loaderElement.hide();
        }
        if (this.backdropElement != null) {
            this.backdropElement.removeClass("show").hide();
        }
    }
}