import { injectable } from "inversify";
import * as Mustache from "mustache";
import { CampusApiClient } from "../ts/clients/campus-api-client";
import template from "./management-campus-view.html";
import { TableWidget, TableWidgetTableMap } from "../../../skupno/src/ts/widgets/table-widget";
import { TranslationService } from "../ts/translation-service";

@injectable()
export class ManagementCampusView {
    private _apiClient: CampusApiClient;
    private _translationService: TranslationService;
    private itemsPerPage = 25;

    public constructor(campusApiClient: CampusApiClient, translationService: TranslationService) {
        this._apiClient = campusApiClient;
        this._translationService = translationService;
    }
    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {

        try {
            
            this._renderData();
            var tableWidget = new TableWidget({
                tableMap: this.dataTableModel(),
                rootElement: $("#main").find(".campus-table-placeholder"),
                getData: async (currentPage) => {
                    return await this._apiClient.getList(currentPage, this.itemsPerPage);
                }
            });
            tableWidget.refresh(1, this._translationService.currentTranslations);

        } catch (e) {

            // Clear previous content on error
            $('#main').text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }



    private _renderData(): void {

        // Build a view model from the API data
        const viewModel = {
            "imageUrl": "../img/icon-logout.svg",
            translations: this._translationService.currentTranslations
        } as any;

        // Construct a template
        const htmlTemplate = template;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#main').html(html); 
    }

    private dataTableModel(): TableWidgetTableMap {
        var translations = this._translationService.currentTranslations;
        const tableMap = {
            title: translations["Campus"] ?? "Campus",
            classInsert: "campus-table-placeholder",
            noDataContent: translations.NoData,
            columns: [
                {
                    name: translations["Id"] ?? "ID",
                    value: "id"
                },
                {
                    name: translations["Name"] ?? "Name",
                    value: "name"
                },
                {
                    name: translations["LocationsCount"] ?? "Locations count",
                    value: "locations.length"
                }

            ]
        };
        return tableMap;
    }
}