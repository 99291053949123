import { inject, injectable, interfaces } from "inversify";
import { PageBase } from "../../../../skupno/src/ts/routing/page-base";
import { MainLayout } from "../../views/main-layout";
import { Router } from "../../../../skupno/src/ts/routing/router";
import { NamizjeView } from "../../views/namizje-view";
import { TranslationService } from "../translation-service";
import { Notyf } from "notyf";
import { DesktopApiClient } from "../clients/desktop-api-client";
import { ErrorLoadingView } from "../../views/error-loading-view";
import { CurrentUserAccessor } from "../utilities/current-user-accessor";
import { NoticeApiClient } from "../clients/notice-api-client";
import { KuponModal } from "../../views/kupon-modal";
import { UserApiClient } from "../clients/users-api-client";
import { InvoiceApiClient } from "../clients/invoice-api-client";
/*import { DamagesApiClient } from "../clients/damage-api-client";*/

@injectable()
export class NamizjePage extends PageBase
{   
    public name = "NamizjePage";
    private _apiClient: DesktopApiClient;
    private _userApiClient: UserApiClient;
    private _noticeApiClient: NoticeApiClient;
    private _invoiceApiClient: InvoiceApiClient;

   /* private _damagesApiClient: DamagesApiClient;*/
    private _mainLayoutProvider: interfaces.Provider<MainLayout>;
    private _router: Router;
    private _namizjeView: NamizjeView;
    private _translationService: TranslationService;
    private _notyf: Notyf;
    private _errorLoadingView: ErrorLoadingView;
    private _kuponModal: KuponModal;

    public constructor(@inject("Provider<MainLayout>") mainLayoutProvider: interfaces.Provider<MainLayout>,
        @inject("Router") router: Router,
        @inject("TranslationService") translationService: TranslationService,        
        @inject("NoticeApiClient") noticeApiClient: NoticeApiClient,
        /*@inject("DamagesApiClient") damagesApiClient: DamagesApiClient,*/
        @inject("DesktopApiClient") apiClient: DesktopApiClient,
        @inject("Notyf") notyf: Notyf,
        @inject("CurrentUserAccessor") currentUserAccessor: CurrentUserAccessor,
        @inject("KuponModal") kuponModal: KuponModal,
        @inject("UserApiClient") userApiClient: UserApiClient,
        @inject("InvoiceApiClient") invoiceApiClient: InvoiceApiClient
    ) {
        super();
        this._mainLayoutProvider = mainLayoutProvider;
        this._router = router;
        this._translationService = translationService;
        this._notyf = notyf;
        this._apiClient = apiClient;   
        this._userApiClient = userApiClient;   
        this._noticeApiClient = noticeApiClient;   
        this._invoiceApiClient = invoiceApiClient;
        this._kuponModal = kuponModal;   
        /*this._damagesApiClient = damagesApiClient;  */
        this._namizjeView = new NamizjeView(this._apiClient, this._noticeApiClient, this._userApiClient, translationService, currentUserAccessor, this._kuponModal, this._notyf, this._invoiceApiClient);
        this._errorLoadingView = new ErrorLoadingView(this._translationService);
    }

    public async execute(): Promise<boolean> {
        var url = this._router.getVirtualUrl();
        if (url.pathname != "" && url.pathname != "/") {
            return true;
        }
        var mainLayout = await this._mainLayoutProvider() as MainLayout;
        try {
            var translations = this._translationService.currentTranslations;
            mainLayout.pageTitle = translations.Desktop;
            mainLayout.htmlTitle = translations.Desktop;
            mainLayout.headerUserViewHide();
            mainLayout.profileSidebarViewShow();
            await this._namizjeView.load();
        } catch (e) {
            // Report failures
            this._notyf.error(this._translationService.currentTranslations.LoadingError);
            this._errorLoadingView.load();
        }

        return false;
    }
}