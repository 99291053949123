import { parse } from "content-disposition-header";
import { inject, injectable } from "inversify";
import { ApiClientBase } from "../../../../skupno/src/ts/api-client/api-client";
import { Authenticator } from "../../../../skupno/src/ts/authentication/authenticator";
import { Configuration } from "../../../../skupno/src/ts/configuration/configuration";
import { CouponCampaign } from "../models/coupon-campaign";
import { DownloadedFile } from "../models/downloaded-file";
import { ListResponse } from "../models/list-response";

@injectable()
export class CouponsApiClient extends ApiClientBase {
    public name = "CouponsApiClient";
    public constructor(
        @inject("Configuration") configuration: Configuration,
        @inject("Authenticator")  authenticator: Authenticator) {
        super(configuration, authenticator);
    }

    public async getList(page?: number, limit?: number): Promise<ListResponse<CouponCampaign>> {
        const data: JSON = <JSON><unknown>{
            "page": page,
            "limit": limit
        };
        return await this._callApi('/coupons/list', 'GET', data) as ListResponse<CouponCampaign>;
    }

    public async getCampaign(id: number): Promise<CouponCampaign> {
        return await this._callApi('/coupons/campaign/' + id, 'GET') as CouponCampaign;
    }

    public async publishCampaign(id: number): Promise<any> {
        return await this._callApi(`/coupons/publish/${id}`, 'PUT') as any;
    }

    public async archieveCampaign(id: number): Promise<any> {
        await this._callApi(`/coupons/archieve/${id}`, 'PUT').catch(err => console.log(err));
    }

    public async createCampaign(data: FormData): Promise<number> {
        return await this._callApi(`/coupons/campaign/add`, 'POST', data, true, "", null, null, (settings) => {
            settings.processData = false;
            settings.contentType = false;
        }) as number;
    }

    public async updateCampaign(data: FormData): Promise<number> {
        return await this._callApi(`/coupons/campaign/edit`, 'PUT', data, true, "", null, null, (settings) => {
            settings.processData = false;
            settings.contentType = false;
        }) as number;
    }

    public async preview(id: number): Promise<DownloadedFile> {
        const data = {};
        var file: DownloadedFile = null as any;

        await this._callApi('/coupons/campaign/overview/' + id, 'GET', data, true, "binary", (data, _status, jqueryXhr: JQueryXHR) => {
            var contentDisposition = jqueryXhr.getResponseHeader("Content-Disposition");
            var filename: string = "";
            if (contentDisposition) {
                var contentDispositionParsed = parse(contentDisposition as string);
                filename = contentDispositionParsed.parameters.filename;
            }
            var filetype = jqueryXhr.getResponseHeader("Content-Type");
            file = {
                fileName: filename,
                fileType: filetype,
                fileContent: data
            } as DownloadedFile;
        });

        return file;
    }

    public async downloadAttachment(id: number): Promise<DownloadedFile> {
        const data = {};
        var file: DownloadedFile = null as any;

        await this._callApi('/coupons/downloadAttachment/' + id, 'GET', data, true, "binary", (data, _status, jqueryXhr: JQueryXHR) => {
            var contentDisposition = jqueryXhr.getResponseHeader("Content-Disposition");
            var filename: string = "";
            if (contentDisposition) {
                var contentDispositionParsed = parse(contentDisposition as string);
                filename = contentDispositionParsed.parameters.filename;
            }
            var filetype = jqueryXhr.getResponseHeader("Content-Type");
            file = {
                fileName: filename,
                fileType: filetype,
                fileContent: data
            } as DownloadedFile;
        });

        return file;
    }

    public async importCoupons(id: string, text: string): Promise<any> {
        var data = {
            "id": id,
            "text": text
        };
        return await this._callApi('/coupons/import/', 'POST', JSON.stringify(data), true, "") as any;
    }

    public async getCoupon(): Promise<DownloadedFile> {
        const data = {};
        var file: DownloadedFile = null as any;

        await this._callApi('/coupons/getCoupon', 'GET', data, true, "binary", (data, _status, jqueryXhr: JQueryXHR) => {
            var contentDisposition = jqueryXhr.getResponseHeader("Content-Disposition");
            var filename: string = "";
            if (contentDisposition) {
                var contentDispositionParsed = parse(contentDisposition as string);
                filename = contentDispositionParsed.parameters.filename;
            }
            var filetype = jqueryXhr.getResponseHeader("Content-Type");
            file = {
                fileName: filename,
                fileType: filetype,
                fileContent: data
            } as DownloadedFile;
        });

        return file;
    }

    public async getCampaignUiMessage (): Promise<any> {
        return await this._callApi('/coupons/getCampaignUiMessage', 'GET') as any;
    }

    public async getActiveCampaignDate(): Promise<any> {
        return await this._callApi('/coupons/getActiveCampaignDate', 'GET') as any;
    }
}