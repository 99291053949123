// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("../img/menu-image-top-left.svg");
var ___HTML_LOADER_IMPORT_1___ = require("../img/menu-image-top-right.svg");
var ___HTML_LOADER_IMPORT_2___ = require("../img/menu-image-bottom-left.svg");
var ___HTML_LOADER_IMPORT_3___ = require("../img/menu-image-bottom-right.svg");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var ___HTML_LOADER_REPLACEMENT_3___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_3___);
var code = "<div class=\"login-bg-wrap\"> <div class=\"login-wrap\"> <div class=\"login-welcome\"> <p>{{translations.WelcomeTo}}<br/><strong>{{translations.MyStudent}}</strong>.</p> <p>{{translations.PortalForAllResidentsOfStudentResidencesLjubljana}}</p> </div> <div class=\"login-form-wrap\"> <div class=\"image-top-left\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\"> </div> <div class=\"image-top-right\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\"> </div> <div class=\"image-bottom-left\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\"> </div> <div class=\"image-bottom-right\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_3___ + "\"> </div> <div class=\"login-header\"> <div class=\"logo\"> <img src=\"{{imageUrlLogo}}\" class=\"img-fluid\"/> </div> <div class=\"welcome\"><strong>{{translations.MyStudent}}</strong><br/>{{message}}</div> </div> <div class=\"login-form\"> <div class=\"row g-4\"> <div class=\"col-12\"> <a href=\"/\" class=\"btn btn-primary w-100\">{{translations.Continue}}</a> </div> </div> </div> </div> </div> <div class=\"login-footer-wrap\"> <div class=\"login-footer\"> <div class=\"copyright\">{{translations.StudentResidencesLjubljanaAllRightsReservedWeReserveTheRightToCorrectSpellingAndSystemErrors}}</div> </div> </div> </div>";
// Exports
module.exports = code;