import { inject, injectable } from "inversify";
import { ApiClientBase } from "../../../../skupno/src/ts/api-client/api-client";
import { Authenticator } from "../../../../skupno/src/ts/authentication/authenticator";
import { Configuration } from "../../../../skupno/src/ts/configuration/configuration";
import { ListResponse } from "../models/list-response";
import { Campus } from "../models/campus";
import { DownloadedFile } from "../models/downloaded-file";
import { parse } from "content-disposition-header";

@injectable()
export class InvoiceApiClient extends ApiClientBase {
    public name = "InvoiceApiClient";
    public constructor(
        @inject("Configuration") configuration: Configuration,
        @inject("Authenticator")  authenticator: Authenticator) {
        super(configuration, authenticator);
    }

    public async getList(dateFrom?: string, dateTo?: string, page?: number, limit?: number): Promise<ListResponse<Campus>> {
        const data: JSON = <JSON><unknown>{
            "page": page,
            "maxRows": limit,
            "dateFrom": dateFrom,
            "dateTo": dateTo
        };
        return await this._callApi('/invoice/', 'GET', data) as ListResponse<Campus>;
    }

    public async documentDownload(documentId: number): Promise<DownloadedFile> {
        const data = {};
        var file: DownloadedFile = null as any;

        await this._callApi('/invoice/' + documentId + "/download", 'GET', data, true, "binary", (data, _status, jqueryXhr: JQueryXHR) => {
            var contentDisposition = jqueryXhr.getResponseHeader("Content-Disposition");
            var filename: string = "";
            if (contentDisposition) {
                var contentDispositionParsed = parse(contentDisposition as string);
                filename = contentDispositionParsed.parameters.filename;
            }
            var filetype = jqueryXhr.getResponseHeader("Content-Type");
            file = {
                fileName: filename,
                fileType: filetype,
                fileContent: data
            } as DownloadedFile;
        });

        return file;
    }
}