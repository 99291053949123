import { Container, interfaces } from "inversify";
import { Application } from "../../../skupno/src/ts/application";
import { Authenticator } from "../../../skupno/src/ts/authentication/authenticator";
import { ConfigurationLoader } from "../../../skupno/src/ts/configuration/configuration-loader";
import { Router } from "../../../skupno/src/ts/routing/router";
import { MiddleWareFunction } from "../../../skupno/src/ts/routing/page-base";
import { MainLayout } from "../views/main-layout";
import { OpenIdLoginHandler } from "../../../skupno/src/ts/authentication/openid-login-handler";
import { OpenIdIframeApplication } from "../../../skupno/src/ts/authentication/openid-iframe-application";
import { AccountApiClient } from "./clients/account-api-client";
//import { AccountsPage } from "./pages/accounts-page";
import { Configuration } from "../../../skupno/src/ts/configuration/configuration";
//import { CampusPage } from "./pages/campus-page";
//import { LokacijePage } from "./pages/lokacije-page";
//import { UporabnikiPage } from "./pages/uporabniki-page";
//import { VlogePage } from "./pages/vloge-page";
import { NamizjePage } from "./pages/namizje-page";
import { ManagementPage } from "./pages/management-page";
import { InternetMojDostopPage } from "./pages/internet-moj-dostop-page";
import { PrenocitvePage } from "./pages/prenocitve-page";
import { ObvestilaPage } from "./pages/obvestila-page";
import { OkvarePage } from "./pages/okvare-page";
import { OkvareDezurnaKnjigaPage } from "./pages/okvare-dezurna-knjiga-page";
import { OpominiPage } from "./pages/opomini-page";
import { IzjavePage } from "./pages/izjave-page";
import { PodporaPage } from "./pages/podpora-page";
import { SportPage } from "./pages/sport-page";
import { ObvestilaPodrobnoPage } from "./pages/obvestila-podrobno-page";
import { ManagementDodajUporabnikaPage } from "./pages/management-dodaj-uporabnika-page";
import { CampusApiClient } from "./clients/campus-api-client";
import { UserApiClient } from "./clients/users-api-client";
import { LocationApiClient } from "./clients/location-api-client";
import { NotificationApiClient } from "./clients/notifications-api-client";
import { OvernightApiClient } from "./clients/overnights-api-client";
import { StatementApiClient } from "./clients/statements-api-client";
import { RoleApiClient } from "./clients/roles-api-client";
import { SportEventApiClient } from "./clients/sport-event-api-client";
import { LoginPage } from "./pages/login-page";
import { ManagementUporabnikiPage } from "./pages/management-uporabniki-page";
import { ManagementVlogePage } from "./pages/management-vloge-page";
import { ManagementLokacijePage } from "./pages/management-lokacije-page";
import { ManagementCampusPage } from "./pages/management-campus-page";
import { InternetNasPage } from "./pages/internet-nas-page";
import { InternetZacasniUporabnikiPage } from "./pages/internet-zacasni-uporabniki-page";
import { InternetDnevnikPrijavPage } from "./pages/internet-dnevnik-prijav-page";
import { InternetDnevnikNapakPage } from "./pages/internet-dnevnik-napak-page";
import { PrenocitvePrijavaPage } from "./pages/prenocitve-prijava-page";
import { ObvestilaObjavljenaObvestilaPage } from "./pages/obvestila-objavljena-obvestila-page";
import { ObvestilaDodajNovoPage } from "./pages/obvestila-dodaj-novo-page";
//import { IzjaveMojeIzjavePage } from "./pages/izjave-moje-izjave-page";
//import { SportUrnikPage } from "./pages/sport-urnik-page";
import { SportNarocninaPage } from "./pages/sport-narocnina-page";
import { SportMojaFitnesKarticaPage } from "./pages/sport-moja-fitnes-kartica-page";
import { SportSeznamDogodkovPage } from "./pages/sport-seznam-dogodkov-page";
import { SportDodajDogodekPage } from "./pages/sport-dodaj-dogodek-page";
import { SportDodajNarocninoPage } from "./pages/sport-dodaj-narocnino-page";
import { SportUpravljajKarticePage } from "./pages/sport-upravljaj-kartice-page";
import { OkvareDodajPage } from "./pages/okvare-dodaj-page";
import { OkvareDezurnaKnjigaDodajPage } from "./pages/okvare-dezurna-knjiga-dodaj-page";
import { InternetZacasniUporabnikiDodajPage } from "./pages/internet-zacasni-uporabniki-dodaj-page";
import { InternetZacasniUporabnikiUrediPage } from "./pages/internet-zacasni-uporabniki-uredi-page";
import { TranslationsApiClient } from "./clients/translations-api-client";
import { DamagesApiClient } from "./clients/damage-api-client";
import { NasApiClient } from "./clients/nas-api-client";
import { RadErrorApiClient } from "./clients/rad-error-api-client";
import { NoticeApiClient } from "./clients/notice-api-client";
import { ManagementUporabnikiPregledPage } from "./pages/management-uporabniki-pregled-page";
import { HelpApiClient } from "./clients/help-api-client";
import { Notyf } from "notyf";
import { ManagementVlogePregledPage } from "./pages/management-vloge-pregled-page";
import { TranslationService } from "./translation-service";
import { IzjavePregledPage } from "./pages/izjave-pregled-page";
import { ProfilePage } from "./pages/profile-page";
import { HelpClient } from "./clients/help-client";
import { SportNarocninaPregledPage } from "./pages/sport-narocnina-pregled-page";
import { PageNotFound } from "./pages/page-not-found";
import { SportNarocninaPrijavljeniPage } from "./pages/sport-narocnina-prijavljeni-page";
import { SiteMapManager } from "./utilities/sitemap-manager";
import rootNodes from "./sitemap-data";
import { CurrentUserAccessor } from "./utilities/current-user-accessor";
import { Lock } from "../../../skupno/src/ts/utils/lock";
import { SportFitnesKarticaLogModal } from "../views/sport-fitness-kartica-log-modal";
import { SportPaketPrijavljeniPage } from "./pages/sport-paket-prijavljeni-page";
import { DesktopApiClient } from "./clients/desktop-api-client";
import { SportActivityApiClient } from "./clients/sport-activity-api-client";
import { SportDnevnikAktivnostiPage } from "./pages/sport-dnevnik-aktivnosti-page";
import { SportEventLogPage } from "./pages/sport-event-log-page";
import { FullscreenLoader } from "../views/fullscreen-loader";
import { SportUrediDogodekPage } from "./pages/sport-uredi-dogodek-page";
import { PotrditevEmailaPage } from "./pages/potrditev-emaila-page";
import { KuponiPage } from "./pages/kuponi-page";
import { CouponsApiClient } from "./clients/coupons-api-client";
import { KuponiDodajPage } from "./pages/kuponi-dodaj-page";
import { PonastavitevGeslaPage } from "./pages/ponastavitev-gesla-page";
import { KuponiUrediPage } from "./pages/kuponi-uredi-page";
import { SetEmailModal } from "../views/set-email-modal";
import { ChangePasswordModal } from "../views/change-password-modal";
import { KuponModal } from "../views/kupon-modal";
import { OkvareSkodniZapisnikPage } from "./pages/okvare-skodni-zapisnik-page";
import { SportFitnesKarticaPage } from "./pages/sport-fitnes-kartica-page";
import { SportDogodekPrijavljeniPage } from "./pages/sport-dogodek-prijavljeni-page";
import { ObvestilaUrediPage } from "./pages/obvestila-uredi-page";
import { IzjaveUrediPage } from "./pages/izjave-uredi-page";
import { IzjaveDodajPage } from "./pages/izjave-dodaj-page";
import { IzjaveSeznamPage } from "./pages/izjave-seznam-page";
import { SelitvePage } from "./pages/selitve-page";
import { SelitveApiClient } from "./clients/selitve-api-client";
import { SelitveZahtevkiPage } from "./pages/selitve-zahtevki-page";
import { SelitveUrejanjePage } from "./pages/selitve-urejanje-page";
import { InvoiceApiClient } from "./clients/invoice-api-client";
import { RacuniPage } from "./pages/racuni.page";
import { ManagementPermissionPage } from "./pages/management-permission-page";
import { PermissionApiClient } from "./clients/permission-api-client";

import { RevisionsPage } from "./pages/revisions-page";
import { RevisionViewPage } from "./pages/revision-view.page";

import { RevisionApiClient } from "./clients/revision-api-client";

import { ReservationsPage } from "./pages/reservations-page";

class ProfilApplication extends Application
{    
    public constructor() {
        super();
    }

    protected override async configureServices(services: Container): Promise<void> {
        // https://inversify.io/
        // https://github.com/inversify/InversifyJS/blob/master/wiki/value_injection.md
        //services.bind<ApplicationConfiguration>("Katana").toConstantValue(new Katana());
        //services.bind<Katana>("Katana").toDynamicValue((context: interfaces.Context) => { return new Katana(); });
        // a dynamic value can return a promise that will resolve to the value
        //services.bind<Katana>("Katana").toDynamicValue((context: interfaces.Context) => { return Promise.resolve(new Katana()); });

        // Download application configuration
        var configuration: Configuration = await ConfigurationLoader.download('spa.config.json?V=00001');
        services.bind<Configuration>("Configuration").toConstantValue(configuration);

        // Initialise our wrapper class around oidc-client
        var authenticator = new Authenticator(configuration.oauth);
        services.bind<Authenticator>("Authenticator").toConstantValue(authenticator); 

        var translationsApiClient = new TranslationsApiClient(configuration, authenticator);
        services.bind<TranslationsApiClient>("TranslationsApiClient").toConstantValue(translationsApiClient);

        var translationService = new TranslationService(translationsApiClient);
        services.bind<TranslationService>("TranslationService").toConstantValue(translationService);

        var userApiClient = new UserApiClient(configuration, authenticator);
        services.bind<UserApiClient>("UserApiClient").toConstantValue(userApiClient);

        services.bind<CurrentUserAccessor>("CurrentUserAccessor").to(CurrentUserAccessor).inSingletonScope();

        var sitemapManager: SiteMapManager = new SiteMapManager(rootNodes);
        services.bind<SiteMapManager>("SiteMapManager").toConstantValue(sitemapManager);

        //configure router
        var middlewareFunctions: MiddleWareFunction[] = new Array<MiddleWareFunction>();
        middlewareFunctions.push(OpenIdLoginHandler.handleLogin);

        $.fn.select2.defaults.set("dropdownAutoWidth", true);

        //popravek, ker ni fokusa na search fieldih
        $(document).on('select2:open', (e) => {
            const selectId = e.target.id;
            $(".select2-search__field[aria-controls='select2-" + selectId + "-results']").each(function (_key, value,) {
                value.focus();
            });
        });

        // jQuery unobtrusive validation defauls

        $.validator.setDefaults({
            errorClass: "",
            validClass: "",
            highlight: function (element, _errorClass, _validClass) {
                $(element).addClass("is-invalid").removeClass("is-valid");
                if (element.id) {
                    $((element as HTMLInputElement).form!).find("[data-valmsg-for=" + element.id + "]").addClass("invalid-feedback");
                }
            },
            unhighlight: function (element, _errorClass, _validClass) {
                $(element).addClass("is-valid").removeClass("is-invalid");
                if (element.id) {
                  $((element as HTMLInputElement).form!).find("[data-valmsg-for=" + element.id + "]").removeClass("invalid-feedback");
                }
            },
        });
        var notyf = new Notyf({
            dismissible: true,
            duration: 5000,
            ripple: true,
            position: {
                x: 'center',
                y: 'bottom'
            },
            types: [
                {
                    type: 'info',
                    icon: false,
                    className: "bg-primary"
                }
            ]
        });
        services.bind<Notyf>("Notyf").toConstantValue(notyf);

        //routing
        var fullScreenLoader = new FullscreenLoader($("body"));
        services.bind<FullscreenLoader>("FullscreenLoader").toConstantValue(fullScreenLoader);

        $(window).on("router:pagechanging", (_ev) => {
            fullScreenLoader.show();
        });
        $(window).on("router:pagechanged", (_ev) => {
            fullScreenLoader.hide();
        });
        $(window).on("router:pageloadingerror", (_ev) => {
            console.log("pageloadingerror:", _ev);
        });

        try {
            await translationService.initialize();
        }
        catch (e) {
            notyf.error({
                dismissible: false,
                position: {
                    x: 'center',
                    y: 'center'
                },
                duration: 0,
                message: "Trenutno poteka vzdrževanje sistema. Prosimo poizkusite čez nekaj minut..."
            })
            throw e;
        }


        /*
        middlewareFunctions.push(async () => {
            var page = services.get<AccountsPage>("AccountsPage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<CampusPage>("CampusPage");
            return await page.execute();
        });
        middlewareFunctions.push(async () => {
            var page = services.get<VlogePage>("VlogePage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<UporabnikiPage>("UporabnikiPage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<LokacijePage>("LokacijePage");
            return await page.execute();
        });   
        */


        //#region misc
        middlewareFunctions.push(async () => {
            var page = services.get<PotrditevEmailaPage>("PotrditevEmailaPage");
            return await page.execute();
        });
        middlewareFunctions.push(async () => {
            var page = services.get<PonastavitevGeslaPage>("PonastavitevGeslaPage");
            return await page.execute();
        });
        //#endregion misc

        //#region namizje
        middlewareFunctions.push(async () => {
            var page = services.get<NamizjePage>("NamizjePage");
            return await page.execute();
        });
        //#endregion namizje
        //#region management

        
        middlewareFunctions.push(async () => {
            var page = services.get<ProfilePage>("ProfilePage");
            return await page.execute();
        });
        middlewareFunctions.push(async () => {
            var page = services.get<ManagementPage>("ManagementPage");
            return await page.execute();
        });
        middlewareFunctions.push(async () => {
            var page = services.get<ManagementUporabnikiPregledPage>("ManagementUporabnikiPregledPage");
            return await page.execute();
        });
        middlewareFunctions.push(async () => {
            var page = services.get<ManagementUporabnikiPage>("ManagementUporabnikiPage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<ManagementDodajUporabnikaPage>("ManagementDodajUporabnikaPage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<ManagementVlogePregledPage>("ManagementVlogePregledPage");
            return await page.execute();
        });
        
        middlewareFunctions.push(async () => {
            var page = services.get<ManagementVlogePage>("ManagementVlogePage");
            return await page.execute();
        });    

        middlewareFunctions.push(async () => {
            var page = services.get<ManagementLokacijePage>("ManagementLokacijePage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<ManagementCampusPage>("ManagementCampusPage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<ManagementPermissionPage>("ManagementPermissionPage");
            return await page.execute();
        });

        //#endregion
        //#region internet
        middlewareFunctions.push(async () => {
            var page = services.get<InternetNasPage>("InternetNasPage");
            return await page.execute();
        });
        middlewareFunctions.push(async () => {
            var page = services.get<InternetZacasniUporabnikiPage>("InternetZacasniUporabnikiPage");
            return await page.execute();
        });
        middlewareFunctions.push(async () => {
            var page = services.get<InternetZacasniUporabnikiDodajPage>("InternetZacasniUporabnikiDodajPage");
            return await page.execute();
        });
        middlewareFunctions.push(async () => {
            var page = services.get<InternetZacasniUporabnikiUrediPage>("InternetZacasniUporabnikiUrediPage");
            return await page.execute();
        });
        middlewareFunctions.push(async () => {
            var page = services.get<InternetDnevnikPrijavPage>("InternetDnevnikPrijavPage");
            return await page.execute();
        });
        middlewareFunctions.push(async () => {
            var page = services.get<InternetDnevnikNapakPage>("InternetDnevnikNapakPage");
            return await page.execute();
        });
        middlewareFunctions.push(async () => {
            var page = services.get<InternetMojDostopPage>("InternetMojDostopPage");
            return await page.execute();
        });
        //#endregion
        //#region prenocitve
        middlewareFunctions.push(async () => {
            var page = services.get<PrenocitvePage>("PrenocitvePage");
            return await page.execute();
        });
        middlewareFunctions.push(async () => {
            var page = services.get<PrenocitvePrijavaPage>("PrenocitvePrijavaPage");
            return await page.execute();
        });
        //#endregion prenocitve
        //#region obvestila
        middlewareFunctions.push(async () => {
            var page = services.get<ObvestilaPage>("ObvestilaPage");
            return await page.execute();
        });
        middlewareFunctions.push(async () => {
            var page = services.get<ObvestilaObjavljenaObvestilaPage>("ObvestilaObjavljenaObvestilaPage");
            return await page.execute();
        });
        middlewareFunctions.push(async () => {
            var page = services.get<ObvestilaDodajNovoPage>("ObvestilaDodajNovoPage");
            return await page.execute();
        });
        middlewareFunctions.push(async () => {
            var page = services.get<ObvestilaUrediPage>("ObvestilaUrediPage");
            return await page.execute();
        });
        middlewareFunctions.push(async () => {
            var page = services.get<ObvestilaPodrobnoPage>("ObvestilaPodrobnoPage");
            return await page.execute();
        });
        //#endregion obvestila
        //#region okvare
        middlewareFunctions.push(async () => {
            var page = services.get<OkvarePage>("OkvarePage");
            return await page.execute();
        });
        middlewareFunctions.push(async () => {
            var page = services.get<OkvareDodajPage>("OkvareDodajPage");
            return await page.execute();
        });
        middlewareFunctions.push(async () => {
            var page = services.get<OkvareDezurnaKnjigaPage>("OkvareDezurnaKnjigaPage");
            return await page.execute();
        });
        middlewareFunctions.push(async () => {
            var page = services.get<OkvareDezurnaKnjigaDodajPage>("OkvareDezurnaKnjigaDodajPage");
            return await page.execute();
        });
        middlewareFunctions.push(async () => {
            var page = services.get<OkvareSkodniZapisnikPage>("OkvareSkodniZapisnikPage");
            return await page.execute();
        });
        //#endregion
        //#region opomini
        middlewareFunctions.push(async () => {
            var page = services.get<OpominiPage>("OpominiPage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<RacuniPage>("RacuniPage");
            return await page.execute();
        });
        //#endregion opomini
        //#region izjave
        middlewareFunctions.push(async () => {
            var page = services.get<IzjavePregledPage>("IzjavePregledPage");
            return await page.execute();
        });
        middlewareFunctions.push(async () => {
            var page = services.get<IzjaveUrediPage>("IzjaveUrediPage");
            return await page.execute();
        });
        middlewareFunctions.push(async () => {
            var page = services.get<IzjaveDodajPage>("IzjaveDodajPage");
            return await page.execute();
        });
        middlewareFunctions.push(async () => {
            var page = services.get<IzjaveSeznamPage>("IzjaveSeznamPage");
            return await page.execute();
        });
        middlewareFunctions.push(async () => {
            var page = services.get<IzjavePage>("IzjavePage");
            return await page.execute();
        });
        //#endregion izjave
        //#region šport
        middlewareFunctions.push(async () => {
            var page = services.get<SportPage>("SportPage");
            
            return await page.execute();
        });
        //middlewareFunctions.push(async () => {
        //    var page = services.get<SportUrnikPage>("SportUrnikPage");
        //    return await page.execute();
        //});
        middlewareFunctions.push(async () => {
            var page = services.get<SportDnevnikAktivnostiPage>("SportDnevnikAktivnostiPage");
            return await page.execute();
        });
        middlewareFunctions.push(async () => {
            var page = services.get<SportEventLogPage>("SportEventLogPage");
            return await page.execute();
        });
        middlewareFunctions.push(async () => {
            var page = services.get<SportPaketPrijavljeniPage>("SportPaketPrijavljeniPage");
            return await page.execute();
        });
        middlewareFunctions.push(async () => {
            var page = services.get<SportNarocninaPregledPage>("SportNarocninaPregledPage");
            return await page.execute();
        });
        middlewareFunctions.push(async () => {
            var page = services.get<SportNarocninaPrijavljeniPage>("SportNarocninaPrijavljeniPage");
            return await page.execute();
        });
        middlewareFunctions.push(async () => {
            var page = services.get<SportNarocninaPage>("SportNarocninaPage");
            return await page.execute();
        });
        middlewareFunctions.push(async () => {
            var page = services.get<SportMojaFitnesKarticaPage>("SportMojaFitnesKarticaPage");
            return await page.execute();
        });
        middlewareFunctions.push(async () => {
            var page = services.get<SportFitnesKarticaPage>("SportFitnesKarticaPage");
            return await page.execute();
        });
        middlewareFunctions.push(async () => {
            var page = services.get<SportUrediDogodekPage>("SportUrediDogodekPage");
            return await page.execute();
        });
        middlewareFunctions.push(async () => {
            var page = services.get<SportSeznamDogodkovPage>("SportSeznamDogodkovPage");
            return await page.execute();
        });
        middlewareFunctions.push(async () => {
            var page = services.get<SportDogodekPrijavljeniPage>("SportDogodekPrijavljeniPage");
            return await page.execute();
        });
        middlewareFunctions.push(async () => {
            var page = services.get<SportDodajDogodekPage>("SportDodajDogodekPage");
            return await page.execute();
        });
        middlewareFunctions.push(async () => {
            var page = services.get<SportDodajNarocninoPage>("SportDodajNarocninoPage");
            return await page.execute();
        });
        middlewareFunctions.push(async () => {
            var page = services.get<SportUpravljajKarticePage>("SportUpravljajKarticePage");
            return await page.execute();
        });
        middlewareFunctions.push(async () => {
            var page = services.get<KuponiUrediPage>("KuponiUrediPage");
            return await page.execute();
        });
        middlewareFunctions.push(async () => {
            var page = services.get<KuponiPage>("KuponiPage");
            return await page.execute();
        });
        middlewareFunctions.push(async () => {
            var page = services.get<KuponiDodajPage>("KuponiDodajPage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<SelitvePage>("SelitvePage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<SelitveZahtevkiPage>("SelitveZahtevkiPage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<SelitveUrejanjePage>("SelitveUrejanjePage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<RevisionsPage>("RevisionsPage");
            return await page.execute();
        });
        
        middlewareFunctions.push(async () => {
            var page = services.get<RevisionViewPage>("RevisionViewPage");
            return await page.execute();
        });
        
        middlewareFunctions.push(async () => {
            var page = services.get<ReservationsPage>("ReservationsPage");
            return await page.execute();
        });
        //#endregion šport
        //#region podpora
        //middlewareFunctions.push(async () => {
        //    var page = services.get<PodporaPage>("PodporaPage");
        //    return await page.execute();
        //});
        //#endregion podpora
        //#region login
        middlewareFunctions.push(async () => {
            var page = services.get<LoginPage>("LoginPage");
            return await page.execute();
        }); 
        middlewareFunctions.push(async () => {
            var page = services.get<PageNotFound>("PageNotFound");
            return await page.execute();
        });
        
      

        //#endregion login

        //#region navigation
        //middlewareFunctions.push(async () => {
        //    var page = services.get<Navigation>("LoginPage");
        //    return await page.execute();
        //}); 
        //#endregion
        var router = new Router(middlewareFunctions, services);
        services.bind<Router>("Router").toConstantValue(router);


        //#region strani

        //services.bind<AccountsPage>("AccountsPage").to(AccountsPage).inTransientScope();
        //services.bind<CampusPage>("CampusPage").to(CampusPage).inTransientScope();
        //services.bind<LokacijePage>("LokacijePage").to(LokacijePage).inTransientScope();

        //services.bind<UporabnikiPage>("UporabnikiPage").to(UporabnikiPage).inTransientScope();
        //services.bind<VlogePage>("VlogePage").to(VlogePage).inTransientScope();

        //#region namizje
        services.bind<NamizjePage>("NamizjePage").to(NamizjePage).inTransientScope();
        //#endregion namizje
        //#region management
        services.bind<ManagementPage>("ManagementPage").to(ManagementPage).inTransientScope();
        services.bind<ManagementUporabnikiPage>("ManagementUporabnikiPage").to(ManagementUporabnikiPage).inTransientScope(); 
        services.bind<ManagementUporabnikiPregledPage>("ManagementUporabnikiPregledPage").to(ManagementUporabnikiPregledPage).inTransientScope(); 
        services.bind<ManagementDodajUporabnikaPage>("ManagementDodajUporabnikaPage").to(ManagementDodajUporabnikaPage).inTransientScope();
        services.bind<ManagementVlogePage>("ManagementVlogePage").to(ManagementVlogePage).inTransientScope();
        services.bind<ManagementVlogePregledPage>("ManagementVlogePregledPage").to(ManagementVlogePregledPage).inTransientScope();        
        services.bind<ManagementLokacijePage>("ManagementLokacijePage").to(ManagementLokacijePage).inTransientScope();
        services.bind<ManagementCampusPage>("ManagementCampusPage").to(ManagementCampusPage).inTransientScope();
        services.bind<ManagementPermissionPage>("ManagementPermissionPage").to(ManagementPermissionPage).inTransientScope();

        //#endregion management
        //#region internet
        services.bind<InternetMojDostopPage>("InternetMojDostopPage").to(InternetMojDostopPage).inTransientScope();
        services.bind<InternetNasPage>("InternetNasPage").to(InternetNasPage).inTransientScope();
        services.bind<InternetZacasniUporabnikiPage>("InternetZacasniUporabnikiPage").to(InternetZacasniUporabnikiPage).inTransientScope();
        services.bind<InternetZacasniUporabnikiDodajPage>("InternetZacasniUporabnikiDodajPage").to(InternetZacasniUporabnikiDodajPage).inTransientScope();
        services.bind<InternetZacasniUporabnikiUrediPage>("InternetZacasniUporabnikiUrediPage").to(InternetZacasniUporabnikiUrediPage).inTransientScope();
        services.bind<InternetDnevnikPrijavPage>("InternetDnevnikPrijavPage").to(InternetDnevnikPrijavPage).inTransientScope();
        services.bind<InternetDnevnikNapakPage>("InternetDnevnikNapakPage").to(InternetDnevnikNapakPage).inTransientScope();
        //#endregion internet
        //#region prenočitve
        services.bind<PrenocitvePage>("PrenocitvePage").to(PrenocitvePage).inTransientScope();
        services.bind<PrenocitvePrijavaPage>("PrenocitvePrijavaPage").to(PrenocitvePrijavaPage).inTransientScope();
        //#endregion prenočitve
        //#region obvestila
        services.bind<ObvestilaPage>("ObvestilaPage").to(ObvestilaPage).inTransientScope();
        services.bind<ObvestilaObjavljenaObvestilaPage>("ObvestilaObjavljenaObvestilaPage").to(ObvestilaObjavljenaObvestilaPage).inTransientScope();
        services.bind<ObvestilaDodajNovoPage>("ObvestilaDodajNovoPage").to(ObvestilaDodajNovoPage).inTransientScope();
        services.bind<ObvestilaUrediPage>("ObvestilaUrediPage").to(ObvestilaUrediPage).inTransientScope();
        services.bind<ObvestilaPodrobnoPage>("ObvestilaPodrobnoPage").to(ObvestilaPodrobnoPage).inTransientScope();
        //#endregion obvestila
        //#region okvare
        services.bind<OkvarePage>("OkvarePage").to(OkvarePage).inTransientScope();
        services.bind<OkvareDodajPage>("OkvareDodajPage").to(OkvareDodajPage).inTransientScope();
        services.bind<OkvareDezurnaKnjigaPage>("OkvareDezurnaKnjigaPage").to(OkvareDezurnaKnjigaPage).inTransientScope();
        services.bind<OkvareDezurnaKnjigaDodajPage>("OkvareDezurnaKnjigaDodajPage").to(OkvareDezurnaKnjigaDodajPage).inTransientScope();
        services.bind<OkvareSkodniZapisnikPage>("OkvareSkodniZapisnikPage").to(OkvareSkodniZapisnikPage).inTransientScope();
        //#endregion
        //#region opomini
        services.bind<OpominiPage>("OpominiPage").to(OpominiPage).inTransientScope();
    
        //#endregion opomini
        //#region izjave
        services.bind<IzjavePregledPage>("IzjavePregledPage").to(IzjavePregledPage).inTransientScope();
        services.bind<IzjaveUrediPage>("IzjaveUrediPage").to(IzjaveUrediPage).inTransientScope();
        services.bind<IzjaveDodajPage>("IzjaveDodajPage").to(IzjaveDodajPage).inTransientScope();
        services.bind<IzjaveSeznamPage>("IzjaveSeznamPage").to(IzjaveSeznamPage).inTransientScope();
        services.bind<IzjavePage>("IzjavePage").to(IzjavePage).inTransientScope();
        //#endregion
        //#region šport
        services.bind<SportPage>("SportPage").to(SportPage).inTransientScope();
        services.bind<SportPaketPrijavljeniPage>("SportPaketPrijavljeniPage").to(SportPaketPrijavljeniPage).inTransientScope();
        /* services.bind<SportUrnikPage>("SportUrnikPage").to(SportUrnikPage).inTransientScope();*/
        services.bind<SportNarocninaPregledPage>("SportNarocninaPregledPage").to(SportNarocninaPregledPage).inTransientScope();
        services.bind<SportNarocninaPrijavljeniPage>("SportNarocninaPrijavljeniPage").to(SportNarocninaPrijavljeniPage).inTransientScope();
        services.bind<SportNarocninaPage>("SportNarocninaPage").to(SportNarocninaPage).inTransientScope();
        services.bind<SportMojaFitnesKarticaPage>("SportMojaFitnesKarticaPage").to(SportMojaFitnesKarticaPage).inTransientScope();
        services.bind<SportFitnesKarticaPage>("SportFitnesKarticaPage").to(SportFitnesKarticaPage).inTransientScope();
        services.bind<SportUrediDogodekPage>("SportUrediDogodekPage").to(SportUrediDogodekPage).inTransientScope();
        services.bind<SportSeznamDogodkovPage>("SportSeznamDogodkovPage").to(SportSeznamDogodkovPage).inTransientScope();
        services.bind<SportDogodekPrijavljeniPage>("SportDogodekPrijavljeniPage").to(SportDogodekPrijavljeniPage).inTransientScope();
        services.bind<SportDodajDogodekPage>("SportDodajDogodekPage").to(SportDodajDogodekPage).inTransientScope();
        services.bind<SportDodajNarocninoPage>("SportDodajNarocninoPage").to(SportDodajNarocninoPage).inTransientScope();
        services.bind<SportUpravljajKarticePage>("SportUpravljajKarticePage").to(SportUpravljajKarticePage).inTransientScope();
        services.bind<SportDnevnikAktivnostiPage>("SportDnevnikAktivnostiPage").to(SportDnevnikAktivnostiPage).inTransientScope();
        services.bind<SportEventLogPage>("SportEventLogPage").to(SportEventLogPage).inTransientScope();

        services.bind<KuponiUrediPage>("KuponiUrediPage").to(KuponiUrediPage).inTransientScope();
        services.bind<KuponiPage>("KuponiPage").to(KuponiPage).inTransientScope();
        services.bind<KuponiDodajPage>("KuponiDodajPage").to(KuponiDodajPage).inTransientScope();
        services.bind<SelitvePage>("SelitvePage").to(SelitvePage).inTransientScope();
        services.bind<SelitveZahtevkiPage>("SelitveZahtevkiPage").to(SelitveZahtevkiPage).inTransientScope();
        services.bind<SelitveUrejanjePage>("SelitveUrejanjePage").to(SelitveUrejanjePage).inTransientScope();
        services.bind<PonastavitevGeslaPage>("PonastavitevGeslaPage").to(PonastavitevGeslaPage).inTransientScope();
        services.bind<PageNotFound>("PageNotFound").to(PageNotFound).inTransientScope();
        //#endregion šport
        //#region podpora
        services.bind<PodporaPage>("PodporaPage").to(PodporaPage).inTransientScope();
        //#endregion podpora
        //#region misc
        services.bind<PotrditevEmailaPage>("PotrditevEmailaPage").to(PotrditevEmailaPage).inTransientScope();
        //#endregion misc
        //#region login
        services.bind<LoginPage>("LoginPage").to(LoginPage).inTransientScope();
        services.bind<ProfilePage>("ProfilePage").to(ProfilePage).inTransientScope();
        services.bind<RacuniPage>("RacuniPage").to(RacuniPage).inTransientScope();
        services.bind<RevisionsPage>("RevisionsPage").to(RevisionsPage).inTransientScope();
        services.bind<RevisionViewPage>("RevisionViewPage").to(RevisionViewPage).inTransientScope();
        
        services.bind<ReservationsPage>("ReservationsPage").to(ReservationsPage).inTransientScope();
        //#endregion login


        //#region odjemalci
        services.bind<AccountApiClient>("AccountApiClient").to(AccountApiClient).inSingletonScope();
        services.bind<CampusApiClient>("CampusApiClient").to(CampusApiClient).inSingletonScope();
        services.bind<LocationApiClient>("LocationApiClient").to(LocationApiClient).inSingletonScope();
        services.bind<NotificationApiClient>("NotificationApiClient").to(NotificationApiClient).inSingletonScope();
        services.bind<DesktopApiClient>("DesktopApiClient").to(DesktopApiClient).inSingletonScope();
        services.bind<OvernightApiClient>("OvernightApiClient").to(OvernightApiClient).inSingletonScope();
        services.bind<StatementApiClient>("StatementApiClient").to(StatementApiClient).inSingletonScope();
        services.bind<RoleApiClient>("RoleApiClient").to(RoleApiClient).inSingletonScope();
        services.bind<SportEventApiClient>("SportEventApiClient").to(SportEventApiClient).inSingletonScope();
        services.bind<DamagesApiClient>("DamagesApiClient").to(DamagesApiClient).inSingletonScope();
        services.bind<NasApiClient>("NasApiClient").to(NasApiClient).inSingletonScope();
        services.bind<RadErrorApiClient>("RadErrorApiClient").to(RadErrorApiClient).inSingletonScope();
        services.bind<NoticeApiClient>("NoticeApiClient").to(NoticeApiClient).inSingletonScope();
        services.bind<HelpApiClient>("HelpApiClient").to(HelpApiClient).inSingletonScope();
        services.bind<HelpClient>("HelpClient").to(HelpClient).inSingletonScope();
        services.bind<SportActivityApiClient>("SportActivityApiClient").to(SportActivityApiClient).inSingletonScope();
        services.bind<SportFitnesKarticaLogModal>("SportFitnesKarticaLogModal").to(SportFitnesKarticaLogModal).inSingletonScope();
        services.bind<SetEmailModal>("SetEmailModal").to(SetEmailModal).inSingletonScope();
        services.bind<KuponModal>("KuponModal").to(KuponModal).inSingletonScope();
        services.bind<ChangePasswordModal>("ChangePasswordModal").to(ChangePasswordModal).inSingletonScope();
        services.bind<CouponsApiClient>("CouponsApiClient").to(CouponsApiClient).inSingletonScope();
        services.bind<SelitveApiClient>("SelitveApiClient").to(SelitveApiClient).inSingletonScope();
        services.bind<InvoiceApiClient>("InvoiceApiClient").to(InvoiceApiClient).inSingletonScope();

        services.bind<PermissionApiClient>("PermissionApiClient").to(PermissionApiClient).inSingletonScope();
        services.bind<RevisionApiClient>("RevisionApiClient").to(RevisionApiClient).inSingletonScope();


        //#endregion odjemalci

        

        //let applicationDocument = new MainLayout(window.document, authenticator, router, services.get<UserApiClient>("UserApiClient"));
        var lock = new Lock();
        var mainLayoutSingleton: MainLayout;

        services.bind<interfaces.Provider<MainLayout>>("Provider<MainLayout>")
            .toProvider<MainLayout>((context) => {
                return () => {   

                    if (mainLayoutSingleton) {
                        return Promise.resolve(mainLayoutSingleton);
                    }
                    
                    return new Promise<MainLayout>(async(resolve, reject) => {
                        var releaser = await lock.acquire();
                        try {
                            if (mainLayoutSingleton) {
                                return mainLayoutSingleton;
                            }
                            console.log("in promise");
                            let mainLayout = new MainLayout(
                                window.document,
                                authenticator,
                                router,
                                context.container.get<TranslationService>("TranslationService"),
                                context.container.get<SiteMapManager>("SiteMapManager"),
                                context.container.get<CurrentUserAccessor>("CurrentUserAccessor"),
                                context.container.get<SetEmailModal>("SetEmailModal"),
                                context.container.get<ChangePasswordModal>("ChangePasswordModal"),
                                context.container.get<UserApiClient>("UserApiClient"),
                                context.container.get<Notyf>("Notyf"),
                            );

                            await mainLayout.initialize().then(() => {
                                mainLayoutSingleton = mainLayout;
                                resolve(mainLayout);
                            })
                                .catch((e: Error) => {
                                    console.log("mainLayout  error", e);
                                    reject(e);
                                });

                            return mainLayout;
                        }
                        finally {
                            releaser();
                        }
                    });
                };
            });        

    }   

}

if (document.readyState !== 'loading') {
    init();
} else {
    document.addEventListener('DOMContentLoaded', init, false);
}

function init() {
    /*
     * The application entry point
     */
    if (window.top === window.self) {

        //console.log('empty for now');
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.register('/service-worker.js')
                .then((reg) => {
                    console.log('Service worker registered -->', reg);
                }, (err) => {
                    console.error('Service worker not registered -->', err);
                });
        }
         
        // Run the main app
        const app = new ProfilApplication();
        app.run();

    } else {

        // If our SPA is running on an iframe, handle token renewal responses
        const app = new OpenIdIframeApplication();
        app.run();
    }
}
