export class ClipboardUtilities {

    public static copyText(text: string): Promise<void> {
        if (!navigator.clipboard) {
            var success = ClipboardUtilities.fallbackCopyTextToClipboard(text);
            return success ? Promise.resolve() : Promise.reject();
        }

        return navigator.clipboard.writeText(text);
    }

    private static fallbackCopyTextToClipboard(text: string): boolean {
        var textArea = document.createElement("textarea");
        textArea.value = text;

        // Avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            return document.execCommand('copy');
        } catch (err) {
            console.error('Fallback: Oops, unable to copy', err);
            return false;
        }
        finally {
            document.body.removeChild(textArea);
        }

    }
}