import * as Mustache from "mustache";
import { ListResponse } from "../../../../profil/src/ts/models/list-response";


export interface PaginationWidgetOptions {
    rootElement: JQuery<HTMLElement>;
    onPageChange: ((page: number) => Promise<any>);
}

export class PaginationWidget {


    public constructor(private options: PaginationWidgetOptions) {
    }

    public load(data: ListResponse<any>): void {      

        if (data.pageCount <= 1) {
            this.options.rootElement.html("").hide();
            return;
        }

        if (data.currentPage > 1) {
            var hash = window.location.hash;
            if (hash && hash.includes('?')) {
                hash = hash.split('?')[0];
            }
            hash = hash + '?page=' + data.currentPage;
            var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + hash;
            window.history.pushState({ path: newurl }, '', newurl);
        }
        else {
            this.removeQueryString();
        }

        const html =
            `
{{^isFirst}}
                <li class='page-item'>
                    <a type='button' class='page-link-first'></a>
                </li>
                <li class='page-item'>
                    <a type='button' class='page-link-previous'> </a>
                </li>
{{/isFirst}}
                <li class='page-item'>
                    <a class='current-page' data-page={{data.currentPage}}>{{data.currentPage}}</a>
{{#data.totalCountAvailable}}
                    / <a class="page-count" data-page="{{data.pageCount}}">{{data.pageCount}}</a>                    
    {{/data.totalCountAvailable}}
                </li>
{{^isLast}}
                <li class='page-item'>
                    <a type='button' class='page-link-next page-link-next'></a>
                </li>
    {{#data.totalCountAvailable}}
                <li class='page-item'>
                    <a type='button' class='page-link-last page-link-last'></a>
                </li>
    {{/data.totalCountAvailable}}
{{/isLast}}
             `;
        var viewModel = {
            data: data,
            isFirst: data.currentPage == 1,
            isLast: data.totalCountAvailable ? data.currentPage == data.pageCount : !data.hasMoreItems
        }
        const htmlRender = Mustache.render(html, viewModel);
        this.options.rootElement.html(htmlRender).show();
        this.options.rootElement.find('.page-link-first').on("click", () => this.first());
        this.options.rootElement.find('.page-link-previous').on("click",  () => this.previous());
        this.options.rootElement.find('.page-link-next').on("click", () => this.next());
        this.options.rootElement.find('.page-link-last').on("click", () => this.last());
    }

    public async first(): Promise<void> {
        var currentPage = parseInt(this.options.rootElement.find(`.current-page`).data('page'), 10) + 1;
        if (currentPage != 1) {
            var currentPage = 1;
            this.removeQueryString();
            await this.triggerPageChange(currentPage);
        }
    }

    public async next(): Promise<void> {       
        var pageCount = parseInt($(`.page-count`).data('page'), 10);
        var currentPage = parseInt(this.options.rootElement.find(`.current-page`).data('page'), 10);
        if (pageCount != currentPage) {
            await this.triggerPageChange(currentPage + 1);
        }        
    }

    public async previous(): Promise<void> {
        var currentPage = parseInt(this.options.rootElement.find(`.current-page`).data('page'), 10);
        if (currentPage != 1) {
            var currentPage = currentPage - 1;
            if (currentPage == 1) {
                this.removeQueryString();
            }
            await this.triggerPageChange(currentPage);
        }
    }

    public async last(): Promise<void> {  
        var currentPage = parseInt($(`.current-page`).data('page'), 10);
        var pageCount = parseInt($(`.page-count`).data('page'), 10);
        if (currentPage != +pageCount) {
            await this.triggerPageChange(pageCount);
        }
    }

    public hide(): void {
        this.options.rootElement.html("").hide();
    }

    private async triggerPageChange(currentPage: number) {
        await this.options.onPageChange(currentPage);
    }

    private removeQueryString() {
        var hash = window.location.hash;
        if (hash && hash.includes('?')) {
            hash = hash.split('?')[0];
            var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + hash;
            window.history.pushState({ path: newurl }, '', newurl);
        }
    }
}