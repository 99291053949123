import { injectable } from "inversify";
import * as Mustache from "mustache";
import { Notyf } from "notyf";
import Quill from "quill";
import { SportEventApiClient } from "../ts/clients/sport-event-api-client";
import { TranslationService } from "../ts/translation-service";
import template from "./sport-dodaj-dogodek-view.html";
import priponkeTemplate from "./widget-priponke.html";
import pripomockiTemplate from "./widget-izbirnik-pripomockov.html";
import izbirnikUporabnikovTemplate from "./widget-izbirnik-uporabnikov.html";

//import * as DateRangePicker from 'daterangepicker';
import { DateTimePickerWidget } from "../../../skupno/src/ts/widgets/date-time-picker-widget";
import { FullscreenLoader } from "./fullscreen-loader";

@injectable()
export class SportDodajDogodekView {
    private _apiClient: SportEventApiClient;
    private _translationService: TranslationService;
    private _datePicker: DateTimePickerWidget;

    private _messageEditorQuillElement: Quill | null;
    private _messageEditorElement: HTMLElement | null;
    private _uploadFilesElement: HTMLInputElement | null;

    private _fileList: Array<any>;
    private _fileViewList: Array<any>;
    private _priceList: Array<any>;
    private _tools: Array<any>;
    private _selectedTools: Array<any>;
    private _selectedManagers: Array<any>;

    public constructor(sportEventApiClient: SportEventApiClient, translationService: TranslationService, private notyf: Notyf, private loader: FullscreenLoader) {
        this._apiClient = sportEventApiClient; 
        this._translationService = translationService;
        this._datePicker = new DateTimePickerWidget;

        this._messageEditorElement = null;
        this._messageEditorQuillElement = null;
        this._uploadFilesElement = null;
        this._fileList = new Array<File>();
        this._fileViewList = new Array<any>();
        this._priceList = new Array<any>();
        this._tools = new Array<any>();
        this._selectedTools = new Array<any>();
        this._selectedManagers = new Array<any>();

    }
    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {

        try {           
            var options = await this._apiClient.getOptions();
            this._priceList = options.priceList;
            this._tools = options.toolList;
            this._renderData();
            this._renderAttachments();
            this._renderAccessorieSelectField();
            this._datePicker.load();
            this._renderManagersSelectField();


        } catch (e) {

            // Clear previous content on error
            $('#main').text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _renderData(): void {

        // Build a view model from the API data
        const viewModel = {
            "imageUrlLogout": "../img/icon-logout.svg",
            "imageUrlArrowDown": "../img/icon-arrow-down.svg",
            "imageUrlDelete": "../img/icon-delete.svg",
            "priceList": this._priceList,
            "tools": this._tools,
            "translations": this._translationService.currentTranslations
        } as any;
        // Construct a template
        const htmlTemplate = template;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#main').html(html);

        this._initForm();
        this._initQuillEditor();
    }

    //inicializira obrazec
    private _initForm(): void {
        var self = this;
        var translations = this._translationService.currentTranslations;
        var formElement = $("#sport-event-add-form");
        
        $.validator.addMethod("required2", function (_value, _element: any, params) {
            if (_value.length == 0) {
                if ($(params).is(":checked") == true) {
                    return true;
                } else {
                    return false;
                }
            } else {
                if ($(params).is(":checked") == true) {
                    return false;
                } else {
                    return true;
                }
            }
            
        });
        $.validator.unobtrusive.adapters.add("required2", ["checkbox"], function (options: any) {
            options.rules["required2"] = "#" + options.params.checkbox;
            options.messages["required2"] = options.message;
        });
        $.validator.unobtrusive.parse(formElement);

        formElement.on("submit", async (ev) => {
            ev.preventDefault();
            $("#freeEvent").on("change", function () {
                formElement.valid();
            })
            if (!formElement.valid()) {
                this.notyf.error(translations.CheckAllFields);
                return;
            }
            this.loader.show();
            //var dt = $("#dtStart").val();
            //var d2 = $("#dtStart").data("daterangepicker")?.startDate;

            var formData = new FormData();
            formData.append("title", $("#title").val() as string);
            var startDate = $("#dtStart").data("daterangepicker")?.startDate.toISOString();
            formData.append("dtStart", startDate as string);
            var endDate = $("#dtEnd").data("daterangepicker")?.startDate.toISOString();
            formData.append("dtEnd", endDate as string);
            formData.append("repeats", $("#repetitions").val() as string);
            formData.append("capacity", $("#capacity").val() as string);
            //Price
            var priceId = $("#price").val();
            var priceElement = this._priceList.find((el) => el.id == priceId);
            if (priceElement) {
                formData.append("price", priceElement.price);
                formData.append("priceCode", priceElement.id);
            }
            formData.append("freeEvent", $('#freeEvent').is(":checked") ? "1" : "0");

            formData.append("body", this._messageEditorQuillElement?.root.innerHTML as string);

            for (var i = 0; i < this._fileList.length; i++) {
                formData.append("attachments", this._fileList[i]);
            }

            var tools = [];
            for (var i = 0; i < this._selectedTools.length; i++) {
                tools.push({
                    id: this._selectedTools[i].id,
                    name: this._selectedTools[i].name,
                    available: this._selectedTools[i].selectedQuantity,
                    count: this._selectedTools[i].quantity
                })
            }
            formData.append("tools", JSON.stringify(tools));
            formData.append("author", "");
            formData.append("deletedDocumentsJson", "[]");
            formData.append("deletedDocuments", "[]");
            formData.append("uploadedDocuments", "[]");
            formData.append("managersJson", JSON.stringify(this._selectedManagers));
            formData.append("managers", JSON.stringify(this._selectedManagers));

            await this._apiClient.createEvent(formData).then(
                function (data: any) {
                    self.loader.hide();
                    if (data && data.success) {
                        self.notyf.success(translations.EventSuccessfullySaved);
                        window.location.href = "/#/sport/seznam-dogodkov";
                    }
                    else if (data && data.message) {
                        self.notyf.error(data.message);
                    }
                    else {
                        self.notyf.error(translations.SaveError);
                    }
                },
                function (reason: any) {
                    console.debug(reason);
                    self.loader.hide();
                    self.notyf.error(translations.SaveError);
                }
            );
        });
    }

    //inicializira RTE
    public _initQuillEditor() {

        var ColorClass = Quill.import('attributors/class/color');
        var SizeStyle = Quill.import('attributors/style/size');
        var Bold = Quill.import('formats/bold');
        Quill.register(ColorClass, true);
        Quill.register(SizeStyle, true);
        Bold.tagName = 'B';   // Quill uses <strong> by default
        Quill.register(Bold, true);
        var toolbarOptions = [
            [{ container: "#toolbar-container" }],
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['blockquote', 'code-block'],

            [{ 'header': 1 }, { 'header': 2 }],               // custom button values
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
            [{ 'direction': 'rtl' }],                         // text direction

            [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            [{ 'font': [] }],
            [{ 'align': [] }],

            ['clean']                                         // remove formatting button
        ];
        let mainElement: JQuery<HTMLElement> = $('#main');
        this._messageEditorElement = mainElement.find(".editor-container")[0];
        /*console.debug("placeholder", this._messageEditorElement);*/
        this._messageEditorQuillElement = new Quill(this._messageEditorElement, {
            modules: {
                toolbar: toolbarOptions
            },
            theme: "snow"
        });
    }


    //#region Attachments

    //render attachments
    private _renderAttachments(): void {

        // Build a view model from the API data
        const viewModel = {
            "imageUrlDelete": "../img/icon-delete.svg",
            "files": this._fileViewList,
            translations: this._translationService.currentTranslations
        } as any;
        // Construct a template
        const htmlTemplate = priponkeTemplate;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#attachments-widget').html(html);

        this._initUploadField();
        this._initDeleteAttachment();

        
    }

    //inicializira file upload polje
    public _initUploadField() {
        this._uploadFilesElement = document.getElementById("attachments") as HTMLInputElement;
        this._uploadFilesElement.addEventListener("change", (ev: Event) => this._onUploadChange(ev));
    }

    //inicializira gumb za brisanje priponk
    public _initDeleteAttachment() {
        var self = this;
        $(".delete-attachment-btn").each(function () {
            var element = this as HTMLInputElement;
            element.addEventListener("click", (ev: Event) => self._onDeleteAttachment(ev));
            //$(this).on("click", (ev: Event) => self._onDeleteAttachment(ev));
        });
    }

    //shrani pripete dokumente v seznam za kasnejše pošiljanje na strežnik
    public _onUploadChange(ev: Event) {
        var th = this;
        const target = ev.target as HTMLInputElement;
        let list: FileList | null = target.files;
        if (list) {
            $(list).each(function (_index, element) {

                th._fileList.push(element);
            })
        }

        //Datoteke prekopiramo v view model, zaradi indexa
        this._fileViewList = [];
        for (let i in this._fileList) {
            this._fileViewList.push({ 'index': i, 'name': this._fileList[i].name });
        }

        this._renderAttachments();
    }

    //event za brisanje priponk
    public _onDeleteAttachment(ev: Event) {
        const target = ev.currentTarget as HTMLInputElement;
        var indexString = target.getAttribute("data-index");
        if (!indexString) {
            return;
        }
        var index = parseInt(indexString);
        if (index || index == 0) {
            this._fileList.splice(index, 1);
        }
        this._fileViewList = [];
        for (let idx in this._fileList) {
            this._fileViewList.push({ 'index': idx, 'name': this._fileList[idx].name });
        }

        this._renderAttachments();
    }

    //#endregion Attachments

    //#region Accessories field

    //render Accessories field
    private _renderAccessorieSelectField(): void {

        //Init dropdown options
        for (var i = 0; i < this._selectedTools.length; i++) {
            var tool = this._selectedTools[i];
            var quantityOptions = new Array<any>;
            for (var j = 1; j <= tool.quantity; j++) {
                var selected = this._selectedTools[i].selectedQuantity == j ? true : false;
                quantityOptions.push({ "value": j, "selected": selected });
            }
            this._selectedTools[i].quantityOptions = quantityOptions;
        }
        // Build a view model from the API data
        const viewModel = {
            "imageUrlDelete": "../img/icon-delete.svg",
            "tools": this._tools,
            "selectedTools": this._selectedTools,
            translations: this._translationService.currentTranslations
        } as any;
        // Construct a template
        const htmlTemplate = pripomockiTemplate;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#accessories-widget').html(html);

        this._onAccessorieSelected();
        this._onQuantitySelected();
        var self = this;
        $(".delete-accessory-btn").each(function () {
            var element = this as HTMLInputElement;
            element.addEventListener("click", (ev: Event) => self._onDeleteAccessorie(ev));
        });
    }

    //inicialiira on user selected event
    public _onAccessorieSelected() {
        var self = this;
        $('#toolSelect').on('change', function () {
            var id = $(this).val();
            if (self._selectedTools.find(a => a.id == id)) {
                return;
            }
            var toolIndex = self._tools.findIndex((el) => el.id == id);
            if (toolIndex == -1) {
                return;
            }
            var tool = self._tools[toolIndex];

            self._selectedTools.push({
                id: tool.id,
                name: tool.name,
                quantity: tool.quantity,
                quantityOptions: tool.quantityOptions,
                selectedQuantity: tool.quantity
            });

            self._tools[toolIndex].selected = true;

            self._renderAccessorieSelectField();
        });
    }

    public _onQuantitySelected() {
        var self = this;
        $('.quantitySelect').on('change', function () {
            var id = $(this).attr("data-toolId");
            var toolIndex = self._selectedTools.findIndex((el) => el.id == id);
            if (toolIndex == -1) {
                return;
            }
            self._selectedTools[toolIndex].selectedQuantity = $(this).val();
        });
    }

    //event za brisanje pripomočkov
    public _onDeleteAccessorie(ev: Event) {
        const target = ev.currentTarget as HTMLInputElement;
        var idString = target.getAttribute("data-id");
        if (!idString) {
            return;
        }
        var id = parseInt(idString);
        var index = this._selectedTools.findIndex((el) => el.id == id);
        if (index || index == 0) {
            this._selectedTools.splice(index, 1);
        }

        var toolIndex = this._tools.findIndex((el) => el.id == id);
        if (toolIndex != -1) {
            this._tools[toolIndex].selected = false;
        }

        this._renderAccessorieSelectField();
    }

    //#endregion UserSearchField

    //#region ManagersField

    private _renderManagersSelectField(): void {
        var translations = this._translationService.currentTranslations;
        // Build a view model from the API data
        const viewModel = {
            "imageUrlDelete": "../img/icon-delete.svg",
            "users": this._selectedManagers,
            translations: translations
        } as any;
        // Construct a template
        const htmlTemplate = izbirnikUporabnikovTemplate;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#managers-select-widget').html(html);

        this._initUserSearchField();
        this._onUserSearchSelected();
        this._initDeleteUser();
    }

    //Inicializira user search field
    public async _initUserSearchField() {
        require('select2');
        $(".user-search-field").select2({
            ajax: (await this._apiClient.userSearchOptions((settings) => {
                settings.url += "&" + jQuery.param({
                    search: $('.user-search-field').data("select2").dropdown.$search.val()
                }, false);
            })) as any,
            placeholder: "Janez Marinko / 999995665 / Mestni log - Dom 1 / 99",
            minimumInputLength: 1,
            allowClear: false
        });
    }

    //inicialiira on user selected event
    public _onUserSearchSelected() {
        var self = this;
        $('.user-search-field').on('select2:select', function (e) {
            var data = e.params.data as any;

            if (self._selectedManagers.find(a => a.id == data.id)) {
                return;
            }

            self._selectedManagers.push({
                id: data.id,
                firstName: data.firstName,
                lastName: data.lastName,
                crmId: data.crmId,
                kampus: data.kampus,
                location: data.location,
                room: data.room,
            });

            self._renderManagersSelectField();
        });
    }

    //inicializira gumb za brisanje uporabnikov
    public _initDeleteUser() {
        var self = this;
        $(".delete-user-btn").each(function () {
            var element = this as HTMLInputElement;
            element.addEventListener("click", (ev: Event) => self._onDeleteUser(ev));
        });
    }

    //event za brisanje uporabnikov
    public _onDeleteUser(ev: Event) {
        const target = ev.currentTarget as HTMLInputElement;
        var idString = target.getAttribute("data-id");
        if (!idString) {
            return;
        }
        var id = parseInt(idString);
        var index = this._selectedManagers.findIndex((el) => el.id == id);
        if (index || index == 0) {
            this._selectedManagers.splice(index, 1);
        }

        this._renderManagersSelectField();
    }
}