import * as moment from "moment";
import * as Mustache from "mustache";
import { ListResponse } from "../../../../profil/src/ts/models/list-response";
import { PaginationWidget, PaginationWidgetOptions } from "./pagination-widget";

export interface TableWidgetOptions {
    rootElement: JQuery<HTMLElement>;
    getData: (currentPage?: number) => Promise<ListResponse<any>>;
    tableMap: TableWidgetTableMap,
    onRender?: ((data: ListResponse<any>)=>any) | null 
}

export interface TableWidgetTableMap {
    title?: string,
    hideTitle?: boolean,
    noDataContent?: string,
    controls?: string,
    classInsert?: string,
    columns: Array<TableWidgetColumn>

}
export interface TableWidgetColumn {
    name: string,
    html?: string,
    value?: string,
    class? : string
}


export class TableWidget {
    private _paginationWidget: PaginationWidget | null = null;
    private _tableTemplate!: string;
    private _currentPage: number = 1;
    public constructor(private options: TableWidgetOptions) {
        this.prepareHtml();
    }    

    public get currentPage() { return this._currentPage; }

    private prepareHtml() {
        var tableMap = this.options.tableMap;
        var customTags: any = ['<%', '%>'];

        const tableTemplateString = `
                            {{#hasData}}
                        <table class="table">
                                    <thead>
                                        <tr>
                                            <%#tableMap%>
                                            <%#columns%>
                                            <th scope="col" class="<%class%>"><%{name}%></th>
                                            <%/columns%>
                                            <%/tableMap%>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {{#data}}
                                    {{#items}}
                                        <tr>
                                            <%#tableMap%>
                                            <%#columns%>

                                                <td scope="row" class="<%class%>">
                                                    <%#html%>
                                                        <%{html}%>
                                                    <%/html%>
                                                    <%#value%>
                                                        {{<%value%>}}
                                                    <%/value%>                                                    
                                                </td>

                                            <%/columns%>
                                            <%/tableMap%>
                                        </tr>
                                    {{/items}}
                                    {{/data}}
                                    </tbody>
                                </table>
                            {{/hasData}}
                            {{^hasData}}
                                <p><%tableMap.noDataContent%></p>
                            {{/hasData}}

`;

        this._tableTemplate = Mustache.render(tableTemplateString, { "tableMap": tableMap, "controls": tableMap.controls }, undefined, customTags);


        const containerTemplateString =
            `
                    <div class="tile-section">
<%^tableMap.hideTitle%>
                        <div class="tile-header">
                            <div class="title">
                                <%#tableMap%>
                                <%{title}%>
                                <%/tableMap%>
                            </div>
                            <div class="controls">
                                <%{controls}%>
                            </div>
                        </div>
              <%/tableMap.hideTitle%>
                        <div class="tile"><div class="empty-utility-item"></div>
                            <div class="table-responsive">                                
                            </div>
            <ul class="pagination" style="display:none">
            </ul>
                        </div>
                    </div>
             `;
        var containerTemplate = Mustache.render(containerTemplateString, { "tableMap": tableMap, "controls": tableMap.controls }, undefined, customTags);

        this.options.rootElement.html(containerTemplate);

        var paginationOptions: PaginationWidgetOptions = {
            rootElement: this.options.rootElement.find(".pagination"),
            onPageChange: async (currentPage) => {
                await this.refresh(currentPage);
            }
        }
        this._paginationWidget = new PaginationWidget(paginationOptions);
        this.showLoader();
        
    }

    private showLoader(): void {
        this.options.rootElement.find(".table-responsive").html(`<div class="text-center"><div class="spinner-grow" style="width: 3rem; height: 3rem;" role="status"></div></div>`);
    }

    private isLoading: boolean = false;

    public async refresh(currentPage: number, translationsService?: any): Promise<void> {
        try {
            this.isLoading = true;
            window.setTimeout(() => {
                if (this.isLoading) {
                    this._paginationWidget!.hide();
                    this.showLoader();
                }
            }, 200);
            this._currentPage = currentPage;

            var data = await this.options.getData(currentPage);

            var formators = {
                dateFormat: function () {
                    return function (timestamp: any, render: any) {
                        return moment(render(timestamp).trim()).format("L");
                    };
                },
                dateTimeFormat: function () {
                    return function (timestamp: any, render: any) {
                        return moment(render(timestamp).trim()).format("L LT");
                    };
                },
                translate: function () {
                    return function (text: any, render: any) {
                        if (translationsService != undefined) return translationsService[render(text)];
                    }
                },
                dateTimeSecondsFormat: function () {
                    return function (timestamp: any, render: any) {
                        return moment(render(timestamp).trim()).format("L LTS");
                    };
                }
            };
            var htmlRender = Mustache.render(this._tableTemplate, { "data": data, "formators": formators, "customFormators": formators, "hasData": data.totalCount > 0 });
            this.options.rootElement.find(`.table-responsive`).html(htmlRender);
            await this._paginationWidget!.load(data);
            if (this.options.onRender) {
                this.options.onRender(data);
            }
            this.isLoading = false;
        }
        catch (e) {
            var errText = translationsService["UnexpectedError"];
            this.options.rootElement.find(`.table-responsive`).html(`<div class="">${errText}</div>`);
            this.isLoading = false;
        }
    }

}