import { injectable } from "inversify";
import * as Mustache from "mustache";
import { Notyf } from "notyf";
import { SportEventApiClient } from "../ts/clients/sport-event-api-client";
import { FitnessCardModel } from "../ts/models/fitness-card-model";
import { TranslationService } from "../ts/translation-service";
import template from "./sport-fitnes-kartica-view.html";

@injectable()
export class SportFitnesKarticaView {
    private _apiClient: SportEventApiClient;
    private _translationService: TranslationService;   

    public constructor(sportEventApiClient: SportEventApiClient, translationService: TranslationService, private notyf: Notyf) {
        this._apiClient = sportEventApiClient;
        this._translationService = translationService;       
    }

    public async load(id: number): Promise<void> {
        var width = $(document).width();
        if (width && width <= 768) {
            $("header").attr('style', 'display:none');
        }
        try {
            var translations = this._translationService.currentTranslations;
            var data = null;
            try {
                data = await this._apiClient.getFitnessCard(id);          
            } catch (e: any) {
                if (e.statusCode == 404) {
                    this.notyf.error(translations.CardNotFound);
                }
                else if (e.statusCode == 401) {
                    this.notyf.error(translations.CardNoPermission);
                }
                else {
                    this.notyf.error(translations.Error);
                }
            }   
        } catch (e) {
            $('#main').text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
        this._renderData(data);       
    }

    private _renderData(data: FitnessCardModel | null): void {
        var bodyHtml = Mustache.render(template,
            {
                "data": data,
                "translations": this._translationService.currentTranslations
            });
        $("#main").html(bodyHtml); 
    }
}