import { injectable } from "inversify";
import * as Mustache from "mustache";
import template from "./sport-dnevnik-dogodkov-view.html";
import { TableWidget, TableWidgetTableMap } from "../../../skupno/src/ts/widgets/table-widget";
import { ActivityLogListInitResponse } from "../ts/models/activity-log-list-init-response";
import { TranslationService } from "../ts/translation-service";
import { SportActivityApiClient } from "../ts/clients/sport-activity-api-client";
import { UrlHelper } from "../../../skupno/src/ts/utils/url-helper";


@injectable()
export class SportDnevnikAktivnostiView {
    private _apiClient: SportActivityApiClient;
    private _tableWidget: TableWidget = null!;
    private _filtersForm: JQuery<HTMLElement> | null = null;
    private _searchInputElement: JQuery<HTMLElement> | null = null;
    private _subscriptionIdInputElement: JQuery<HTMLElement> | null = null;

    public constructor(apiClient: SportActivityApiClient, private translationService: TranslationService) {
        this._apiClient = apiClient;
    }
    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {

        try {
            var translations = this.translationService.currentTranslations;

            // Try to get data
            try {
                var listInit = await this._apiClient.getListInit(undefined, undefined, undefined, undefined, undefined, undefined, 1);               
                this._renderData(listInit);
            }
            catch (ex: any) {
                $('#main').text(translations[ex.message]);
            }


            this._filtersForm = $('#main').find("form[name=dnevnik-aktivnosti-filters]");
            this._searchInputElement = this._filtersForm.find("[name=search]");
            this._subscriptionIdInputElement = this._filtersForm.find("[name=subscription-id]");
            this._filtersForm.on("submit", (ev) => this.onFilterFormSubmit(ev));

            var tableWidget = new TableWidget({
                tableMap: this.dataTableModel(),
                rootElement: $("#main").find(".dnevnik-narocnin-table-placeholder"),
                getData: async (currentPage) => {
                    if (currentPage == 1) {
                        var page = UrlHelper.getPageFromUrl();
                        if (page != 1) {
                            currentPage = page;
                        }
                    }
                    //return await this._apiClient.getList(currentPage, 50, this._searchInputElement!.val() as string, parseInt(this._vrstaVadbeIdInputElement!.val() as string, 10) || undefined, undefined, undefined, 2, parseInt(this._MonthInputElement!.val() as string, 10) || undefined);
                    return await this._apiClient.getList(currentPage, 50, this._searchInputElement!.val() as string, undefined, undefined, parseInt(this._subscriptionIdInputElement!.val() as string, 10) || undefined, 1);                   
                }
            });
            tableWidget.refresh(1, this.translationService.currentTranslations);
            this._tableWidget = tableWidget;          
 
            
        } catch (e) {
            // Clear previous content on error
            $('#main').text(this.translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }
    private async onFilterFormSubmit(ev: JQuery.SubmitEvent) {
        ev.preventDefault();
        await this.search();
    }

    private async search():Promise<void> {
        await this._tableWidget.refresh(1, this.translationService.currentTranslations);
    }

    private _renderData(data: ActivityLogListInitResponse): void {
        // Build a view model from the API data       
        const viewModel = {
            "data": data.searchResult,
            "allSubscriptionTypes": data.allSubscriptionTypes,
            translations: this.translationService.currentTranslations
        } as any;
        // Construct a template
        const htmlTemplate = template;
        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#main').html(html); 

    }

    private dataTableModel(): TableWidgetTableMap {
        var translations = this.translationService.currentTranslations;
        const tableMap: TableWidgetTableMap = {
            noDataContent: translations.NoData,
            hideTitle: true,
            classInsert: "table-wrapper",
            columns: [
                {
                    name: translations.User,
                    html: "<a href=\"#/management/uporabniki/pregled/{{uporabnikId}}\">{{uporabnikTitle}}</a>"
                },
                {
                    name: translations.ActivityName,
                    value: "aktivnost"
                },
                {
                    name: translations.Subscription,
                    html: "{{vrstaNarocnine}}{{vrstaPaketa}}{{vrstaVadbe}}"
                },
                {
                    name: translations.Time,
                    value: "datum"
                }
            ]
        };       
        return tableMap;
    }
}