import { inject, injectable } from "inversify";
import { ApiClientBase } from "../../../../skupno/src/ts/api-client/api-client";
import { Authenticator } from "../../../../skupno/src/ts/authentication/authenticator";
import { Configuration } from "../../../../skupno/src/ts/configuration/configuration";
import { CurrentUserInfo } from "../models/current-user-info";
import { ListResponse } from "../models/list-response";
import { ProfileBasicDataSaveModel } from "../models/profile-basic-data-save-model";
import { RadAccount } from "../models/rad-account";
import { User } from "../models/user"
import { UserDetailsTraffic } from "../models/user-details-traffic-response";
/*import { UserDetailsTraffic } from "../models/user-details-traffic-response";*/
import { UserDetailsVlogeResponse } from "../models/user-details-vloge-response";
import { UserListInitResponse } from "../models/user-list-init-response";

@injectable()
export class UserApiClient extends ApiClientBase {
    public name = "UserApiClient";
    public constructor(
        @inject("Configuration") configuration: Configuration,
        @inject("Authenticator")  authenticator: Authenticator) {
        super(configuration, authenticator);
    }
    
    public async getListInit(page?: number, limit?: number, searchTerm?: string, locationId?: string): Promise<UserListInitResponse> {
        const data = {
            "page": page,
            "limit": limit,
            "search": searchTerm,
            "locationId": locationId
        };
        return await this._callApi('/user/list-init', 'GET', data) as UserListInitResponse;
    }
    public async getList(page?: number, limit?: number, searchTerm?: string, locationId?: string): Promise<ListResponse<User>> {
        const data = {
            "page": page,
            "limit": limit,
            "search": searchTerm,
            "locationId": locationId
        };
      
        return await this._callApi('/user/list', 'GET', data) as ListResponse<User>;
    }
    public async getCurrentUserInfo(): Promise<CurrentUserInfo> {
        const data = {
        };
        return await this._callApi('/user/current-user', 'GET', data, true) as CurrentUserInfo;
    }

    public async userDetails(userId: number): Promise<CurrentUserInfo> {
        const data = {
        };
        return await this._callApi('/user/user-details/' + userId, 'GET', data, true) as CurrentUserInfo;
    }

    public async userDetailsVloge(userId: number): Promise<UserDetailsVlogeResponse> {
        const data = {
        };
        return await this._callApi('/user/user-details/' + userId + '/vloge', 'GET', data, true) as UserDetailsVlogeResponse;
    }
    public async isEmailConfiremd(): Promise<boolean> {
        return await this._callApi('/user/user-details/isEmailConfirmed', 'GET', true) as boolean;
    }

    public resendVerification() {      
        return this._callApi('/user/resend', 'GET', true);
    }

    public async userDetailsTraffic(userId: number): Promise<UserDetailsTraffic> {
        const data = {
        };
        return await this._callApi('/user/user-details/' + userId + '/traffic', 'GET', data, true) as UserDetailsTraffic;
    }
    
    public async currentUserTraffic(): Promise<UserDetailsTraffic> {
        const data = {
        };
        return await this._callApi('/user/current-user/traffic/', 'GET', data, true) as UserDetailsTraffic;
    }    

    public async validate(hash: string): Promise<UserDetailsTraffic> {
        const data = {
        };
        return await this._callApi('/user/validate/' + hash, 'GET', data, false) as UserDetailsTraffic;
    }   
    
    public async userDetailsVlogeShrani(userId: number, izbraneVloge: Array<number>): Promise<UserDetailsVlogeResponse> {
        const data = izbraneVloge;
        return await this._callApi('/user/user-details/' + userId + '/vloge', 'POST', JSON.stringify(data), true) as UserDetailsVlogeResponse;
    }

    public async profileSaveBasicData(profileBasicDataSaveModel: ProfileBasicDataSaveModel): Promise<void> {
        return await this._callApi('/user/profile/save-basic-data', 'POST', JSON.stringify(profileBasicDataSaveModel), true, "");
    }

    public async profileChangePassword(newPassword:string): Promise<void> {
        return await this._callApi('/user/profile/password/change', 'POST', JSON.stringify(newPassword), true, "");
    }
    public async profileChangeEmail(newEmail: string): Promise<void> {
        return await this._callApi('/user/profile/email/change', 'POST', JSON.stringify(newEmail), true, "");
    } 

    public async getCurrentUserConnections(userId: number, page?: number, limit?: number): Promise<ListResponse<RadAccount>> {
        const data = {
            "page": page,
            "limit": limit
        };
        return await this._callApi('/user/user-details/' + userId + '/connections', 'GET', data) as ListResponse<RadAccount>;
    }

    public async getUserConnections(page?: number, limit?: number): Promise<ListResponse<RadAccount>> {
        const data = {
            "page": page,
            "limit": limit
        };
        return await this._callApi('/user/profile/connections', 'GET', data) as ListResponse<RadAccount>;
    }

    public async passwordReset(username: string): Promise<void> {
        return await this._callApi('/user/password/reset', 'POST', JSON.stringify(username), false, "");
    }
    public async passwordResetCheck(hash: string): Promise<void> {
        return await this._callApi('/user/password/reset/check/' + hash, 'GET', null, false, "");
    }
    public async passwordResetChange(hash: string, newPassword: string): Promise<void> {
        return await this._callApi('/user/password/reset/' + hash, 'POST', JSON.stringify(newPassword), false, "");
    } 

    public async checkEmailConfirmed(): Promise<any> {
        return await this._callApi('/user/checkEmailConfirmed', 'GET') as any;
    }
} 