import { injectable } from "inversify";
import { TableWidget } from "../../../../skupno/src/ts/widgets/table-widget";
import { TranslationService } from "../../ts/translation-service";
import { UrlHelper } from "../../../../skupno/src/ts/utils/url-helper";
import * as Mustache from "mustache";
import { PermissionApiClient } from "../../ts/clients/permission-api-client";
import template from "./management-permission.view.html";
import { Permission } from "../../ts/models/permission";
import permissionModal from "./management-permission-edit-modal.view.html";
import { Notyf } from "notyf";

@injectable()
export class PermissionView {
    private _apiClient: PermissionApiClient;
    private itemsPerPage = 25;
    private _translationService: TranslationService;


    public constructor(ApiClient: PermissionApiClient, private translationService: TranslationService, private notyf: Notyf  ) {
        this._apiClient = ApiClient;
        this._translationService = translationService;

    }
    /*
     * Wait for data then render it
     */
    public async load(): Promise<any> {

        try {
              
            var translations: any = await this.translationService.currentTranslations;
            this._renderData();
            var rootElement = $("#main").find(".permission-table-placeholder");

            var tableWidget = new TableWidget({
                tableMap: this.dataTableModel(translations),
                rootElement: rootElement,
                getData: async (currentPage) => {
                    if (currentPage == 1) {
                        var page = UrlHelper.getPageFromUrl();
                        if (page != 1) {
                            currentPage = page;
                        }
                    }
                    var data = await this._apiClient.getList(currentPage, this.itemsPerPage);  
                    return data
                },
             
               
            });


            tableWidget.refresh(1, this.translationService.currentTranslations);
            this.listeners();
                
        } catch (e) {
            $('#main').text(this.translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _initAddEmpoloymentTypeForm(id: number): void {
        var formElement = $("#edit-Permissions-form")

        var self = this;
        var translations = this._translationService.currentTranslations;

        $.validator.unobtrusive.parse(formElement);
        formElement.on("submit", async (ev: Event) => {
            ev.preventDefault();
            if (!formElement.valid()) {
                this.notyf.error(translations.CheckAllFields);
                return;
            }

            var revisionDescription = formElement.find("textarea[name='RevisionDescription']").val() as string;
            this._apiClient.Updaate(id, revisionDescription).then(() => {
                $("#permission-admin-add-modal").modal('hide'); 
                self.load();
                this.notyf.success(translations.Updated);
            
            })
            .catch(() => {
                this.notyf.error(translations.Error);
            });       
    })
 }
    

    private _renderEditModal(permission?: Permission): void {

        const viewModel = {
            translations: this.translationService.currentTranslations,
            permission,
            edit: false
        } as any;

        
        // Construct a template
        const htmlTemplate = permissionModal;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#permission-admin-add-modal').html(html);
        
        this._initAddEmpoloymentTypeForm(permission?.id ?? 0)

    }


    private _renderData(): void {

        // Build a view model from the API data
        const viewModel = {
            "imageUrlLogout": "../img/icon-logout.svg",
            "imageUrlDownload": "../img/icon-download.svg",
            "imageUrlArrowDown": "../img/icon-arrow-down.svg",
            "imageUrlDocument": "../img/icon-document.svg",
            "imageUrlGreenDot": "../img/icon-green-dot.svg",
            "imageUrlVerticalDots": "../img/icon-vertical-dots.svg",
            "imageUrlCalendarSmall": "../img/icon-calendar-small.svg",
            "imageUrlLocationSmall": "../img/icon-location-small.svg",
            translations: this.translationService.currentTranslations
        } as any;

        // Construct a template
        const htmlTemplate = template;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#main').html(html);

    }


    public async listeners(): Promise<void> {
        const apiClient = this._apiClient;
        $(document).on('click', '[data-action=edit]', async (ev) =>  {
            ev.preventDefault();
            var vlogaId = parseInt($(ev.currentTarget).attr("data-id") as string, 10);
            await apiClient.get(vlogaId)
                .then((data : any) => {
                    ev.preventDefault();
                    this._renderEditModal(data);
                    $("#permission-admin-add-modal").modal("show");
                })
                .catch((ex) => {
                    $('#main').text(this._translationService.currentTranslations[ex.message]);
                });
        });
        
    }

    private dataTableModel(translations: any): any {
        const tableMap = {
            title: translations["Permissions"],
            classInsert: "premission-table-placeholder",
            noDataContent: translations.NoData,
            columns: [
                {
                    name: translations["ID"] ?? "ID",
                    value: "id"
                },
                {
                    name: translations["Name"],
                    value: "name"
                },
                {
                    name: translations["Description"],
                    // value: "description"
                    html: `<div title="{{description}}" class="w-350 text-truncate">
                                {{description}}
                            </div>`
                },
                {
                    name: translations["RevisionTrailDescription"],
                    value: "revisionDescription"
                },
                {
                    name: "",
                    html: `<a href="#"  data-action="edit" data-id="{{id}}"  id="edit-premission-type-modal-btn">
                                                <img src="../img/icon-edit.svg" class="img-fluid td-icon" />
                                            </a>`
                },
            ]
        };
        return tableMap;
    }
}