import { injectable } from "inversify";
import * as Mustache from "mustache";
import { SportEventApiClient } from "../ts/clients/sport-event-api-client";
import template from "./sport-paket-prijavljeni-view.html";
import subscribersTemplate from "./widget-subscription-subscribers.html";
import { TranslationService } from "../ts/translation-service";
import { Notyf } from "notyf";
import { UserSearchViewModel } from "../ts/models/sport-subscription-viewmodel";
import * as moment from "moment";
import Quill from "quill";
import { NotificationCreateRequest } from "../ts/models/notification-create-request";
import { SportPackageViewModel } from "../ts/models/sport-package-viewmodel";

@injectable()
export class SportPaketPrijavljeniView {
    private _apiClient: SportEventApiClient;
    private _translationService: TranslationService;
    private id: number;
    private _messageEditorElement: HTMLElement | null;
    private subscribers: Array<UserSearchViewModel>;
    private loadingImages: boolean;

    public constructor(sportEventApiClient: SportEventApiClient, translationService: TranslationService, private notyf: Notyf) {
        this._apiClient = sportEventApiClient;
        this._translationService = translationService;
        this.id = 0;
        this._messageEditorElement = null;
        this.loadingImages = false;
        this.subscribers = new Array<UserSearchViewModel>();
    }
    /*
     * Wait for data then render it
     */
    public async load(item: SportPackageViewModel): Promise<void> {

        try {
            this._renderData(item);
            this.id = item.id;
            await this._renderSubscribers("");
            this.initSearchField();
            this._initDeleteSubscriber();
            this._initQuillEditor();
            this._initFormSubmit();
            //this.listeners();
            //download-paket-subscribers
            var self = this;
            $('[data-action=download-paket-subscribers]').on('click', async function (ev) {
                ev.preventDefault();
                var vlogaId = parseInt($(ev.currentTarget).attr("data-vloga-id") as string, 10);
                var vlogaName = $(ev.currentTarget).attr("data-vloga-name") as string;
                var fileContent = await self._apiClient.paketiDownload(vlogaId);

                var blob = new Blob([fileContent.fileContent], { type: fileContent.fileType });
                var url = URL.createObjectURL(blob);

                var fileName: string = fileContent.fileName;

                if (!fileName) {
                    fileName = vlogaName.toLowerCase().replace(/\s+/g, '_') + "_" + moment(new Date()).format("yyyyMMDDHHmm");
                    fileName = fileName.replace(/(_)_+|(\\.)\\.+|(-)-+/, "$1$2$3") + ".xlsx";
                }

                var fileLink = document.createElement('a');
                fileLink.href = url;
                fileLink.download = fileName;
                fileLink.click();
            });

        } catch (e) {
            // Clear previous content on error
            $('#main').text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _renderData(data: any): void {

        // Build a view model from the API data
        const viewModel = {
            "imageUrlLogout": "../img/icon-logout.svg",
            "imageUrlArrowDown": "../img/icon-arrow-down.svg",
            "imageUrlDelete": "../img/icon-delete.svg",
            "imageUrlTestImage": "../img/test-image-1.jpg",
            "data": data,
            "translations": this._translationService.currentTranslations
        } as any;
        // Construct a template
        const htmlTemplate = template;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#main').html(html);
    }

    private async _renderSubscribers(search: string): Promise<void> {
        var subscribers = await this._apiClient.getPackageSubscribers(this.id, search);
        this.subscribers = subscribers;
        const viewModel = {
            "imageUrlDelete": "../img/icon-delete.svg",
            "imageUrlTestImage": "../img/test-image-1.jpg",
            "subscriptions": subscribers,
            "translations": this._translationService.currentTranslations
        } as any;

        const htmlTemplate = subscribersTemplate;

        const html = Mustache.render(htmlTemplate, viewModel);
        $('#subscribers-table-widget').html(html);

        this.loadImages();
    }

    private async loadImages() {
        this.loadingImages = true;
        var items = $(".fitnes-card-image");
        console.log(items, this.loadingImages);
        for (var i = 0; i < items.length; i++) {
            if (!this.loadingImages) {
                break;
            }
            var item = items[i];
            var id = item.getAttribute("data-card-id");
            if (!id) {
                continue;
            }

            try {
                //var fileContent = await this._apiClient.getFitnessImage(parseInt(id), this.id);
                var fileContent = await this._apiClient.getUserFitnessImage(parseInt(id));
                var blob = new Blob([fileContent.fileContent], { type: fileContent.fileType });
                var objectURL = URL.createObjectURL(blob);
                item.setAttribute("src", objectURL);
            }
            catch (error) {
                console.log(error);
            }         
        }
        this.loadingImages = false;
    }

    //public async listeners(): Promise<void> {
    //    const apiClient = this._apiClient;

    //    $(document).on('click', '[data-action=download]', async function (ev) {
    //        ev.preventDefault();
    //        var vlogaId = parseInt($(ev.currentTarget).attr("data-vloga-id") as string, 10);
    //        var vlogaName = $(ev.currentTarget).attr("data-vloga-name") as string;
    //        var fileContent = await apiClient.paketiDownload(vlogaId);

    //        var blob = new Blob([fileContent.fileContent], { type: fileContent.fileType });
    //        var url = URL.createObjectURL(blob);

    //        var fileName: string = fileContent.fileName;

    //        if (!fileName) {
    //            fileName = vlogaName.toLowerCase().replace(/\s+/g, '_') + "_" + moment(new Date()).format("yyyyMMDDHHmm");
    //            fileName = fileName.replace(/(_)_+|(\\.)\\.+|(-)-+/, "$1$2$3") + ".xlsx";
    //        }

    //        var fileLink = document.createElement('a');
    //        fileLink.href = url;
    //        fileLink.download = fileName;
    //        fileLink.click();
    //    });

    //}

    public initSearchField() {
        var self = this;
        $(".subscribers-search-form").on("submit", async function (ev) {
            ev.preventDefault();
            if (self.loadingImages) {
                self.loadingImages = false;
            }
            var search = $("#subscribers-search").val() as string;
            self.showLoader();
            await self._renderSubscribers(search);
        });
    }

    public _initDeleteSubscriber() {
        var self = this;
        $(".delete-subscriber-btn").on("click", async function (ev) {
            const target = ev.currentTarget as HTMLInputElement;
            var dataId = target.getAttribute("data-id");
            if (!dataId) {
                return;
            }
            var userId = parseInt(dataId);
            await self._apiClient.deletePackageSubscriber(self.id, userId).then(
                async function (data: any) {
                    console.debug(data);
                    self.notyf.success("Naročnik uspešno izbrisan");
                    self._renderSubscribers('');
                },
                function (reason: any) {
                    console.debug(reason);
                    self.notyf.error("Prišlo je do napake");
                });
        });
    }

    private _initFormSubmit(): void {
        var translations = this._translationService.currentTranslations;
        var formElement = $("#notification-add-form");
        $.validator.unobtrusive.parse(formElement);
        formElement.validate();
        formElement.on("submit", (ev) => {
            ev.preventDefault();
            if (!formElement.valid()) {
                this.notyf.error(translations.CheckAllFields);
                return;
            }
            var formData = this._createFormData();
            this._apiClient.create(formData)
                .then(() => {
                    this.notyf.success(translations.SaveSuccessful);
                    $(".btn-close").click();
                })
                .catch((ex) => {
                    if (ex.statusCode == 400) {
                        this.notyf.error(translations.SaveError + "\n" + ex.message);
                    }
                    else {
                        this.notyf.error(translations.SaveError);
                    }
                });
        });

        formElement.find("#notificationAddFormBody").on("focus", () => {
            var quill: Quill = $(this._messageEditorElement!).data("quill");
            quill.focus();
        })
    }

    private _createFormData(): FormData {
        var inputsData = this._readValuesFromInputs();
        var formData = new FormData();
        formData.set("subject", inputsData.subject);
        formData.set("body", inputsData.body);
        formData.set("posljiTudiNaNepotrjene", inputsData.posljiTudiNaNepotrjene ? "true" : "false");
        this._addArrayToForm(formData, "selectedUsers", inputsData.selectedUsers);
        this._addArrayToForm(formData, "selectedRoles", inputsData.selectedRoles);
        this._addArrayToForm(formData, "selectedLocations", inputsData.selectedLocations);
        this._addArrayToForm(formData, "selectedNotificationTypes", inputsData.selectedNotificationTypes);
        this._addArrayToForm(formData, "selectedEmailStatuses", inputsData.selectedEmailStatuses);
        this._addArrayToForm(formData, "selectedUserCategories", inputsData.selectedUserCategories);
        return formData;
    }

    private _addArrayToForm(formData: FormData, fieldName: string, array: Array<any> | null): void {
        if (array == null) {
            return;
        }
        for (var i = 0; i < array.length; i++) {
            formData.append(fieldName, array[i].toString());
        }
    }

    private _readValuesFromInputs(): NotificationCreateRequest {
        var form = $("#notification-add-form");
        return {
            subject: form.find("[name=subject]").val() as string,
            body: form.find(".editor-container .ql-editor").html(),
            posljiTudiNaNepotrjene: form.find("[name=posljiTudiNaNepotrjene]").is(":checked"),
            selectedUsers: this.subscribers.flatMap((val) => val.id),
            selectedRoles: null,
            selectedLocations: null,
            selectedNotificationTypes: [9],
            selectedEmailStatuses: null,
            selectedUserCategories: null,
            selectedConfidentialityLevel: null,
            selectedNotificationRoles: null,
        };
    }

    //inicializira RTE
    public _initQuillEditor() {

        var ColorClass = Quill.import('attributors/class/color');
        var SizeStyle = Quill.import('attributors/style/size');
        var Bold = Quill.import('formats/bold');
        Quill.register(ColorClass, true);
        Quill.register(SizeStyle, true);
        Bold.tagName = 'B';   // Quill uses <strong> by default
        Quill.register(Bold, true);
        var toolbarOptions = [
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline'],        // toggled buttons
            [{ 'align': [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['link']

        ];
        let mainElement: JQuery<HTMLElement> = $('#main');
        this._messageEditorElement = mainElement.find(".editor-container")[0];
        var quill = new Quill(this._messageEditorElement, {
            modules: {
                toolbar: toolbarOptions
            },
            theme: "snow",

        });
        var toolbar = quill.getModule('toolbar');
        toolbar.addHandler('link', (_value: any) => {
            // const range = quill.getSelection(true);
            var href = prompt('Enter the URL');
            //quill.insertText(range.index, text, 'user');
            //quill.setSelection((range.index, text.length);
            (quill as any).theme.tooltip.edit('link', href);
            (quill as any).theme.tooltip.save();

            //quill.setSelection(range.index + text.length);
        });

        quill.on('text-change', (_delta, _oldDelta, _source) => {
            var html = $(this._messageEditorElement!).find(".ql-editor").text();
            mainElement.find("#notificationAddFormBody").val(html).trigger("change");
        });
        $(this._messageEditorElement).data("quill", quill);
    }

    private showLoader(): void {
        $('#subscribers-table-widget').html(`<div class="text-center"><div class="spinner-grow" style="width: 3rem; height: 3rem;" role="status"></div></div>`);
    }
}