import { injectable } from "inversify";
import * as Mustache from "mustache";
import { Notyf } from "notyf";
import Quill from "quill";
import { TranslationService } from "../ts/translation-service";
import template from "./kuponi-dodaj-view.html";
import * as DateRangePicker from 'daterangepicker';
import * as moment from 'moment';

//import * as DateRangePicker from 'daterangepicker';
import { CouponsApiClient } from "../ts/clients/coupons-api-client";

@injectable()
export class KuponiDodajView {
    private _apiClient: CouponsApiClient;
    private _translationService: TranslationService;
    private _file: any | null;

    private _messageEditorQuillElementSl: Quill | null;
    private _messageEditorQuillElementEn: Quill | null;
    private _messageEditorElementSl: HTMLElement | null;
    private _messageEditorElementEn: HTMLElement | null;
    private _uploadFilesElement: HTMLInputElement | null;

    private _validFromPicker: DateRangePicker = null!;
    private _validToPicker: DateRangePicker = null!;
    private _userCreatedFromPicker: DateRangePicker = null!;
    private _userCreatedToPicker: DateRangePicker = null!;

    public constructor(apiClient: CouponsApiClient, translationService: TranslationService, private notyf: Notyf) {
        this._apiClient = apiClient; 
        this._translationService = translationService;
        this._uploadFilesElement = null;
        this._messageEditorElementEn = null;
        this._messageEditorElementSl = null;
        this._messageEditorQuillElementSl = null;
        this._messageEditorQuillElementEn = null;
        this._file = null;
    }
    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {

        try {           
            this._renderData();
            //this._datePicker.load(5);
            this._initUploadField();           
        } catch (e) {

            // Clear previous content on error
            $('#main').text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _renderData(): void {

        // Build a view model from the API data
        const viewModel = {
            "translations": this._translationService.currentTranslations
        } as any;
        // Construct a template
        const htmlTemplate = template;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#main').html(html);

        this._initForm();
        this._initQuillEditor();

        $("#barCodeData").val('{"X":732,"Y":1472,"Width":355,"Height":178,"VerticalPadding":10,"HorizontalPadding":20,"ForegroundColor":"Black","BackgroundColor":"White","Rotation": 0}');
    }

    //inicializira obrazec
    private _initForm(): void {
        var self = this;

        this._validFromPicker = new DateRangePicker($('#main').find("#validFrom")[0], {
            showDropdowns: true,
            singleDatePicker: true,
            autoApply: true,
            minDate: moment().add(-1, 'years'),
            maxDate: moment().add(5, 'years')
        });
        this._validToPicker = new DateRangePicker($('#main').find("#validTo")[0], {
            showDropdowns: true,
            singleDatePicker: true,
            autoApply: true,
            startDate: moment().add(1, 'years'),
            minDate: moment().add(-1, 'years'),
            maxDate: moment().add(5, 'years')
        });
        this._userCreatedFromPicker = new DateRangePicker($('#main').find("#userCreatedFrom")[0], {
            showDropdowns: true,
            singleDatePicker: true,
            autoApply: true,
            minDate: moment().add(-1, 'years'),
            maxDate: moment().add(5, 'years')
        });
        this._userCreatedToPicker = new DateRangePicker($('#main').find("#userCreatedTo")[0], {
            showDropdowns: true,
            singleDatePicker: true,
            autoApply: true,
            startDate: moment().add(1, 'years'),
            minDate: moment().add(-1, 'years'),
            maxDate: moment().add(5, 'years')
        });

        var translations = this._translationService.currentTranslations;
        var formElement = $("#kuponi-kampanja-add-form");
        $.validator.unobtrusive.parse(formElement);
        formElement.validate();

        $(".submit-btn").on("click", async (ev) => {
            ev.preventDefault();

            var showPreview = false;
            //var submitType = ev.currentTarget.getAttribute("data-submitType");
            //if (submitType == "saveAndPreview") {
            //    showPreview = true;
            //}

            var fileAttached = false;
            if (this._file) {
                fileAttached = true;
                $(".template-validation").attr("style", "display:none");
            }
            else {
                $(".template-validation").removeAttr("style");
            }

            //var barCode = $("#barCodeData").val();
            //if (!barCode) {
            //    $(".barcode-validation").removeAttr("style");
            //}

            if (!formElement.valid() || !fileAttached /*|| !barCode*/) {
                this.notyf.error(translations.CheckAllFields);
                return;
            }

            var formData = new FormData();
            formData.append("name", $("#name").val() as string);
            formData.append("validFrom", (this._validFromPicker.startDate.isValid() ? this._validFromPicker.startDate.format() : undefined) as string);
            formData.append("validTo", (this._validToPicker.startDate.isValid() ? this._validToPicker.startDate.format() : undefined) as string);
            formData.append("userCreatedFrom", (this._userCreatedFromPicker.startDate.isValid() ? this._userCreatedFromPicker.startDate.format() : undefined) as string);
            formData.append("userCreatedTo", (this._userCreatedToPicker.startDate.isValid() ? this._userCreatedToPicker.startDate.format() : undefined) as string);
            formData.append("emailSubjectSl", $("#emailSubjectSl").val() as string);
            formData.append("emailSubjectEn", $("#emailSubjectEn").val() as string);
            formData.append("uiMessageSl", $("#uiMessageSl").val() as string);
            formData.append("uiMessageEn", $("#uiMessageEn").val() as string);
            formData.append("emailBodySl", this._messageEditorQuillElementSl?.root.innerHTML as string);
            formData.append("emailBodyEn", this._messageEditorQuillElementEn?.root.innerHTML as string);
            //formData.append("barCodeData", $("#barCodeData").val() as string);
            formData.append("barCodeData", "{}");
            if (this._file) {
                formData.append("attachments", this._file);
            }

            await this._apiClient.createCampaign(formData).then(
                async function (data: any) {
                    self.notyf.success(translations.CampaignSuccessfullySaved);
                    if (showPreview) {
                        window.location.href = "/#/kuponi/" + data + "?preview";
                    }
                    else {
                        window.location.href = "/#/kuponi";
                    }
                },
                function (reason: any) {
                    console.debug(reason);
                    self.notyf.error(translations.SaveError);
                }
            );
        });
    }

    //inicializira RTE
    public _initQuillEditor() {

        var ColorClass = Quill.import('attributors/class/color');
        var SizeStyle = Quill.import('attributors/style/size');
        var Bold = Quill.import('formats/bold');
        Quill.register(ColorClass, true);
        Quill.register(SizeStyle, true);
        Bold.tagName = 'B';   // Quill uses <strong> by default
        Quill.register(Bold, true);
        var toolbarOptions = [
            [{ container: "#toolbar-container" }],
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['blockquote', 'code-block'],

            [{ 'header': 1 }, { 'header': 2 }],               // custom button values
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
            [{ 'direction': 'rtl' }],                         // text direction

            [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            [{ 'font': [] }],
            [{ 'align': [] }],

            ['clean']                                         // remove formatting button
        ];
        let mainElement: JQuery<HTMLElement> = $('#main');
        this._messageEditorElementSl = mainElement.find(".editor-container")[0];
        /*console.debug("placeholder", this._messageEditorElement);*/
        this._messageEditorQuillElementSl = new Quill(this._messageEditorElementSl, {
            modules: {
                toolbar: toolbarOptions
            },
            theme: "snow"
        });
        this._messageEditorElementEn = mainElement.find(".editor-container")[1];
        /*console.debug("placeholder", this._messageEditorElement);*/
        this._messageEditorQuillElementEn = new Quill(this._messageEditorElementEn, {
            modules: {
                toolbar: toolbarOptions
            },
            theme: "snow"
        });
    }

    //inicializira file upload polje
    public _initUploadField() {
        this._uploadFilesElement = document.getElementById("attachments") as HTMLInputElement;
        this._uploadFilesElement.addEventListener("change", (ev: Event) => this._onUploadChange(ev));
    }

    //shrani pripete dokumente v seznam za kasnejše pošiljanje na strežnik
    public _onUploadChange(ev: Event) {
        
        const target = ev.target as HTMLInputElement;
        let list: FileList | null = target.files;
        if (list) {
            var translations = this._translationService.currentTranslations;
            this._file = list[0];
            $(".template-validation").attr("style", "display:none");
            $(".template-uploaded").removeAttr("style");
            $(".btn-upload").html(translations.ChangeTemplate);
        };
    }
}