import { inject, injectable } from "inversify"
import { UserApiClient } from "../clients/users-api-client";
import { CurrentUserInfo } from "../models/current-user-info";
import { TranslationService } from "../translation-service";
import { Lock } from "../../../../skupno/src/ts/utils/lock";

@injectable()
export class CurrentUserAccessor {
        
    private currentUser: CurrentUserInfo | null = null;
    private lock: Lock = new Lock();

    constructor(@inject("UserApiClient") private userApiClient: UserApiClient,
        @inject("TranslationService") private translationService: TranslationService

    ) {

    }

    public async getUser(): Promise<CurrentUserInfo> {
        const releaser = await this.lock.acquire();
        try {
            if (this.currentUser == null) {
                this.currentUser = await this.userApiClient.getCurrentUserInfo();
                await this.translationService.setCurrentLanguage(this.currentUser.languageCode);
            }
        }
        finally {
            releaser();
        }
        return this.currentUser;
    }

}