// typed-event-emitter.ts
export class TypedEventEmitter<T extends Record<string, any>> {
    private events: { [K in keyof T]?: Array<(params: T[K]) => void> } = {};

    on<K extends keyof T>(event: K, handler: (params: T[K]) => void): void {
        if (!this.events[event]) {
            this.events[event] = [];
        }
        this.events[event]?.push(handler);
    }

    off<K extends keyof T>(event: K, handler: (params: T[K]) => void): void {
        if (!this.events[event]) return;
        this.events[event] = this.events[event]?.filter(h => h !== handler);
    }

    emit<K extends keyof T>(event: K, params: T[K]): void {
        this.events[event]?.forEach(handler => handler(params));
    }
}