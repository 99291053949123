import { injectable } from "inversify";
//import * as DateRangePicker from 'daterangepicker';
import * as moment from 'moment';
import * as Mustache from "mustache";
import { SportEventApiClient } from "../ts/clients/sport-event-api-client";
import template from "./sport-seznam-dogodkov-view.html";
import packetsTemplate from "./sport-paketi-mesec.html";
import paketAddModalTemplate from "./sport-paket-dodaj-modal-view.html";
import paketUrediModalTemplate from "./sport-paket-uredi-modal-view.html";
import eventDetailsModalTemplate from "./sport-dogodek-details-modal-view.html";
import dogodkiPaketaTemplate from "./widget-dogodki-paketa.html";
import { TranslationService } from "../ts/translation-service";
import { AvailableMonthsModel, EventByDayModel, EventModel, PackageModel } from "../ts/models/sport-list-viewmodel";
import { SportPackageAddModel } from "../ts/models/sport-package-add-model";
import { Notyf } from "notyf";
import { ModalConfirm } from "./modal-confirm";
import { FullscreenLoader } from "./fullscreen-loader";

@injectable()
export class SportSeznamDogodkovView {
    private _apiClient: SportEventApiClient;
    private _translationService: TranslationService;
    private _availableMonths: Array<AvailableMonthsModel> | null;
    private _eventsByDay: Array<EventByDayModel> | null;
    private _packages: Array<PackageModel> | null;
    private _selectedEvents: Array<EventModel> | null;
    private _priceList: Array<any>;
    private _packageEvents: Array<EventModel>;
    private admin: boolean;
    private selectedQuery: string | null;
    private translations: any | null;
    //private _addValidPicker: DateRangePicker = null!;
    //private _editValidPicker: DateRangePicker = null!;


    public constructor(sportEventApiClient: SportEventApiClient, translationService: TranslationService, private notyf: Notyf, private loader: FullscreenLoader) {
        this._apiClient = sportEventApiClient; 
        this._translationService = translationService;
        this._availableMonths = null;
        this._eventsByDay = null;
        this._packages = null;       
        this._packageEvents = new Array<EventModel>;
        this._priceList = new Array<any>();
        this.selectedQuery = null;
        this._selectedEvents = new Array<EventModel>;
        this.admin = false;
        this.translations = this._translationService.currentTranslations;
           
    }
    /*
     * Wait for data then render it
     */
    public async load(query: string = ""): Promise<void> {

        try {
            this.loader.show();

            this.admin = await this._apiClient.isSportAdmin();
            if (this.admin) {
                var options = await this._apiClient.getOptions() as any;
                if (options) {
                    this._priceList = options.priceList;
                }
            }

            this._selectedEvents = new Array<EventModel>;
            if (query) {
                this.selectedQuery = query;
            }
            else if (!query && this.selectedQuery) {
                query = this.selectedQuery;
            }

            var sport = await this._apiClient.getSportList(query);
            
            this._availableMonths = sport.availableMonths;
            this._eventsByDay = sport.eventsByDay;
            this._packages = sport.packages;
            //var events = await this._apiClient.getEvents(new Date().getMonth() + 1);
            // Render new content
            this._renderData();
            this.refreshPackages();

            this._initActionButtons();

            this.initAddPackageModalButton();
            $('.form-select.sport').on('change', () => {
                this.refreshPackages();
            });

            
            var self = this;
            $('#month-select').on('change', function () {
                var query = $(this).val() as string;
                self.load(query);
            });

            this.loader.hide();

        } catch (e) {
            // Clear previous content on error
            $('#main').text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _renderData(): void {
        
        // Build a view model from the API data
        const viewModel = {
            "imageUrlLogout": "../img/icon-logout.svg",
            "imageUrlDownload": "../img/icon-download.svg",
            "imageUrlArrowDown": "../img/icon-arrow-down.svg",
            "imageUrlDocument": "../img/icon-document.svg",
            "imageUrlGreenDot": "../img/icon-green-dot.svg",
            "imageUrlVerticalDots": "../img/icon-vertical-dots.svg",
            "imageUrlCalendarSmall": "../img/icon-calendar-small.svg",
            "imageUrlLocationSmall": "../img/icon-location-small.svg",
            "eventsByDay": this._eventsByDay,
            "availableMonths": this._availableMonths,
            "formators": this.getFormators(),
            "admin": this.admin,
            "monthHasEvents": this._eventsByDay != null,
            translations: this.translations
        } as any;
        // Construct a template
        const htmlTemplate = template;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#main').html(html);

        this.initEventCheckboxChange();
        this.initEventDetailsModalButton();
    }  

    public getFormators() {
        const userLocale = document.documentElement.lang
            ? document.documentElement.lang
            : 'en';
        moment.locale(userLocale);
        var localeFormat = moment().creationData().locale.longDateFormat("L");
        return {
            dateFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format(localeFormat);
                };
            },
            longDateFormat: function () {
                return function (timestamp: any, render: any) { //sobota, 3. september
                    return moment(render(timestamp).trim()).format("dddd, D. MMMM");
                };
            },
            dateTimeFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format(localeFormat + ' HH:mm');
                };
            },
            timeRange: function () {
                return function (timestamp: any, render: any) { //3. April (19:30 - 20:15)
                    var timeString: string[] = render(timestamp).split(" ");
                    var time: Date[] = [];
                    time[0] = new Date(timeString[0]);
                    time[1] = new Date(timeString[1]);
                    if (time[0].getDate() == time[1].getDate() && time[0].getMonth() == time[1].getMonth()) {
                        return moment((time[0])).format('D. MMM (HH:mm') + " - " + moment((time[1])).format('HH:mm)')
                    } else {
                        return moment((time[0])).format('D. MMM (HH:mm)') + " - " + moment((time[1])).format('D. MMM (HH:mm)')
                    }
                };
            }
        };
    }

    public async refreshPackages() {
        //var month: number = ($('.form-select.sport').val()?.toString().length! > 2) ? new Date().getMonth() + 1 : +$('.form-select.sport').val()!;
        
        //var packets = await this._apiClient.getPackagesThisMonth(month);
        this.formatEventsInPack(this._packages);
        const viewModel = {
            "imageUrlLogout": "../img/icon-logout.svg",
            //"packages": this._packages,
            "packages": this._packages,
            "formators": this.getFormators(),
            "admin": this.admin,
            translations: this._translationService.currentTranslations
        } as any;

        const htmlTemplate = packetsTemplate;
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#PacketsTableWrap').html(html);

        $(".packet-order-add").on('click', async (e) => {
            var id: any = e.target.getAttribute("value");
            var self = this;

            if (id != null) {
                ModalConfirm.confirm({
                    cancelText: this.translations.Cancel,
                    confirmText: this.translations.Confirm,
                    content: this.translations.SubscribeConfirm,
                    confirmType: 'splosno'
                }).then(() => {
                    self._apiClient.packetAddOrder(id).then(
                        async function () {
                            self.notyf.success(self._translationService.currentTranslations.SubscribeSuccess);
                            await self.load();
                        },
                        function (reason: any) {
                            console.debug(reason);
                            self.notyf.error(self._translationService.currentTranslations.Error);
                        });
                }).catch(() => {
                });
            }
        });

        $(".packet-order-delete").on('click', async (e) => {
            var id: any = e.target.getAttribute("value");
            var self = this;
            if (id != null) {
                ModalConfirm.confirm({
                    cancelText: this.translations.Cancel,
                    confirmText: this.translations.Confirm,
                    content: this.translations.UnsubscribeConfirm,
                    confirmType: 'brisanje'
                }).then(() => {
                    self._apiClient.packetRemoveOrder(id).then(
                        async function () {
                            self.notyf.success(self._translationService.currentTranslations.UnsubscribeSuccess);
                            await self.load();
                        },
                        function (reason: any) {
                            console.debug(reason);
                            self.notyf.error(self._translationService.currentTranslations.Error);
                        });
                }).catch(() => {
                }); 
            }
        });

        $(".event-order-add").on('click', async (e) => {
            var id: any = e.target.getAttribute("value");
            var self = this;
            if (id != null) {
                ModalConfirm.confirm({
                    cancelText: this.translations.Cancel,
                    confirmText: this.translations.Confirm,
                    content: this.translations.SubscribeConfirm,
                    confirmType: 'splosno'
                }).then(() => {
                    self._apiClient.eventAddOrder(id).then(
                        async function () {
                            self.notyf.success(self._translationService.currentTranslations.SubscribeSuccess);
                            await self.load();
                        },
                        function (reason: any) {
                            console.debug(reason);
                            self.notyf.error(self._translationService.currentTranslations.Error);
                        });
                }).catch(() => {
                });                 
            }
        });

        $(".event-order-delete").on('click', async (e) => {
            var id: any = e.target.getAttribute("value");
            var self = this;

            if (id != null) {
                ModalConfirm.confirm({
                    cancelText: this.translations.Cancel,
                    confirmText: this.translations.Confirm,
                    content: this.translations.UnsubscribeConfirm,
                    confirmType: 'brisanje'
                }).then(() => {
                    self._apiClient.eventDeleteOrder(id).then(
                        async function () {
                            self.notyf.success(self._translationService.currentTranslations.UnsubscribeSuccess);
                            await self.load();
                        },
                        function (reason: any) {
                            console.debug(reason);
                            self.notyf.error(self._translationService.currentTranslations.Error);
                        });
                }).catch(() => {
                }); 
            }
        });

        $(".event-order-toggle-publish").on('click', async (e) => {
            var id: any = e.target.getAttribute("value");
            if (id != null) {
                await this._apiClient.eventTogglePublish(+id);
                this.load();
            }
        });       
    }

    //Event checkbox
    public initEventCheckboxChange() {
        var self = this;
        $(".event-checkbox").on("click", function (ev) {
            const target = ev.currentTarget as HTMLInputElement;
            var dataId = target.getAttribute("data-id");
            if (!dataId) {
                return;
            }
            var id = parseInt(dataId);
            var selected = $(target).is(":checked")
            if (selected) {
                //Add
                if (!self._eventsByDay) {
                    return;
                }
                if (self._selectedEvents && self._selectedEvents.findIndex((el) => el.id == id) != -1) {
                    //Element je že dodan
                    return;
                }

                for (var i = 0; i < self._eventsByDay?.length; i++) {
                    var eventsByDay = self._eventsByDay[i];
                    var index = eventsByDay.events.findIndex((el) => el.id == id);
                    if (index != -1) {
                        var event = eventsByDay.events[index];
                        self._selectedEvents?.push(event);
                        break;
                    }
                }
            }
            else {
                //remove
                if (!self._selectedEvents) {
                    return;
                }
                var index = self._selectedEvents.findIndex((el) => el.id == id);
                if (index || index == 0) {
                    self._selectedEvents.splice(index, 1);
                }
            }

            //if (self._selectedEvents) {
            //    self._selectedEvents = self._selectedEvents.sort(
            //        (objA, objB) => Number(objA.dtStart) - Number(objB.dtStart),
            //    );
            //}        

        });
    }

    public initEventDetailsModalButton() {
        var self = this;
        $(".event-details-btn").on("click", function (ev) {
            const target = ev.currentTarget as HTMLInputElement;
            var dataId = target.getAttribute("data-id");
            if (!dataId) {
                return;
            }
            var id = parseInt(dataId);

            if (!self._eventsByDay) {
                return;
            }

            for (var i = 0; i < self._eventsByDay?.length; i++) {
                var event = self._eventsByDay[i].events.find((el) => el.id == id);
                if (event) {
                    self._renderEventDetailsModal(event);
                    $("#event-details-modal").modal("show");
                    break;
                }
            }            
        });
    }

    private _renderEventDetailsModal(event: EventModel): void {
        var tools = JSON.parse(event.tools);
        // Build a view model from the API data
        const viewModel = {
            translations: this._translationService.currentTranslations,
            formators: this.getFormators(),
            event: event,
            admin: this.admin,
            tools: tools
        } as any;
        // Construct a template
        const htmlTemplate = eventDetailsModalTemplate;
        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#event-details-modal').html(html);

        var self = this;
        $(".download-attachment").on("click", async function (ev) {
            ev.preventDefault();
            var id = parseInt($(ev.currentTarget).attr("data-id") as string, 10);
            var label = ev.currentTarget.innerHTML;
            //var vlogaName = $(ev.currentTarget).attr("data-vloga-name") as string;
            await self._apiClient.attachmentDownload(id)
                .then((fileContent) => {
                    var blob = new Blob([fileContent.fileContent], { type: fileContent.fileType });
                    var url = URL.createObjectURL(blob);

                    //var fileName: string = fileContent.fileName;

                    //if (!fileName) {
                    //    //fileName = vlogaName.toLowerCase().replace(/\s+/g, '_') + "_" + moment(new Date()).format("yyyyMMDDHHmm");
                    //    fileName = fileName.replace(/(_)_+|(\\.)\\.+|(-)-+/, "$1$2$3") + ".xlsx";
                    //}

                    var fileLink = document.createElement('a');
                    fileLink.href = url;
                    fileLink.download = label;
                    fileLink.click();
                })
                .catch((ex) => {
                    $('#main').text(self._translationService.currentTranslations[ex.message]);
                });
        });
    }

    //Dodajanje paketa
    public initAddPackageModalButton() {
        var self = this;
        $("#add-package-modal-btn").on("click", function (ev) {
            ev.preventDefault();
            if (!self._selectedEvents || self._selectedEvents.length < 1) {
                self.notyf.error(self.translations.NoEventsSelected);
                return;
            }
            self._renderPaketDodajModal();
            $("#add-package-modal").modal("show");
        });
    }

    private _renderPaketDodajModal(): void {
        // Build a view model from the API data
        const viewModel = {
            //imageUrlDelete: "../img/icon-delete.svg",
            //events: this._selectedEvents,
            formators: this.getFormators(),
            translations: this._translationService.currentTranslations,
            priceList: this._priceList
        } as any;
        // Construct a template
        const htmlTemplate = paketAddModalTemplate;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#add-package-modal').html(html);

        this._renderAddPackageEvents();
        this._initAddPackageForm();
    }

    public _onAddPackageDeleteEvent(ev: Event) {
        const target = ev.currentTarget as HTMLInputElement;
        var idString = target.getAttribute("data-id");
        if (!idString) {
            return;
        }
        var id = parseInt(idString);

        if (!this._selectedEvents) {
            return;
        }
        var index = this._selectedEvents.findIndex((el) => el.id == id);
        if (index || index == 0) {
            this._selectedEvents.splice(index, 1);
            //Odstranimo checkbox
            var el = $('input[data-id="' + id + '"]');
            if (el) {
                el.prop("checked", false);
            }
        }

        if (!this._selectedEvents || this._selectedEvents.length < 1) {
            $("#add-package-modal").modal("toggle");
        }

        this._renderAddPackageEvents();
    }

    private _renderAddPackageEvents(): void {

        // Build a view model from the API data
        const viewModel = {
            events: this._selectedEvents,
            formators: this.getFormators(),
            translations: this._translationService.currentTranslations
        } as any;
        // Construct a template
        const htmlTemplate = dogodkiPaketaTemplate;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#add-package-modal').find('#package-events').html(html);

        var self = this;
        $(".delete-package-event-btn").each(function () {
            var element = this as HTMLInputElement;
            element.addEventListener("click", (ev: Event) => self._onAddPackageDeleteEvent(ev));
        });

    }

    private _initAddPackageForm(): void {
        var translations = this._translationService.currentTranslations;
        var formElement = $("#add-package-form");

        //const userLocale = document.documentElement.lang
        //    ? document.documentElement.lang
        //    : 'en';
        //moment.locale(userLocale);
        //var localeFormat = moment().creationData().locale.longDateFormat("LLL");
        //this._addValidPicker = new DateRangePicker($('#add-package-form').find("#valid")[0], {
        //    showDropdowns: true,
        //    singleDatePicker: true,
        //    timePicker: true,
        //    autoApply: true,
        //    locale: {
        //        format: localeFormat
        //    },
        //    minDate: moment().add(-1, 'years'),
        //    maxDate: moment().add(2, 'years')
        //});

        $.validator.unobtrusive.parse(formElement);
        formElement.validate();
      
        formElement.on("submit", async (ev) => {
            ev.preventDefault();
            if (!formElement.valid()) {
                this.notyf.error(translations.CheckAllFields);
                return;
            }
            var selectedEvents = [];
            if (this._selectedEvents) {
                for (var i = 0; i < this._selectedEvents?.length; i++) {
                    selectedEvents.push(this._selectedEvents[i].id);
                }
            }

            var priceId = $("#price").val();
            var priceElement = this._priceList.find((el) => el.id == priceId);
            var price;
            var priceCode;
            if (priceElement) {
                price = priceElement.price;
                priceCode = priceElement.id;
            }

            let data: SportPackageAddModel = {
                id: null,
                title: $("#title").val() as string,
                //valid: (this._addValidPicker.startDate.isValid() ? this._addValidPicker.startDate.toDate() : null) as Date,
                price: price,
                priceCode: priceCode,
                EventIds: selectedEvents
            };
            var self = this;

            await this._apiClient.addPackage(data).then(
                function (data: any) {
                    console.debug(data);
                    self.load();
                    $("#add-package-modal").modal("toggle");
                    self.notyf.success(self.translations.SaveSuccessful);
                },
                function (reason: any) {
                    console.debug(reason);
                    self.notyf.error(self.translations.SaveError);
                }
            );
        });
    } 

    //Urejanje paketa
    private async _renderPaketUrediModal(id: number): Promise<void> {
        var pckg = await this._apiClient.getPackage(id);
        if (pckg.priceCode) {
            for (var i = 0; i < this._priceList.length; i++) {
                //if (pckg.priceCode.substring(1, pckg.priceCode.length) == this._priceList[i].id) {
                if (pckg.priceCode == this._priceList[i].id) {
                    this._priceList[i].selected = true;
                }
                else {
                    this._priceList[i].selected = false;
                }
            }
        }
        this._packageEvents = pckg.events;
        // Build a view model from the API data
        const viewModel = {
            formators: this.getFormators(),
            translations: this._translationService.currentTranslations,
            priceList: this._priceList,
            package: pckg,
        } as any;
        // Construct a template

        const htmlTemplate = paketUrediModalTemplate;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#edit-package-modal').html(html);

        this._renderEditPackageEvents();
        this._initEditPackageForm();

        //const userLocale = document.documentElement.lang
        //    ? document.documentElement.lang
        //    : 'en';

        //var valid = $('#edit-package-form').find("#valid");
        //var vt2 = $('#edit-package-form').find("#valid").val(new Date(pckg.valid).toLocaleDateString(userLocale));
        //console.log(valid);
        //console.log(vt2);
        //$('#add-package-form').find("#valid").val(new Date(pckg.valid).toLocaleDateString(userLocale));

    }

    private _renderEditPackageEvents(): void {
        // Build a view model from the API data
        const viewModel = {
            events: this._packageEvents,
            formators: this.getFormators(),
            translations: this._translationService.currentTranslations
        } as any;
        // Construct a template
        const htmlTemplate = dogodkiPaketaTemplate;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#edit-package-modal').find('#package-events').html(html);

        var self = this;
        $('#edit-package-modal').find(".delete-package-event-btn").each(function () {
            var element = this as HTMLInputElement;
            element.addEventListener("click", (ev: Event) => self._onEditPackageDeleteEvent(ev));
        });
    }

    public _onEditPackageDeleteEvent(ev: Event) {
        const target = ev.currentTarget as HTMLInputElement;
        var id = parseInt(target.getAttribute("data-id") as string);

        if (!this._packageEvents) {
            return;
        }
        var index = this._packageEvents.findIndex((el) => el.id == id);
        if (index || index == 0) {
            this._packageEvents.splice(index, 1);
        }
        this._renderEditPackageEvents();
    }

    private _initEditPackageForm(): void {
        var formElement = $("#edit-package-form");

        //const userLocale = document.documentElement.lang
        //    ? document.documentElement.lang
        //    : 'en';
        //moment.locale(userLocale);
        //var localeFormat = moment().creationData().locale.longDateFormat("LLL");
        //this._editValidPicker = new DateRangePicker($('#edit-package-form').find("#valid")[0], {
        //    showDropdowns: true,
        //    singleDatePicker: true,
        //    timePicker: true,
        //    locale: {
        //        format: localeFormat
        //    },
        //    autoApply: true,
        //    minDate: moment().add(-1, 'years'),
        //    maxDate: moment().add(2, 'years')
        //});

        $.validator.unobtrusive.parse(formElement);
        formElement.validate();

        formElement.on("submit", async (ev) => {
            ev.preventDefault();
            if (!formElement.valid()) {
                this.notyf.error(translations.CheckAllFields);
                return;
            }
            var noEvents = true;
            var selectedEvents = [];
            if (this._packageEvents) {
                for (var i = 0; i < this._packageEvents?.length; i++) {
                    selectedEvents.push(this._packageEvents[i].id);
                    noEvents = false;
                }
            }

            var priceId = $("#price").val();
            var priceElement = this._priceList.find((el) => el.id == priceId);
            var price;
            var priceCode;
            if (priceElement) {
                price = priceElement.price;
                priceCode = priceElement.id;
            }

            let data: SportPackageAddModel = {
                id: parseInt($("#id").val() as string),
                title: $("#title").val() as string,
                //valid: (this._editValidPicker.startDate.isValid() ? this._editValidPicker.startDate.toDate() : null) as Date,
                price: price,
                priceCode: priceCode,
                EventIds: selectedEvents
            };

            var self = this;
            var translations = this._translationService.currentTranslations;

            if (noEvents) {
                ModalConfirm.confirm({
                    cancelText: translations.Cancel,
                    confirmText: translations.Confirm,
                    content: self.translations.NoEventsPackageDelete,
                    confirmType: 'brisanje'
                }).then(async () => {
                    await this._apiClient.editPackage(data).then(
                        function (data: any) {
                            console.debug(data);
                            self.load();
                            $("#edit-package-modal").modal("toggle");
                            self.notyf.success(self.translations.SaveSuccessful);
                        },
                        function (reason: any) {
                            console.debug(reason);
                            self.notyf.error(self.translations.SaveError);
                        }
                    );
                }).catch(() => {
                    return;
                });
            }
            else {
                await this._apiClient.editPackage(data).then(
                    function (data: any) {
                        console.debug(data);
                        self.load();
                        $("#edit-package-modal").modal("toggle");
                        self.notyf.success(self.translations.SaveSuccessful);
                    },
                    function (reason: any) {
                        console.debug(reason);
                        self.notyf.error(self.translations.SaveError);
                    }
                );
            }
        });
    }


    private _initActionButtons() {
        var rootElement = $("#main").find(".events-root-element");
        var self = this;
        var translations = this._translationService.currentTranslations;
        rootElement.find(".popover-event-actions").each((_index, element) => {
            var id = parseInt(element.getAttribute("data-id") as string);
            var content = '<ul><li><a href="#/sport/dogodek/' + id + '" data-action="edit-event" class="edit">' + translations.Edit + '</a></li>';
            var el = null;
            if (self._eventsByDay) {
                var found = false;
                for (var i = 0; i < self._eventsByDay?.length; i++) {
                    for (var j = 0; j < self._eventsByDay[i].events.length; j++) {
                        if (self._eventsByDay[i].events[j].id == id) {
                            found = true;
                            el = self._eventsByDay[i].events[j];
                            break;
                        }
                    }
                    if (found) {
                        break;
                    }
                }
            }
            
            if (el) {
                //if (el.published == true) {
                //    content += '<li><a href="#" class="deactivate" data-action="toggle-active-package" data-id="' + id + '">' + translations.Deactivate + '</a></li>';
                //}
                //else {
                //    content += '<li><a href="#" class="activate" data-action="toggle-active-package" data-id="' + id + '">' + translations.Activate + '</a></li>';
                //}
                if (el.canDelete) {
                    content += '<li><a href="#" data-action="delete" class="delete" data-id="' + id + '">' + translations.Delete + '</a></li>'
                }
            }
            content += '</ul>';

            $(element).popover({
                container: rootElement[0],
                //content: '<ul><li><a tabindex="0" href="#" class="delete" data-action="delete" data-id="' + id + '">' + translations.Delete + '</a></li></ul>',
                content: content,
                html: true,
                sanitize: false,
                trigger: "focus",
                delay: {
                    show: 0,
                    hide: 200
                }
            });
        });
        rootElement.find(".popover-package-actions").each((_index, element) => {
            var id = parseInt(element.getAttribute("data-id") as string);
            var content = '<ul><li><a href="#" class="edit" data-action="edit-package" data-id="' + id + '">' + translations.Edit + '</a></li>';
            var el = self._packages?.find((el) => el.id == id);
            if (el) {
                if (el.published == 1) {
                    content += '<li><a href="#" class="deactivate" data-action="toggle-active-package" data-id="' + id + '">' + translations.Deactivate + '</a></li>';
                }
                else {
                    content += '<li><a href="#" class="activate" data-action="toggle-active-package" data-id="' + id + '">' + translations.Activate + '</a></li>';
                }
                if (el.canDelete) {
                    content += '<li><a href="#" data-action="delete-package" class="delete" data-id="' + id + '">' + translations.Delete + '</a></li>'
                }
            }
            
            content += '</ul>';
            $(element).popover({
                container: rootElement[0],
                content: content,
                html: true,
                sanitize: false,
                trigger: "focus",
                delay: {
                    show: 0,
                    hide: 200
                }
            });
        });

        rootElement.on("click", "[data-action=delete]", async (ev) => {
            ev.preventDefault();
            var id = parseInt($(ev.currentTarget).attr("data-id") as string, 10);
            ModalConfirm.confirm({
                cancelText: translations.Cancel,
                confirmText: translations.Confirm,
                content: translations.ConfirmDeleteContent,
                confirmType: 'brisanje'
            }).then(() => {
                this._apiClient.deleteEvent(id).then(async () => {
                    await self.load();
                })
                    .catch(() => {
                        this.notyf.error(translations.DeleteError);
                    });
            }).catch(() => {
                console.log("delete canceled:" + id);
            });
        });

        rootElement.on("click", "[data-action=edit-event]", async () => {
            window.scrollTo(0, 0);
        });

        rootElement.on("click", "[data-action=delete-package]", async (ev) => {
            ev.preventDefault();
            var id = parseInt($(ev.currentTarget).attr("data-id") as string, 10);
            ModalConfirm.confirm({
                cancelText: translations.Cancel,
                confirmText: translations.Confirm,
                content: translations.ConfirmDeleteContent,
                confirmType: 'brisanje'
            }).then(() => {
                this._apiClient.deletePackage(id).then(async () => {
                    await self.load();
                })
                    .catch(() => {
                        this.notyf.error(translations.DeleteError);
                    });
            }).catch(() => {
                console.log("delete canceled:" + id);
            });
        });

        rootElement.on("click", "[data-action=toggle-active-package]", async (ev) => {
            ev.preventDefault();
            var id = parseInt($(ev.currentTarget).attr("data-id") as string, 10);
            this._apiClient.packageTogglePublish(id).then(async () => {
                await self.load();
            })
                .catch(() => {
                    this.notyf.error(translations.DeleteError);
                });
        });
        rootElement.on("click", "[data-action=edit-package]", async (ev) => {
            ev.preventDefault();
            var id = parseInt($(ev.currentTarget).attr("data-id") as string, 10);
            //this._apiClient.getPackage(id).then(async () => {
            //    self._renderPaketUrediModal(id);
            //    $("#edit-package-modal").modal("show");
            //})
            //.catch(() => {
            //    this.notyf.error(translations.error);
            //});
            self._renderPaketUrediModal(id);
            $("#edit-package-modal").modal("show");
        });
    }

   
    private formatEventsInPack(packs: any) {

        packs.forEach(function (pckg: any) {
            var eventGroups: Array<Array<object>> = new Array<Array<object>>;
            var events: Array<object> = new Array<object>;
            var innerGroupIndex: number = 0;
            for (var i: number = 0; i < pckg.events.length; i++) {

                if (innerGroupIndex < 3) {
                    events.push(pckg.events[i]);
                    innerGroupIndex++;
                } else {
                    eventGroups.push(events.slice());
                    events = new Array<object>;
                    events.push(pckg.events[i]);
                    innerGroupIndex = 1;
                }
            }

            if (events) {
                eventGroups.push(events.slice());
            }

            pckg.events = eventGroups;
        });
        return packs;
    }
}