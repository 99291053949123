import { NavigationNode } from "./models/navigation-node";

var rootNodes: Array<NavigationNode> = [
    { "title": "Desktop", "class": "namizje", "url": "/#" },
    {
        title: "Management",
        "class": "management",
        url: "#/management/uporabniki",
        hasChildren: true,
        permissions: 778,
        children: [
            { "title": "Users", "class": "", "url": "#/management/uporabniki", permissions: 778 },
            { "title": "Roles", "class": "", "url": "#/management/vloge", permissions: 2 },
            { "title": "Locations", "class": "", "url": "#/management/lokacije", permissions: 2 },
            { "title": "Campus", "class": "", "url": "#/management/campus", permissions: 2 },
            { "title": "Permissions", "class": "", "url": "#/management/pravice", permissions: 2  },
        ]
    },

    {
        title: "Internet",
        "class": "internet",
        url: "#/internet/moj-dostop",
        hasChildren: true,
        children: [
            { "title": "MyAccess", "class": "", "url": "#/internet/moj-dostop", permissions: 0 },
            //{ "title": "Nas", "class": "", "url": "#/internet/nas", permissions: 24 },
            { "title": "LoginLog", "class": "", "url": "#/internet/dnevnik-prijav", permissions: 1022 },
            { "title": "ErrorLog", "class": "", "url": "#/internet/dnevnik-napak", permissions: 1022 },
        ]
    },

    {
        title: "Overnights",
        "class": "prenocitve",
        url: "#/prenocitve",
        hasChildren: true,
        permissions: 524288,
        children: [
            { "title": "Login", "class": "", "url": "#/prenocitve/prijava", permissions: 524288 },
        ]
    },

    {
        title: "Notifications",
        "class": "obvestila",
        url: "#/obvestila",
        hasChildren: true,
        permissions: 0,
        children: [
            { "title": "PublishedNotifications", "class": "", "url": "#/obvestila/objavljena-obvestila", permissions: 7170 },
            { "title": "AddNew", "class": "", "url": "#/obvestila/dodaj-novo", permissions: 7170 },
        ]
    },

    {
        title: "Damages",
        "class": "okvare",
        url: "#/okvare",
        hasChildren: true,
        permissions: 65536,
        children: [
            { "title": "DutyBook", "class": "", "url": "#/okvare/dezurna-knjiga", permissions: 65536 },
            { "title": "SkodniZapisnik", "class": "", "url": "#/okvare/skodni-zapisnik", permissions: 65536 },
        ]
    },

    {
        title: "Invoices",
        "class": "racuni",
        url: "#/racuni",
        hasChildren: true,
        permissions: 536870912,
        children: []
    },
  

    {
        title: "Notices",
        "class": "opomini",
        url: "#/opomini",
        hasChildren: true,
        permissions: 65536,
        children: []
    },

    {
        title: "Statements",
        "class": "izjave",
        url: "#/izjave",
        hasChildren: true,
        permissions: 16777216,
        children: [
            { "title": "AllStatements", "class": "", "url": "#/izjave/seznam", permissions: 33554432 },
            { "title": "AddStatement", "class": "", "url": "#/izjave/dodaj", permissions: 33554432 },
        ]
    },


    {
        title: "Sport",
        "class": "sport",
        url: "#/sport",
        hasChildren: true,
        permissions: 262144,
        children: [
            { "title": "ListEvents", "class": "", "url": "#/sport/seznam-dogodkov", permissions: 262144 },
            { "title": "AddEvent", "class": "", "url": "#/sport/dodaj-dogodek", permissions: 131072 },
            { "title": "SportEventLog", "class": "", "url": "#/sport/dnevnik-dogodkov", permissions: 131072 },
            { "title": "Subscription", "class": "", "url": "#/sport/narocnina", permissions: 262144 },
            { "title": "AddSubscription", "class": "", "url": "#/sport/dodaj-narocnino", permissions: 131072 },
            { "title": "SportActivityLog", "class": "", "url": "#/sport/dnevnik-aktivnosti", permissions: 131072 }, 
            { "title": "MyFitnessCard", "class": "", "url": "#/sport/moja-fitnes-kartica", permissions: 262144 },            
            { "title": "ManageCards", "class": "", "url": "#/sport/upravljaj-kartice", permissions: 131072 },                       
        ]
    },

    {
        title: "Coupons",
        "class": "kuponi",
        url: "#/kuponi",
        hasChildren: true,
        permissions: 268435456,
        children: [
            { "title": "AddNew", "class": "", "url": "#/kuponi/dodaj", permissions: 268435456 },
        ]
    },

    {
        title: "Relocation",
        "class": "selitve",
        url: "#/selitve",
        hasChildren: true,
        permissions: 67108864,
        children: [
            { "title": "EditRelocation", "class": "", "url": "#/selitve/urejanje", permissions: 134217728 },
            { "title": "Requests", "class": "", "url": "#/selitve/zahtevki", permissions: 134217728 },
        ]
    },
   
    {
        title: "Revision",
        "class": "revizija",
        url: "#/revizija",
        hasChildren: true,
        permissions: 1073741824,
        children: [
        ]
    },
    // {
    //     title: "Reservations",
    //     "class": "reservations",
    //     url: "#/uporaba-prostorov",
    //     hasChildren: true,
    //     permissions: 1073741824,
    //     children: [
    //     ]
    // },
];
export default rootNodes;