import { Container } from "inversify";
import { Router } from "./routing/router";
import "reflect-metadata";

export abstract class Application {

    private _container: Container;
	public constructor(){
		this._container = new Container();
    }

    public async run(): Promise<void> {
        await this.configureServices(this._container);
        await this.execute();
    }

    protected abstract configureServices(services: Container): Promise<void>;

    private async execute(): Promise<void> {
        var router = this._container.get<Router>("Router");
        await router.execute();
    }
}