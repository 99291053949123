import { inject, injectable } from "inversify";
import { ApiClientBase } from "../../../../skupno/src/ts/api-client/api-client";
import { Authenticator } from "../../../../skupno/src/ts/authentication/authenticator";
import { Configuration } from "../../../../skupno/src/ts/configuration/configuration";
import { ListResponse } from "../models/list-response";
import { Role } from "../models/role"
import { VlogaDetailsResponse } from "../models/vloga-details-response";
import { VlogaShraniResponse } from "../models/vloga-shrani-request";
import {  parse } from "content-disposition-header";
import { DownloadedFile } from "../models/downloaded-file";

@injectable()
export class RoleApiClient extends ApiClientBase {
    public name = "RoleApiClient";
    public constructor(
        @inject("Configuration") configuration: Configuration,
        @inject("Authenticator")  authenticator: Authenticator) {
        super(configuration, authenticator);
    }

    public async getList(page?: number, limit?: number): Promise<ListResponse<Role>> {        
        const data: JSON = <JSON><unknown>{
            "page": page,
            "limit": limit
        };
        return await this._callApi('/role/list', 'GET', data) as ListResponse<Role>;
    }

    public async roleDetails(vlogaId: number): Promise<VlogaDetailsResponse> {
        const data = {};
        return await this._callApi('/role/' + vlogaId, 'GET', data) as VlogaDetailsResponse;
    }

    public async roleShrani(vlogaId: number, vloga: VlogaShraniResponse): Promise<VlogaDetailsResponse> {
        const data = JSON.stringify(vloga);
        return await this._callApi('/role/' + vlogaId, 'POST', data) as VlogaDetailsResponse;
    }

    public async roleDownload(vlogaId: number): Promise<DownloadedFile> {
        const data = {};
        var file: DownloadedFile = null as any;

        await this._callApi('/role/' + vlogaId + "/download", 'GET', data, true, "binary", (data, _status, jqueryXhr: JQueryXHR) => {
            var contentDisposition = jqueryXhr.getResponseHeader("Content-Disposition");
            var filename: string = "";
            if (contentDisposition) {
                var contentDispositionParsed = parse(contentDisposition as string);
                filename = contentDispositionParsed.parameters.filename;
            }
            var filetype = jqueryXhr.getResponseHeader("Content-Type");
            file = {
                fileName: filename,
                fileType: filetype,
                fileContent: data
            } as DownloadedFile;
        });
         
        return file;        
    }

}