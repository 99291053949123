import { TempusDominus } from "@eonasdan/tempus-dominus";
import { injectable } from "inversify";
import { classroomReservationsModel } from "../../ts/models/reservations/classroomReservationsModel";
type ReservationKeys = 'conferenceRoom' | `desk${number}`;

// reservation-utils.ts
@injectable()
export class ReservationUtils {
    public static initializeReservations(): Record<ReservationKeys, boolean> {
        const reservations: Partial<Record<ReservationKeys, boolean>> = { conferenceRoom: false };
        for (let i = 1; i <= 24; i++) {
            reservations[`desk${i}` as ReservationKeys] = false;
        }
        return reservations as Record<ReservationKeys, boolean>;
    }

    public static initializeTimePickers(startElement: HTMLElement | null, endElement: HTMLElement | null): { 
        estimatedTimeFrom: TempusDominus | undefined, 
        estimatedTimeTo: TempusDominus | undefined 
    } {
        if (!startElement || !endElement) {
            return { estimatedTimeFrom: undefined, estimatedTimeTo: undefined };
        }

        const config = {
            display: {
                viewMode: 'clock' as const,
                components: {
                    decades: false,
                    year: false,
                    month: false,
                    date: false,
                    hours: true,
                    minutes: true,
                    seconds: false,
                    useTwentyfourHour: true
                }
            },
            localization: {
                format: 'HH:mm'
            }
        };

        return {
            estimatedTimeFrom: new TempusDominus(startElement, config),
            estimatedTimeTo: new TempusDominus(endElement, config)
        };
    }


    public static updateVisibility(selectedItemId: string | null): void {
        const reservationInfo = document.querySelector('.reservation-info-wrapper');
        if (reservationInfo instanceof HTMLElement) {
            const formElements = reservationInfo.querySelectorAll('.form-content');
            const infoMessage = reservationInfo.querySelector('.info-message');
            formElements.forEach(element => {
                if (element instanceof HTMLElement) {
                    element.style.display = selectedItemId === null ? 'none' : 'block';
                }
            });
            if (infoMessage instanceof HTMLElement) {
                infoMessage.style.display = selectedItemId === null ? 'block' : 'none';
            }
        }
    }

    public static hasPermission(userPermissions: bigint, permissionToCheck: bigint): boolean {
        return (userPermissions & permissionToCheck) !== 0n;
    }

    public static updateDeskColors(
        existingReservations: Array<classroomReservationsModel>,
        currentlySelectedDesk: string | null
    ): void {
        $("#conferenceRoom, #entireRoom").each((_, element) => {
            const elementId = element.id;
            const isSelected = elementId === currentlySelectedDesk;

            if (isSelected) {
                $(element).css('background-color', 'rgba(255, 111, 0, 1)');
            } else {
                $(element).css('background-color', '');
            }
        });
        $(".desk").each((_, element) => {
            const elementId = element.id;
            const isReserved = existingReservations.some(r => r.classroomReservationTypeId === elementId);
            const isSelected = elementId === currentlySelectedDesk;

            if (isReserved) {
                $(element).css('background-color', isSelected ? 'rgba(255, 111, 0, 1)' : 'rgba(241, 91, 78, 0.6)');
            } else if (isSelected) {
                $(element).css('background-color', 'rgba(255, 111, 0, 1)');
            } else {
                $(element).css('background-color', '');
            }
        });
    }

    public static validateReservation(type: number, userCount: number, isPrivate?: boolean): string | null {
        if (type === 0) {
            if (userCount === 0) {
                return "Please select at least 1 user";
            } else if (userCount > 1) {
                return "Please select only one user";
            }
        } else if (type === 1) {
            if (isPrivate) {
                if (userCount !== 1) {
                    return "Please select exactly one user for private reservation";
                }
            } else {
                if (userCount !== 3) {
                    return userCount < 3
                        ? "Please select at least 3 users"
                        : "Please select only 3 users";
                }
            }
        }
        return null;
    }

    public static clearSelection(
        currentlySelectedDesk: string | null,
        existingReservations: Array<classroomReservationsModel>,
        callbacks: {
            updateVisibility: () => void,
            renderUserSelectField: () => void,
            initializeTimePickers: () => void
        }
    ): {
        currentlySelectedDesk: string | null,
        selectedItemId: string | null,
        currentSelectedDeskTitle: string | null,
        reservations: Record<ReservationKeys, boolean>
    } {
        if (currentlySelectedDesk) {
            const element = document.getElementById(currentlySelectedDesk);
            const isReserved = existingReservations.some(r => r.classroomReservationTypeId === currentlySelectedDesk);

            if (element instanceof HTMLElement) {
                if (!isReserved) {
                    element.style.backgroundColor = '';
                } else {
                    element.style.backgroundColor ='rgba(241, 91, 78, 0.6)';
                }
            }

            const reservations: Record<ReservationKeys, boolean> = { conferenceRoom: false };
            for (let i = 1; i <= 24; i++) {
                reservations[`desk${i}` as ReservationKeys] = false;
            }

            callbacks.updateVisibility();
            callbacks.renderUserSelectField();
            callbacks.initializeTimePickers();

            return {
                currentlySelectedDesk: null,
                selectedItemId: null,
                currentSelectedDeskTitle: null,
                reservations
            };
        }

        return {
            currentlySelectedDesk,
            selectedItemId: null,
            currentSelectedDeskTitle: null,
            reservations: { conferenceRoom: false }
        };
    }
}
