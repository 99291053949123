import { inject, injectable } from "inversify";
import * as Mustache from "mustache";
import template from "./navigation-view.html";
import  "bootstrap";
import { Router } from "../../../skupno/src/ts/routing/router";
import { TranslationService } from "../ts/translation-service";
import { SiteMapManager } from "../ts/utilities/sitemap-manager";
import { CurrentUserAccessor } from "../ts/utilities/current-user-accessor";
//import { push } from "../../../webpack.config";
//import { parseJSON } from "jquery";

@injectable()
export class NavigationView {
    private _router: Router;
    private _translationService: TranslationService;
    private _level1MenuItems: JQuery<HTMLElement> = null!;
    private _level2MenuItems: JQuery<HTMLElement> = null!;
    private _sideNavigation: JQuery<HTMLElement> = null!;
    

    constructor( @inject("Router") router: Router,
        @inject("TranslationService") translationService: TranslationService,
        @inject("SiteMapManager") private sitemapManager: SiteMapManager,
        @inject("CurrentUserAccessor") private currentUserAccessor: CurrentUserAccessor
    ) {        
        this._router = router;
        this._translationService = translationService;
        $(window).on("hashchange", () => this._onChange());

    }

   

    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {
        try
        {
            // Render new content
            await this._renderData();
            this._initialize();
            $('.nav-link').on('mouseover', function () {
                $(this).parent().addClass('active-hover');
            }).on('mouseout', function () {
                $(this).parent().removeClass('active-hover');
            });

            $("#offcanvasNav a.nav-link-secondary").click(function () {
                $('.offcanvas').offcanvas('hide');
            });
            $("#offcanvasNav a.nav-link").click(function () {
                var child = $(this).closest(".nav-item").find(".nav-link-secondary");
                if (!child || child.length <= 1) {
                    $('.offcanvas').offcanvas('hide');
                }
            });

            $("#offcanvasTop a.edit-profile").click(function () {
                $('.offcanvas.offcanvas-top').offcanvas('hide');
            })
        }
        catch (e) {

            // Clear previous content on error
            $('#main').text('Napaka pri nalaganju');
            throw e;
        }
    }

    private _onChange() {
        var url = this._router.getVirtualUrl();
        this.setMenuSelected(url);       
    }

    private _initialize(): void{
        var sideNavigation = $('#side-navigation');
        this._sideNavigation = sideNavigation;
        this._level1MenuItems = sideNavigation.find(".nav-link");
        this._level2MenuItems = sideNavigation.find(".nav-link-secondary");

        this._level1MenuItems.on("click", (ev: Event) => this._onDropdownClick(ev));
        var currentUrl = this._router.getVirtualUrl();
        this.setMenuSelected(currentUrl);       

    }

    public setMenuSelected(url: URL): void {
        var normalizedUrl = "#" + url.pathname;
        if (this._sideNavigation == null) {
            return;
        }

        var menuNodes = this._sideNavigation.find(".nav-item");
        menuNodes.removeClass("active");
        this._level1MenuItems.removeClass("active");
        this._level2MenuItems.removeClass("active");
        
        //preverjamo prvo na podmenijih
        var submenu = this._level2MenuItems.filter((_index, item) => {
            var normalizedHref = this.normalizeUrl($(item).attr("href") as string);
            return normalizedHref == normalizedUrl;
        })[0];

        if (submenu) {           

            $(submenu).addClass("active").parents(".nav-item").addClass("active")
                .find("[data-bs-toggle]").removeClass("collapsed")
                .siblings("ul").addClass("show");
            return;
        }

        //preverimo root
        var menu = this._level1MenuItems.filter((_index, item) => {
            var normalizedHref = this.normalizeUrl($(item).attr("href") as string);
            return normalizedHref == normalizedUrl;
        })[0];

        if (menu) {
            $(menu).addClass("active").parents(".nav-item").addClass("active")
                .find("[data-bs-toggle]").removeClass("collapsed")
                .siblings("ul").addClass("show");
            return;
        }

        //preverimo delno ujemanje z root
        menu = this._level1MenuItems.filter((_index, item) => {
            var normalizedHref = this.normalizeUrl($(item).attr("href") as string);
            return normalizedUrl.startsWith(normalizedHref);
        }).last()[0];

        if (menu) {
            $(menu).addClass("active").parents(".nav-item").addClass("active")
                .find("[data-bs-toggle]").removeClass("collapsed")
                .siblings("ul").addClass("show");
            return;
        }
    }

    private normalizeUrl(href: string | null): string {
        if (!href) {
            return "";
        }

        if (href.startsWith("http")) {
            var url = new URL(href);
        }
        else {
            var url = new URL("http://nidomene" + href);
        }
        if (url.pathname == "/") {
            var urlHash = (url.hash || "/");
            if (!urlHash.startsWith("#")) {
                return "#" + urlHash;
            }
            return urlHash;
        }
        return href;
    }

    private _onDropdownClick(ev: Event): void {             

        var menuNodes = this._sideNavigation.find(".nav-item");
        menuNodes.removeClass("active");

        let currentTarget = $(ev.currentTarget as HTMLElement);
        let href = currentTarget.attr("href") as string;

        var navItem = currentTarget.parents(".nav-item:first");
        navItem.addClass("active");
        currentTarget.addClass("active");

        if (!currentTarget.attr("data-bs-toggle")) {
            this._sideNavigation.find("[data-bs-toggle]").addClass("collapsed")
                .each((_index, element) => { $(element).siblings("ul").removeClass("show"); });
        }

        document.location.href = href;
    }

    private async _renderData(): Promise<void> {        
        // Build a view model from the API data
        var currentUser = await this.currentUserAccessor.getUser();
        var nodes = this.sitemapManager.getNodesForUser(currentUser.permissions || 0);
        var translations = this._translationService.currentTranslations;
        const viewModel = {
            "logoImagePath": "../img/studentski-dom-ljubljana.png",
            "navigationElements": nodes,
            "translations": translations,
            "kratica": currentUser.inicialke,
            "translate": () => {
                return function (template: any, render: any) {
                    var val = render(template);
                    return translations[val];
                };
            }
        } as any;
       
        viewModel.translations = this._translationService.currentTranslations;
        
        // Construct a template
        const htmlTemplate = template;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#side-navigation').html(html);
        // ustvari bootstrap dropdown objekte
        //$('#side-navigation').html(html).find("[data-bs-toggle]").each(function (_index, element) {
        //    new bootstrap.Dropdown(element, {
        //        autoClose: true,
        //        display: "dynamic"                
        //    });
        //});      

       

    }
}