export class Lock {
    private tip: Promise<void>;

    constructor() {
        this.tip = Promise.resolve<void>(undefined);
    }

    public async acquire(): Promise<() => void> {
        const oldTip = this.tip;
        let resolver = nop;
        const promise = new Promise<void>(resolve => { resolver = resolve; });
        this.tip = oldTip.then(() => promise);
        return oldTip.then(() => resolver);
    }
}

// tslint:disable-next-line:no-empty
export function nop() { }

export class Resolver<T> {
    public readonly promise: Promise<T>;

    private resolver: (result: T | PromiseLike<T>) => void = null!;
    private rejecter: (reason: any | PromiseLike<any>) => void = null!;

    constructor() {
        this.promise = new Promise<T>((resolve, reject) => {
            this.resolver = resolve;
            this.rejecter = reject;
        });
    }

    get resolve() { return this.resolver; }
    get reject() { return this.rejecter; }
}

// const lock = new Lock();
// const releaser = await lock.acquire();
// try {
//     ...
// } finally {
//    releaser();
// }