import { inject, injectable } from "inversify";
import { ApiClientBase } from "../../../../skupno/src/ts/api-client/api-client";
import { Authenticator } from "../../../../skupno/src/ts/authentication/authenticator";
import { Configuration } from "../../../../skupno/src/ts/configuration/configuration";
import { ListResponse } from "../models/list-response";
import { Permission } from "../models/permission";

@injectable()
export class PermissionApiClient extends ApiClientBase {
    public name = "PermissionApiClient";
    public constructor(
        @inject("Configuration") configuration: Configuration,
        @inject("Authenticator")  authenticator: Authenticator) {
        super(configuration, authenticator);
    }

    public async getList(page?: number, limit?: number): Promise<ListResponse<Permission>> {
        let data = {
            "page": page,
            "limit": limit,
        };

        return await this._callApi('/permission/list', 'GET', data) as ListResponse<Permission>;
    }

    public async get(id: number): Promise<Permission> {

        return await this._callApi(`/permission/${id}`, 'GET') as Permission;
    }

    public async Updaate(id: number, revisionDescription:any): Promise<Permission> 
    {
        var data = {
            revisionDescription: revisionDescription
        }

        return await this._callApi(`/permission/${id}`, 'PUT', JSON.stringify(data)) as Permission;
    }

} 