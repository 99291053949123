import { StatementsSigning } from "./statements-signing";

export interface Statement
{
	id: number;
	name: string;
	nameEn: string;
	content: string;
    contentEn: string;
    instructions: string;
    instructionsEn: string;
	crmId: string;
	typ: string;
	blocksInternet: number;
	validFrom: Date;
	validTo: Date | null;
	graceDaysEmail: number;
	graceDaysBlock: number;
	created: Date;
    updated: Date;
    // is signed by the user?
    isSigned: boolean;
    isActive: boolean;
    isSubmited: boolean;
    statementsSignings: Array<StatementsSigning>;
    customFields: Array<CustomField>;
    locations: Array<number>; 
    signings: number | null; 
}

export interface CustomField {
    id: number;
    labelSl: string;
    labelEn: string;
    description: string;
    descriptionEn: string;
    dropDownOptionsSl: string;
    dropDownOptionsEn: string;
    requiredField: boolean;
    type: CustomFieldType;
    formOrder: number;
}

export enum CustomFieldType {
    DropDown = 1
}
    