import { inject, injectable } from "inversify";
import { ApiClientBase } from "../../../../skupno/src/ts/api-client/api-client";
import { Authenticator } from "../../../../skupno/src/ts/authentication/authenticator";
import { Configuration } from "../../../../skupno/src/ts/configuration/configuration";
import { GetAddedRelocateRequest } from "../models/get-added-relocate-request";
import { ListResponse } from "../models/list-response";
import { NotificationAddInitializationModel } from "../models/notification-add-initialization-model";
import { RelocateDataResponse } from "../models/relocate-data-response";
import { RelocateRequest } from "../models/relocate-requests-response";

@injectable()
export class SelitveApiClient extends ApiClientBase {
    public name = "SelitveApiClient";
    public constructor(
        @inject("Configuration") configuration: Configuration,
        @inject("Authenticator")  authenticator: Authenticator) {
        super(configuration, authenticator);
    }

    public async checkIfAvailable(): Promise<any> {
        return await this._callApi('/selitve/checkIfAvailable', 'GET', null) as any;
    } 

    public async list(blok?: string): Promise<ListResponse<any>> {
        const data: JSON = <JSON><unknown>{
            "blok": blok,
        };
        return await this._callApi('/selitve/list', 'GET', data) as ListResponse<any>;
    }   

    public async reserve(blok: string, room: string): Promise<any> {
        const data: JSON = <JSON><unknown>{
            "blok": blok,
            "room": room
        };
        return await this._callApi('/selitve/reserve', 'POST', JSON.stringify(data)) as any;
    }

    public async getLocations(): Promise<NotificationAddInitializationModel> {
        return await this._callApi('/selitve/locations', 'GET', null) as NotificationAddInitializationModel;
    }

    public async getAvailableLocations(): Promise<NotificationAddInitializationModel> {
        return await this._callApi('/selitve/availableLocations', 'GET', null) as NotificationAddInitializationModel;
    }

    public async getRelocateRequests(page?: number, limit?: number): Promise<ListResponse<RelocateRequest>> {
        const data: JSON = <JSON><unknown>{
            "page": page,
            "limit": limit
        };
        return await this._callApi('/selitve/relocate-requests', 'GET', data) as ListResponse<RelocateRequest>;
    } 

    public async getRelocateRequest(): Promise<GetAddedRelocateRequest> {
        return await this._callApi('/selitve/get-added-relocate-request', 'GET', null) as GetAddedRelocateRequest;
    } 

    public async editRelocate(data: any): Promise<any> {
        return await this._callApi('/selitve/edit-relocate', 'POST', data, true, "", null, null, (settings) => {
            settings.processData = false;
            settings.contentType = false;
        }) as any;
    }

    public async getRelocateData(): Promise<RelocateDataResponse> {
        return await this._callApi('/selitve/get-relocate-data', 'GET', null) as RelocateDataResponse;
    }
}