import { inject, injectable } from "inversify";
import { Notyf } from "notyf";
import { Authenticator } from "../../../skupno/src/ts/authentication/authenticator";
import { ErrorConsoleReporter } from "../../../skupno/src/ts/error-handling/error-console-reporter";
import { Router } from "../../../skupno/src/ts/routing/router";
import { HtmlStorageHelper } from "../../../skupno/src/ts/utils/html-storage-helper";
import { UserApiClient } from "../ts/clients/users-api-client";
import { TranslationService } from "../ts/translation-service";
import { CurrentUserAccessor } from "../ts/utilities/current-user-accessor";
import { SiteMapManager } from "../ts/utilities/sitemap-manager";
import { ChangePasswordModal } from "./change-password-modal";
import { ErrorView } from "./error-view";
import { HeaderView } from "./header-view";
import { NavigationView } from "./navigation-view";
import { ProfileSidebarView } from "./profile-sidebar-view";
import { SetEmailModal } from "./set-email-modal";

/*
 * Opisuje DOM dokument v katerem teče aplikacija.
 */
@injectable()
export class MainLayout {
    public name = "MainLayout";
	private _headElement: Element;
	private _htmlTitleElement: Element | null;
	private _errorView: ErrorView;
	private _authenticator: Authenticator;
    private _router: Router;
    private _navigationView: NavigationView;
    private _headerUserView: HeaderView;
    private _profileSidebarView: ProfileSidebarView;
    private nav: boolean = true;
    private _setEmailModal: SetEmailModal;
    private _changePasswordModal: ChangePasswordModal;
    private _userApiClient: UserApiClient;
    private _notyf: Notyf;

	get htmlTitle(): string | null {
		if (this._htmlTitleElement != null) {
			return this._htmlTitleElement.innerHTML;
		}
		return null;
	}

	set htmlTitle(value: string | null) {
		if (this._htmlTitleElement != null) {
			this._htmlTitleElement.innerHTML = value || "";
		}
    }

    set pageTitle(title: string | null) {
        if (this._headerUserView != null) {
            this._headerUserView.setTitle(title || "");
        }
    }

    headerUserViewShow():void {
        this._headerUserView.visible = true;
    }
    headerUserViewHide(): void {
        this._headerUserView.visible = false;
    }

    profileSidebarViewShow(): void {
        this._profileSidebarView.visible = true;
    }
    profileSidebarViewHide(): void {
        this._profileSidebarView.visible = false;
    }
    hideNavigation(): void {
        this.nav = false;
    }
    


	get errorView(): ErrorView {
		return this._errorView;
    }

    get navigationView(): NavigationView {
        return this._navigationView;
    }

	public constructor(@inject("Document") document: Document,
		@inject("Authenticator") authenticator: Authenticator,
        @inject("Router") router: Router,
        @inject("TranslationService") translationService: TranslationService,
        @inject("SiteMapManager") siteMapManager: SiteMapManager,
        @inject("CurrentUserAccessor") currentUserAccessor: CurrentUserAccessor,
        @inject("SetEmailModal") setEmailModal: SetEmailModal,
        @inject("ChangePasswordModal") changePasswordModal: ChangePasswordModal,
        @inject("UserApiClient") userApiClient: UserApiClient,
        @inject("Notyf") notyf: Notyf,
	) {
		this._authenticator = authenticator;
        this._router = router;
        this._errorView = new ErrorView();
        this._navigationView = new NavigationView(router, translationService, siteMapManager, currentUserAccessor);
        this._setupCallbacks();
        this._setEmailModal = setEmailModal;
        this._changePasswordModal = changePasswordModal;
        this._userApiClient = userApiClient;
        this._notyf = notyf;

		this._headElement = document.getElementsByTagName("head")[0] || null;
		if (this._headElement != null) {
			this._htmlTitleElement = this._headElement.getElementsByTagName("title")[0] || null;
		}
		else {
			this._htmlTitleElement = null;
        }
        this._headerUserView = new HeaderView(document.getElementById("header-view") as HTMLElement, currentUserAccessor, this._userApiClient, authenticator, translationService, this._setEmailModal, this._changePasswordModal, this._notyf);

        this._profileSidebarView = new ProfileSidebarView(document.getElementById("profile-sidebar-view") as HTMLElement, currentUserAccessor, translationService, authenticator);
    }

    public async initialize(): Promise<void> {
        this._errorView.load();
        await this._headerUserView.load();
        await this._profileSidebarView.load();
        if (this.nav) {
            await this._navigationView.load();
        }
    }

	/*
	 * The home button moves to the home view but also deals with error recovery
	 */
	private async _onHome(): Promise<void> {
		document.location = "#/";
	}

	/*
	 * This method is for testing only, to make the access token in storage act like it has expired
	 */
	public async expireAccessToken(): Promise<void> {

		const user = await this._authenticator._userManager.getUser();
		if (user) {

			// Add a character to the signature to make it fail validation
			user.access_token = `${user.access_token}x`;
			this._authenticator._userManager.storeUser(user);
		}
	}

	/*
	 * Force both API calls when reload is clicked
	 */
	private async _onReloadData(): Promise<void> {
		this._router.refresh();
	}

	/*
	 * Perform a logout request
	 */
	private async _onLogout(): Promise<void> {

		try {

			// Start the logout redirect
			await this._authenticator!.startLogout();

		} catch (e) {

			// On error, only output logout errors to the console, then move to the logged out view
			ErrorConsoleReporter.output(e);
			location.hash = '#loggedout';
		}
	}

	/*
	 * Handle logout notifications from other browser tabs
	 */
	private _onStorageChange(event: StorageEvent): void {

		if (HtmlStorageHelper.isMultiTabLogoutEvent(event)) {

			this._authenticator!.onExternalLogout();
			location.hash = '#loggedout';
		}
	}

	/*
	 * Force a new access token to be retrieved
	 */
	private async _onExpireToken(): Promise<void> {
		await this.expireAccessToken();
	}


	/*
	* Plumbing to ensure that the this parameter is available in async callbacks
	*/
	private _setupCallbacks(): void {
		this._onHome = this._onHome.bind(this);
		this._onReloadData = this._onReloadData.bind(this);
		this._onLogout = this._onLogout.bind(this);
		this._onStorageChange = this._onStorageChange.bind(this);
		this._onExpireToken = this._onExpireToken.bind(this);
	}
}