import { inject, injectable, interfaces } from "inversify";
import * as Mustache from "mustache";
import { Notyf } from "notyf";
import { PageBase } from "../../../../skupno/src/ts/routing/page-base";
import { Router } from "../../../../skupno/src/ts/routing/router";
import { MainLayout } from "../../views/main-layout";
import { TranslationService } from "../translation-service";
import htmlTemplate from "./page-not-found.html";

@injectable()
export class PageNotFound extends PageBase {
    public name = "AccountsPage";
    private _mainLayoutProvider: interfaces.Provider<MainLayout>;
    private _router: Router;
    private _translationService: TranslationService;

    public constructor(@inject("Provider<MainLayout>") mainLayoutProvider: interfaces.Provider<MainLayout>,
        @inject("Router") router: Router,
        @inject("TranslationService") translationService: TranslationService,
        @inject("Notyf") private notyf: Notyf
    ) {
        super();
        this._mainLayoutProvider = mainLayoutProvider;
        this._router = router;
        this._translationService = translationService;
    }

    public async execute(): Promise<boolean> {
        var translations = this._translationService.currentTranslations;
        var mainLayout = await this._mainLayoutProvider() as MainLayout;
        mainLayout.pageTitle = translations.PageNotFound;
        mainLayout.htmlTitle = translations.PageNotFound;
        mainLayout.headerUserViewShow();
        mainLayout.profileSidebarViewHide();

        var url = this._router.getVirtualUrl();

        Mustache.render(htmlTemplate, {})

        this.notyf.error("Stran " + url + " ne obstaja!");

        return false;
    }

}