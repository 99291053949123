import { inject, injectable } from "inversify";
import { ApiClientBase } from "../../../../skupno/src/ts/api-client/api-client";
import { Authenticator } from "../../../../skupno/src/ts/authentication/authenticator";
import { Configuration } from "../../../../skupno/src/ts/configuration/configuration";
import { HelpContact } from "../models/help-contacts";

@injectable()
export class HelpApiClient extends ApiClientBase {
    public name = "HelpApiClient";
    public constructor(
        @inject("Configuration") configuration: Configuration,
        @inject("Authenticator")  authenticator: Authenticator) {
        super(configuration, authenticator);
    }

    public async contacts(): Promise<Array<HelpContact>> {
        return await this._callApi('/helpApi/contact', 'GET') as Array<HelpContact>;
    }
}