import { injectable, inject } from "inversify";
import { TranslationService } from "../../ts/translation-service";
import * as Mustache from "mustache";
import { Namespace, TempusDominus } from '@eonasdan/tempus-dominus';
import { UserSearchViewModel } from "../../ts/models/sport-subscription-viewmodel";
import { ClassRoomReservationApiClient } from "../../ts/clients/classroom-api-client";
import addReservationConferanceRoomForm from "./addReservationConferanceRoomForm.html";
import addReservationDeskSlotForm from "./addReservationConferanceSlotForm.html";
import reservationWidget from "./widget-reservations.html";

import selectRremoveUser from "../widget-select-remove-user.html"
import { classroomReservationModel, classroomReservationsModel } from "../../ts/models/reservations/classroomReservationsModel";
import { Notyf } from "notyf";
import { CurrentUserAccessor } from "../../ts/utilities/current-user-accessor";
import { ModalConfirm } from "../modal-confirm";
import { ReservationUtils } from "./reservations-utils";
import { TypedEventEmitter } from "./typed-event-emitter";
import { ReservationEvents } from "./event-types";
import * as moment from "moment";



@injectable()
export class ConferenceRoomReservation {
    private _selectedUsers: Array<UserSearchViewModel>;
    private readonly _apiClient: ClassRoomReservationApiClient;
    public currentSelectedDeskTitle: string | null = null;
    public conferenceRoom: HTMLElement | null = document.getElementById('conferenceRoom');
    public reservations: any;
    public selectedItemId: string | null = 'conferenceRoom';
    public estimatedTimeFrom: TempusDominus | undefined;
    public estimatedTimeTo: TempusDominus | undefined;
    public existingReservations: Array<classroomReservationsModel>;
    private eventEmitter: TypedEventEmitter<ReservationEvents>;
    private viewModel: any;
    private datePicker: TempusDominus | undefined;

    constructor(
        @inject(TranslationService) 
        private translationService: TranslationService,
        classRoomApi: ClassRoomReservationApiClient,
        public notyf: Notyf,
        public currentUserAccessor: CurrentUserAccessor
    ) {
        this._selectedUsers = [];
        this._apiClient = classRoomApi;
        this.existingReservations = [];
        this.reservations = ReservationUtils.initializeReservations();
        this.eventEmitter = new TypedEventEmitter<ReservationEvents>();

    }


    public async load(viewModelIn: any): Promise<void> {
        try {
            this.viewModel = viewModelIn;
            await this._renderData(this.viewModel);
        } catch (e) {
            $('#main').text(this.translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private async _renderData(viewmodel: any): Promise<void> 
    {
        if (!this.viewModel.data) {
            this.viewModel.data = {
                startTime: moment().format('DD.MM.YYYY')
            };
        } else {
            this.viewModel.data.startTime = moment().format('DD.MM.YYYY');
        }       

        if(this.viewModel.hasConferancePermission) {
            this._apiClient.getReservationsByType(this.viewModel.data.type,   moment()).then((data: any) => {

                const html = Mustache.render(addReservationConferanceRoomForm, viewmodel);
                $('#reservation-info').html(html);
               
               this._setupEventListeners();
               this._intDeleteReservation();
               this.initializeTimePickers(this.viewModel.data.type);
               this._renderReservations(data);
    
        
            }).catch((error) => {
                console.error('Error loading data:', error);
                $('#main').text(this.translationService.currentTranslations["LoadingError"]);
            });       
        }
        else {
            const html = Mustache.render(addReservationConferanceRoomForm, viewmodel);
            $('#reservation-info').html(html);
        }
      
    }

    
    private initializeTimePickers(type: any): void {
        this.datePicker = new TempusDominus(document.getElementById('startTime')!, {
            display: {
                components: {
                    clock: false,
                    hours: false,
                    minutes: false,
                    seconds: false
                },
                icons: {
                    time: 'fas fa-calendar'
                }
            },
            localization: {
                format: 'dd.MM.yyyy',
            }
        });
    
        this.datePicker.subscribe(Namespace.events.change, (e: any) => {
            if (e.date) {
                // Get the selected date from datepicker
                
                // Fetch and render reservations
                const startOfDay = moment(e.date).startOf('day').toDate();
                this._apiClient.getReservationsByType(type, startOfDay)
                    .then((data) => {
                        this._renderReservations(data as any);
                    })
                    .catch(() => {
                        this.notyf.error(this.translationService.currentTranslations["LoadingError"]);
                    });
            }
        });
    }
    

    private _renderReservations(reservations: Array<classroomReservationModel>): void {
        const viewModel = {
            reservations: reservations.map((item: classroomReservationModel): any => ({
                ...item,
                estimatedTimeFrom: moment.utc(item.estimatedTimeFrom).local().format("HH:mm"),
                estimatedTimeTo: moment.utc(item.estimatedTimeTo).local().format("HH:mm"),
                imageUrlDelete: "../img/icon-delete.svg",
                imageUrlView: "../img/eye-regular.svg",
                imageUrlEdit: "../img/icon-edit.svg",
                hasPermissionToDelete: true
            }))
        };
    
        const html = Mustache.render(reservationWidget, viewModel);
        $('#reservationSlot').html(html);

        $(".removeSlot").on('click', async (e) => { 
            e.preventDefault();
            const translations = this.translationService.currentTranslations;
            const target = e.currentTarget as HTMLElement;
            const reservationId = $(target).data('id');
    
            if (!reservationId) return;
    
            try {
                const confirmed = await ModalConfirm.confirm({
                    cancelText: translations.Cancel,
                    confirmText: translations.Confirm,
                    content: translations.ConfirmDeleteContent,
                    confirmType: 'brisanje'
                });
    
                if (confirmed) {
                    const data = await this._apiClient.deleteReservation(reservationId);
                    if (data) {
                        this.notyf.success(translations.EventSuccessfullySaved);
                        this._renderData(this.viewModel);
                        if(this.viewModel.data.type == 2) {
                            this.eventEmitter.emit('reservation:deleted', data);
                        }
                    } else if (data && data.message) {
                        this.notyf.error(data.message);
                    } else {
                        this.notyf.error(translations.SaveError);
                    }
                }
            } catch (error: any) {
                console.error('Error deleting reservation:', error);
                this.notyf.error(translations.SaveError);
            }
        });

        $(".editSlot").on('click', async (e) => {
            e.preventDefault();
            await this.viewEditSlot(e);
        });

    }

     private _renderUserWidget(users: Array<UserSearchViewModel>, isReserved: boolean) {
        var translations = this.translationService.currentTranslations;


        const viewModel = {
            "imageUrlDelete": "../img/icon-delete.svg",
            "users": users,
            translations: translations,
            "title": translations.AddUser,
            isReserved: isReserved,
        } as any;
        // Construct a template
        const htmlTemplate = selectRremoveUser;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#users-select-widget').html(html);

        $(".delete-user-btn").each((_, element) => {
            element.addEventListener("click", (ev: Event) => this._onDeleteUser(ev));
        });
    }
    
    private _setupEventListeners() {
        $("#cancelConferanceRommRerervation").off('click').on('click', () => {
            this.eventEmitter.emit('reservation:cleared', void 0);  
        });
    
        $("#conferanceRoomReservationForm").off('submit').on('submit', async (e) => {
            e.preventDefault();
            await this._saveReservation();
        });
    
        $("#addNewSlot").on('click', () => {
            this._selectedUsers = [];
            this.addNewSlot();
            this._renderUserSelectField();
        });

       
    }

    private viewEditSlot(e:any): void {
        const target = e.currentTarget as HTMLElement;
        const reservationId = $(target).data('id');

        $(target).find(".view").attr('src', '../img/eye-regular-active.svg');
        $(target).find("span").css('color', '#FF6F00');

        $(target).find(".edit").attr('src', '../img/icon-edit-active.svg');
        if (!reservationId) return;

        this._apiClient.getById(reservationId).then((data) => {
            console.log(data)
            this.addNewSlot(data);
        })
    }

    public async addNewSlot(data?: any): Promise<void> {

        this._selectedUsers = data != null ? data?.classRoomUsers : [];
        let viewModel: any = {
            translations: this.translationService.currentTranslations,
            selectedItemId: this.selectedItemId,
            deskTitle: this.currentSelectedDeskTitle,
            showForm: this.selectedItemId != null,
            isReserved: false,
            emptyFormDescripton: "Prosim izberite termin",
            hasFormRole: true,
            isConferenceRoom: true,
            data: data,
            isLoading: false,
            hasClassroomPermission: true,
            hasConferancePermission: true
    
        };

        const html = Mustache.render(addReservationDeskSlotForm, viewModel);
        $('#addReservation').html(html);
        this._renderUserSelectField();


        this._renderUserWidget(this._selectedUsers, false);

        const startElement = document.getElementById('EstimatedTimeFrom');
        const endElement = document.getElementById('EstimatedTimeTo');
        const pickers = ReservationUtils.initializeTimePickers(startElement, endElement);
        this.estimatedTimeFrom = pickers.estimatedTimeFrom;
        this.estimatedTimeTo = pickers.estimatedTimeTo;
        

        this._setupEventListeners();
        this._renderUserSelectField();

      
        
    }
  
    public async _initUserSearchField(): Promise<void> {
        require('select2');
        const ajaxOptions = await this._apiClient.userSearchOptions((settings) => {
            if (settings.url) {
                settings.url += "&search={query}";
            }
        });
        $(".user-search-field").select2({
            ajax: {
                url: ajaxOptions.url,
                method: ajaxOptions.method,
                headers: ajaxOptions.headers,
                data: (params: any) => {
                    return { search: params.term, page: params.page || 1 };
                },
                processResults: (data: any) => {
                    return {
                        results: data.map((item: any) => ({
                            id: item.id,
                            text: `${item.firstName} ${item.lastName} / ${item.crmId} `,
                            firstName: item.firstName,
                            lastName: item.lastName,
                            crmId: item.crmId,
                        }))
                    };
                },
                cache: true
            },
            placeholder: "Janez Marinko / 999995665",
            minimumInputLength: 1,
            allowClear: false
        });
    }
    
    private _intDeleteReservation(): void {
        const translations = this.translationService.currentTranslations;
        $("#deleteConferanceReservation").off('click').on('click', async (e) => {
            e.preventDefault();
            const target = e.currentTarget as HTMLElement;
            const reservationId = $(target).data('id');
    
            if (!reservationId) return;
    
            try {
                const confirmed = await ModalConfirm.confirm({
                    cancelText: translations.Cancel,
                    confirmText: translations.Confirm,
                    content: translations.ConfirmDeleteContent,
                    confirmType: 'brisanje'
                });
    
                if (confirmed) {
                    const data = await this._apiClient.deleteReservation(reservationId);
                    if (data) {
                        this.notyf.success(translations.EventSuccessfullySaved);
                        this._selectedUsers = [];
                        this.eventEmitter.emit('reservation:deleted', data);

                    } else if (data && data.message) {
                        this.notyf.error(data.message);
                    } else {
                        this.notyf.error(translations.SaveError);
                    }
                }
            } catch (error: any) {
                console.error('Error deleting reservation:', error);
                if (error.statusCode === 400) {
                    $('#main').text(translations[error.message]);
                } else {
                    this.notyf.error(translations.SaveError);
                }
            }
        });
    }

    private _renderUserSelectField(): void {
        this._initUserSearchField();
        this._onUserSearchSelected();
    }

    public _onUserSearchSelected(): void {
        $('.user-search-field').on('select2:select', (e) => {
            const data = (e.params.data as unknown) as UserSearchViewModel;
            if (!this._selectedUsers.some(user => user.id === data.id)) {
                const userViewModel: UserSearchViewModel = {
                    id: data.id,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    crmId: data.crmId,
                };
                this._selectedUsers.push(userViewModel);
                this._renderUserWidget(this._selectedUsers, false)
                this._renderUserSelectField();
            }
        });
    }

    private _onDeleteUser(ev: Event): void {
        const target = ev.currentTarget as HTMLElement;
        const idString = target.getAttribute("data-id");
        if (!idString) return;
        const id = parseInt(idString);
        const index = this._selectedUsers.findIndex(el => el.id === id);
        if (index !== -1) {
            this._selectedUsers.splice(index, 1);
            this._renderUserSelectField();
            this._renderUserWidget(this._selectedUsers, false)
        }
    }

    private async _saveReservation() {

        const dataId = $('#conferanceRoomReservationForm').data('id');
        const { currentTranslations: translations } = this.translationService;
        
        // Get the base date from datePicker
        const selectedDate = moment(this.datePicker?.dates.picked[0]);
        
        // Get times from time pickers
        const timeFrom = moment(this.estimatedTimeFrom?.dates.picked[0]);
        const timeTo = moment(this.estimatedTimeTo?.dates.picked[0]);
        
        // Combine date and times
        const dateTimeFrom = selectedDate
            .hours(timeFrom.hours())
            .minutes(timeFrom.minutes())
            .seconds(0)
            .milliseconds(0);
            
        const dateTimeTo = selectedDate
            .clone()
            .hours(timeTo.hours())
            .minutes(timeTo.minutes())
            .seconds(0)
            .milliseconds(0);

        const formData = {
            id: null,
            ClassroomReservationTypeId: this.viewModel.data.type == 1 ? this.selectedItemId : "entireRoom",
            Type: this.viewModel.data.type,
            estimatedTimeFrom: dateTimeFrom.toISOString(),
            estimatedTimeTo: dateTimeTo.toISOString(),
            description: $('#description').val(),
            classRoomUsers: this._selectedUsers.map(x => x.id),
            isPrivate: $('#isPrivate').prop('checked'),
            createTime: selectedDate.format("YYYY-MM-DD")
        };

        const userCount = formData.classRoomUsers?.length ?? 0;
        let errorMessage: string | null = null;


        if(formData.estimatedTimeFrom == null || formData.estimatedTimeTo == null)  
        {
            errorMessage = "Prosim izberite čas rezervacije";

        }
    
        if (formData.isPrivate) {
            if (userCount !== 1) {
                errorMessage = "Prosim izberite samo enega uporabnika";
            }
        }
        else {         
            if (userCount !== 3) 
            {
                errorMessage = userCount < 3 ? "Prosim izberite vsaj 3 uporabnike" : "Prosim izberite samo 3 uporabnike";
            }
        }
        

        if (errorMessage) {
            this.notyf.error(errorMessage);
            return;
        }

        try {
            if(dataId) {
                formData.id = dataId;
                const response = await this._apiClient.updateReservation(JSON.stringify(formData), dataId);
                if (response) {
                    this.notyf.success(translations.EventSuccessfullySaved);
                    this.eventEmitter.emit('reservation:saved', void 0);
                } else if (response?.message) {
                    this.notyf.error(response.message);
                } else {
                    this.notyf.error(translations.SaveError);
                }
            }
            else {
                const response = await this._apiClient.saveReservation(JSON.stringify(formData));
                if (response) {
                    this.notyf.success(translations.EventSuccessfullySaved);
                    this.eventEmitter.emit('reservation:saved', response);
                } else if (response?.message) {
                    this.notyf.error(response.message);
                } else {
                    this.notyf.error(translations.SaveError);
                }
            }

          
        } catch (error: any) {
            console.error('Error submitting reservation:', error);
            this.notyf.error(error.statusCode === 400
                ? translations[error.message]
                : translations.SaveError);
        }
    }

       
    public onReservationSaved(handler: () => void): void {
        this.eventEmitter.on('reservation:saved', handler);
    }
   
    public onReservationDelete(handler: () => void): void {
        this.eventEmitter.on('reservation:deleted', handler);
    }
   
    public onReservationCleared(handler: () => void): void {
        this.eventEmitter.on('reservation:cleared', handler);
    }
}
