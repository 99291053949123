export enum Action {
    ViewAction = 1,
    ChangeAction = 2,
   
}

export enum Domain {
    Users = 1
}

export function parseEnum(enumType: any) {
    return Object.entries(enumType)
        .filter(([key]) => isNaN(Number(key))) // Filter out numeric keys to avoid reverse mappings
        .map(([key, value]) => ({
            label: key,   // Enum name as the label
            value: value  // Enum value as the value
        }));
}