import * as Mustache from "mustache";
import template from "./revision.view.html";
import { RevisionApiClient } from "../../ts/clients/revision-api-client";
import { TranslationService } from "../../ts/translation-service";
import { inject, injectable } from "inversify";
import * as Diff from "diff";

import 'diff2html/bundles/css/diff2html.min.css';
import { Diff2HtmlUI } from 'diff2html/lib/ui/js/diff2html-ui-slim.js';

@injectable()
export class RevisionView {
    private _apiClient: RevisionApiClient;
    private _translationService: TranslationService;

    public constructor(
        @inject(RevisionApiClient) apiClient: RevisionApiClient,
        @inject(TranslationService) translationService: TranslationService,
   
    ) {
        this._apiClient = apiClient;
        this._translationService = translationService;
    }

    public async load(id: any): Promise<void> {
        try {
            var translations: any = await this._translationService.currentTranslations

            var data =  await this._apiClient.get(id, translations);
            this._renderData(data);
        } catch (e) {
            $('#main').text(this._translationService.currentTranslations["LoadingError"]);
            console.error("Error loading data:", e);
        }
    }

    private _renderData(item: any): void {
        let domainLink = null;
        switch(item.domain)
        {
            case "Uporabniki": // Use the enum value
            domainLink = `#/management/uporabniki/pregled/${item.domainDataId}`;
            break;        
        }

        const viewModel = {
            revision: item,
            domainLink,
            translations: this._translationService.currentTranslations,
            imageUrlLogout: "../img/icon-logout.svg",
            showChangeDetails: item.changeDataOld != null && item.changeDataOld.length > 0 ||  item.changeDataNew != null && item.changeDataNew.length > 0

        
            // ...other properties as required
        };

        const html = Mustache.render(template, viewModel);
        $('#main').html(html);

        this._initDiff(item.changeDataOld, item.changeDataNew);

    }

 

    private _initDiff(changeDataOld: string, changeDataNew: string): void {
        const diffContainer = document.getElementById('data');

        if (diffContainer) {
            const oldDataHtml = this._formatChangeData(changeDataOld);
            const newDataHtml = this._formatChangeData(changeDataNew);


            const diffString = Diff.createPatch("User roles", oldDataHtml, newDataHtml);

            // Initialize Diff2HtmlUI
            const html = new Diff2HtmlUI(diffContainer, diffString, {
                matching: 'none' , 
                drawFileList: false,
                fileListToggle: false,
                fileListStartVisible: false,
                fileContentToggle: false,
                outputFormat: 'side-by-side',
                synchronisedScroll: true,
                highlight: true,
                renderNothingWhenEmpty: false,   // specify matching strategy
            });
    
            // Render the diff
            html.draw();
            html.highlightCode(); 
        }
    }

    private _formatChangeData(changeData: string): string {
        if (!changeData) return '';

        try {
            const parsedData = JSON.parse(changeData);
            return JSON.stringify(parsedData, null, 4); // Pretty-print JSON
        } catch (error) {
            console.error("Invalid JSON data:", error);
            return `<span style="color:red;">Invalid JSON</span>`;
        }
    }

}
