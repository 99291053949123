import { injectable, inject } from "inversify";
import { TranslationService } from "../../ts/translation-service";
import * as Mustache from "mustache";
import template from "./reservations.view.html";
import { TempusDominus } from '@eonasdan/tempus-dominus';
import { UserSearchViewModel } from "../../ts/models/sport-subscription-viewmodel";
import { ClassRoomReservationApiClient } from "../../ts/clients/classroom-api-client";
import addReservationForm from "./addReservationDeskForm.html";

import selectRremoveUser from "../widget-select-remove-user.html"
import { classroomReservationsModel } from "../../ts/models/reservations/classroomReservationsModel";
import { Notyf } from "notyf";
import { CurrentUserAccessor } from "../../ts/utilities/current-user-accessor";
import { ModalConfirm } from "../modal-confirm";
import { CurrentUserInfo } from "../../ts/models/current-user-info";
import { ReservationUtils } from "./reservations-utils";
import { ConferenceRoomReservation } from "./conferanceRommReservation";
enum ClassroomPermissions {
    ClassroomReservationView = 2147483648,
    ClassroomDeskReservation = 4294967296,
    ClassroomConferenceReservation = 8589934592
}

@injectable()
export class ReservationsView {
    private _selectedUsers: Array<UserSearchViewModel>;
    private readonly _apiClient: ClassRoomReservationApiClient;
    private currentlySelectedDesk: string | null = null;
    public currentSelectedDeskTitle: string | null = null;
    public conferenceRoom: HTMLElement | null = document.getElementById('conferenceRoom');
    public reservations: any;
    public selectedItemId: string | null = null;
    public estimatedTimeFrom: TempusDominus | undefined;
    public estimatedTimeTo: TempusDominus | undefined;
    public existingReservations: Array<classroomReservationsModel>;
    private user: CurrentUserInfo | null = null;

    private confreaceRoomReservation: ConferenceRoomReservation;

    constructor(
        @inject(TranslationService) 
        private translationService: TranslationService,
        classRoomApi: ClassRoomReservationApiClient,
        public notyf: Notyf,
        public currentUserAccessor: CurrentUserAccessor
    ) {
        this._selectedUsers = [];
        this._apiClient = classRoomApi;
        this.existingReservations = [];
        this.reservations = ReservationUtils.initializeReservations();
        this.confreaceRoomReservation =  new ConferenceRoomReservation(this.translationService, this._apiClient, this.notyf, this.currentUserAccessor);
        this.confreaceRoomReservation.onReservationSaved(() => {
            this.clearSelection();
            this.load();
        });
        this.confreaceRoomReservation.onReservationDelete(() => {
            this.clearSelection();
            this.load();
        });
        this.confreaceRoomReservation.onReservationCleared(() => {
            this.clearSelection();
            this.load();
        });
    }

    private initializeTimePickers(): void {
        const startElement = document.getElementById('EstimatedTimeFrom');
        const endElement = document.getElementById('EstimatedTimeTo');
        const pickers = ReservationUtils.initializeTimePickers(startElement, endElement);
        this.estimatedTimeFrom = pickers.estimatedTimeFrom;
        this.estimatedTimeTo = pickers.estimatedTimeTo;
    }

    public async load(): Promise<void> {
        try {
            this.user = await this.currentUserAccessor.getUser();
            await this._renderData();

            this._renderUserSelectField();
            this.initRoom();
        } catch (e) {
            $('#main').text(this.translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private async _renderData(): Promise<void> {
        const translations = this.translationService.currentTranslations;
         const hasPermission = this.hasPermission(ClassroomPermissions.ClassroomReservationView) ||  this.hasPermission(ClassroomPermissions.ClassroomConferenceReservation) || this.hasPermission(ClassroomPermissions.ClassroomDeskReservation);
        const viewModel = {
            translations: translations,
            selectedItemId: this.selectedItemId,
            deskTitle: this.currentSelectedDeskTitle,
            reservationDetaisEnabled: hasPermission,
            hasConferancePermission: this.hasPermission(ClassroomPermissions.ClassroomConferenceReservation)
        };
        const html = Mustache.render(template, viewModel);
        $('#main').html(html);
        this.updateVisibility();
        this._renderUserSelectField();
        this.initRoom();
    }

   

    private async _rerenderReservation(id?: number) {
        const translations = this.translationService.currentTranslations;

        const hasPermission =  this.selectedItemId === "conferenceRoom"  || this.selectedItemId === 'entireRoom'  ? this.hasPermission( ClassroomPermissions.ClassroomConferenceReservation)  : this.hasPermission(ClassroomPermissions.ClassroomDeskReservation);
        var hasFormRole = false;
        var emptyFormDescripton = '';

        const deskReservePermission = this.hasPermission( ClassroomPermissions.ClassroomDeskReservation);
        const conferenceRoomPermission = this.hasPermission( ClassroomPermissions.ClassroomConferenceReservation);


        if ( (deskReservePermission || conferenceRoomPermission) && hasPermission) {
            hasFormRole = true;
        }


        if(!hasFormRole)
        {

            if(this.selectedItemId === "conferenceRoom" &&  (!deskReservePermission && !conferenceRoomPermission))
            {
                emptyFormDescripton = translations.ViewPermissionConferenceDesciption;
            }
            else if(this.selectedItemId !== "conferenceRoom" && (!deskReservePermission && !conferenceRoomPermission)) {
                emptyFormDescripton = translations.ViewPermissionDeskDesciption;

            } else if(this.selectedItemId !== "conferenceRoom" && !deskReservePermission) {
                emptyFormDescripton = translations.AdministrationPermissionDeskDesciption;

            }else if(this.selectedItemId === "conferenceRoom" && !conferenceRoomPermission) {
                emptyFormDescripton = translations.RecptionPermissionConferenceDesciption;

            }
        }


        const lines = emptyFormDescripton.split('\n');
        const firstLine = lines[0];
        const remainingLines = lines.slice(1).join('\n').replace(/-/g, '<br>-');
        const formattedDescription = firstLine + '\n' + remainingLines;


        let viewModel: any = {
            translations: translations,
            selectedItemId: this.selectedItemId,
            deskTitle: this.currentSelectedDeskTitle,
            showForm: this.selectedItemId != null,
            isReserved: false,
            emptyFormDescripton: formattedDescription,
            hasFormRole: hasFormRole,
            isConferenceRoom: this.selectedItemId === 'conferenceRoom' || this.selectedItemId === 'entireRoom',
            isLoading: true,
            hasClassroomPermission: deskReservePermission,
            hasConferancePermission: conferenceRoomPermission
    
        };


    
    
        // Render initial loading state
        let html = Mustache.render(addReservationForm, viewModel);
        $('#reservation-info').html(html);
    
        if (id) {
            try {
                const data = await this._apiClient.getById(id);
                this._selectedUsers = data.classRoomUsers;
                data.estimatedTimeFrom = data.estimatedTimeFrom ?? '/';
                data.estimatedTimeTo = data.estimatedTimeTo ?? '/';
                viewModel = {
                    ...viewModel,
                    isReserved: true,
                    data: data,
                    users: data.classRoomUsers,
                    isLoading: false
                };
            } catch (error) {
                console.error("Error fetching reservation data:", error);
                viewModel.isLoading = false;
            }
        } else {
            this._selectedUsers = [];
            viewModel.isLoading = false;
        }
    

        if(this.selectedItemId === "conferenceRoom")
            {viewModel.data = {};
            viewModel.data.type = 1;
            viewModel.data.hasConferancePermission
            = conferenceRoomPermission
            this.confreaceRoomReservation.load(viewModel);
        } else if(this.selectedItemId === "entireRoom") {
            viewModel.data = {};
            viewModel.data.type = 2;
            viewModel.data.hasConferancePermission = conferenceRoomPermission
            this.confreaceRoomReservation.load(viewModel);

        } 
        else {
            html = Mustache.render(addReservationForm, viewModel);
            $('#reservation-info').html(html);

        }


    
        this._renderUserWidget(this._selectedUsers, viewModel.isReserved );
        this._renderUserSelectField();
        this.initializeTimePickers();
    
        if (id) {
            this._intDeleteReservation();
        }
        this._setupEventListeners();
    }

     private _renderUserWidget(users: Array<UserSearchViewModel>, isReserved: boolean) {
        var translations = this.translationService.currentTranslations;


        const viewModel = {
            "imageUrlDelete": "../img/icon-delete.svg",
            "users": users,
            translations: translations,
            "title": translations.AddUser,
            isReserved: isReserved,
        } as any;
        // Construct a template
        const htmlTemplate = selectRremoveUser;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#users-select-widget').html(html);

        $(".delete-user-btn").each((_, element) => {
            element.addEventListener("click", (ev: Event) => this._onDeleteUser(ev));
        });
    }
    
    private _setupEventListeners() {
        $("#cancelRerervation").off('click').on('click', () => {
            this.clearSelection();
            this._rerenderReservation();
        });
    
        $("#reservationForm").off('submit').on('submit', async (e) => {
            e.preventDefault();
            await this._saveReservation();
        });
    }
  
    public async _initUserSearchField(): Promise<void> {
        require('select2');
        const ajaxOptions = await this._apiClient.userSearchOptions((settings) => {
            if (settings.url) {
                settings.url += "&search={query}";
            }
        });
        $(".user-search-field").select2({
            ajax: {
                url: ajaxOptions.url,
                method: ajaxOptions.method,
                headers: ajaxOptions.headers,
                data: (params: any) => {
                    return { search: params.term, page: params.page || 1 };
                },
                processResults: (data: any) => {
                    return {
                        results: data.map((item: any) => ({
                            id: item.id,
                            text: `${item.firstName} ${item.lastName} / ${item.crmId} `,
                            firstName: item.firstName,
                            lastName: item.lastName,
                            crmId: item.crmId,
                        }))
                    };
                },
                cache: true
            },
            placeholder: "Janez Marinko / 999995665",
            minimumInputLength: 1,
            allowClear: false
        });
    }




    public async initRoom() {
        if (this.user) {
            this.existingReservations = await this._apiClient.get();
            
            const updateDeskColors = () => {
                // $("#conferenceRoom, .desk").each((_, element) => {
                //     const elementId = element.id;
                //     const isReserved = this.existingReservations.some(r => r.classroomReservationTypeId === elementId);
                //     const isSelected = elementId === this.currentlySelectedDesk;

                //     // Set background colors
                //     if (isSelected) {
                //         $(element).css('background-color', isReserved ? 'rgba(255, 111, 0, 1)' : 'rgba(255, 111, 0, 1)');
                //     } else if (isReserved) {
                //         $(element).css('background-color', 'rgba(255, 111, 0, 0.5)');
                //     } else {
                //         $(element).css('background-color', '');
                //     }
             
                // });
                $("#conferenceRoom, #entireRoom").each((_, element) => {
                    const elementId = element.id;
                    const isSelected = elementId === this.currentlySelectedDesk;
        
                    if (isSelected) {
                        $(element).css('background-color', 'rgba(255, 111, 0, 1)');
                    } else {
                        $(element).css('background-color', '');
                    }
                });
                $(".desk").each((_, element) => {
                    const elementId = element.id;
                    const isReserved = this.existingReservations.some(r => r.classroomReservationTypeId === elementId);
                    const isSelected = elementId === this.currentlySelectedDesk;
        
                    if (isReserved) {
                        $(element).css('background-color', isSelected ? 'rgba(255, 111, 0, 1)' : 'rgba(241, 91, 78, 0.6)');
                    } else if (isSelected) {
                        $(element).css('background-color', 'rgba(255, 111, 0, 1)');
                    } else {
                        $(element).css('background-color', '');
                    }
                });
            };
            
            updateDeskColors();
            
            $("#conferenceRoom, #entireRoom, .desk").off('click').on('click', async (e) => {
                const targetId = e.currentTarget.id;
            
                this.selectedItemId = targetId;
                this.currentlySelectedDesk = targetId;
                
                if (targetId === "conferenceRoom") {
                    this.currentSelectedDeskTitle = this.translationService.currentTranslations.ConferenceRoom;
                } else if(targetId === "entireRoom") {
                    this.currentSelectedDeskTitle = this.translationService.currentTranslations.EntireRoom;
                }
                
                else {
                    this.currentSelectedDeskTitle = `${this.translationService.currentTranslations.Desk} ${e.currentTarget.textContent}`;
                }
        
                updateDeskColors();
                this.updateVisibility();
        
                const item = this.existingReservations.find(x => x.classroomReservationTypeId === targetId);
                if (item) {
                    await this._rerenderReservation( item.id);
                } else {
                    await this._rerenderReservation();
                }
            });
        }
    }
    
    private _intDeleteReservation(): void {
        const translations = this.translationService.currentTranslations;
        $("#deleteReservation").off('click').on('click', async (e) => {
            e.preventDefault();
            const target = e.currentTarget as HTMLElement;
            const reservationId = $(target).data('id');
    
            if (!reservationId) return;
    
            try {
                const confirmed = await ModalConfirm.confirm({
                    cancelText: translations.Cancel,
                    confirmText: translations.Confirm,
                    content: translations.ConfirmDeleteContent,
                    confirmType: 'brisanje'
                });
    
                if (confirmed) {
                    const data = await this._apiClient.deleteReservation(reservationId);
                    if (data) {
                        this.notyf.success(translations.EventSuccessfullySaved);
                        this.clearSelection();
                        this.load();
                    } else if (data && data.message) {
                        this.notyf.error(data.message);
                    } else {
                        this.notyf.error(translations.SaveError);
                    }
                }
            } catch (error: any) {
                console.error('Error deleting reservation:', error);
                if (error.statusCode === 400) {
                    $('#main').text(translations[error.message]);
                } else {
                    this.notyf.error(translations.SaveError);
                }
            }
        });
    }
    private updateVisibility(): void {
        ReservationUtils.updateVisibility(this.selectedItemId);
    }

    private hasPermission(permissionToCheck: any): boolean {
        return ReservationUtils.hasPermission(BigInt(this.user!.permissions), BigInt(permissionToCheck));
    }

  
    
    private clearSelection(): void {
        const result = ReservationUtils.clearSelection(
            this.currentlySelectedDesk,
            this.existingReservations,
            {
                updateVisibility: () => this.updateVisibility(),
                renderUserSelectField: () => this._renderUserSelectField(),
                initializeTimePickers: () => this.initializeTimePickers()
            }
        );

        this.currentlySelectedDesk = result.currentlySelectedDesk;
        this.selectedItemId = result.selectedItemId;
        this.currentSelectedDeskTitle = result.currentSelectedDeskTitle;
        this.reservations = result.reservations;
    }

    private _renderUserSelectField(): void {
        this._initUserSearchField();
        this._onUserSearchSelected();
    }

    public _onUserSearchSelected(): void {
        $('.user-search-field').on('select2:select', (e) => {
            const data = (e.params.data as unknown) as UserSearchViewModel;
            if (!this._selectedUsers.some(user => user.id === data.id)) {
                const userViewModel: UserSearchViewModel = {
                    id: data.id,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    crmId: data.crmId,
                };
                this._selectedUsers.push(userViewModel);
                this._renderUserWidget(this._selectedUsers, false)
                this._renderUserSelectField();
            }
        });
    }

    private _onDeleteUser(ev: Event): void {
        const target = ev.currentTarget as HTMLElement;
        const idString = target.getAttribute("data-id");
        if (!idString) return;
        const id = parseInt(idString);
        const index = this._selectedUsers.findIndex(el => el.id === id);
        if (index !== -1) {
            this._selectedUsers.splice(index, 1);
            this._renderUserSelectField();
            this._renderUserWidget(this._selectedUsers, false)
        }
    }

    private async _saveReservation() {
        const { currentTranslations: translations } = this.translationService;
        const dateTimeFrom = this.estimatedTimeFrom?.dates.picked[0];
        const dateTimeTo = this.estimatedTimeTo?.dates.picked[0];


        const formData = {
            ClassroomReservationTypeId: this.selectedItemId,
            Type: 0,
            estimatedTimeFrom: dateTimeFrom?.toISOString(),
            estimatedTimeTo: dateTimeTo?.toISOString(),
            description: $('#description').val(),
            classRoomUsers: this._selectedUsers.map(x => x.id),
            isPrivate: $('#isPrivate').prop('checked')
        };

        const userCount = formData.classRoomUsers?.length ?? 0;
        let errorMessage: string | null = null;

        if (userCount === 0) {
                errorMessage = "Prosim izberite vsaj 1 uporabnika";
        } else if (userCount > 1) {
                errorMessage = "Prosim izberite samo enega uporabnika";
        }
        
        if (errorMessage) {
            this.notyf.error(errorMessage);
            return;
        }

        try {
            const response = await this._apiClient.saveReservation(JSON.stringify(formData));

            if (response) {
                this.notyf.success(translations.EventSuccessfullySaved);
                this.clearSelection();
                this.load();
            } else if (response?.message) {
                this.notyf.error(response.message);
            } else {
                this.notyf.error(translations.SaveError);
            }
        } catch (error: any) {
            console.error('Error submitting reservation:', error);
            this.notyf.error(error.statusCode === 400
                ? translations[error.message]
                : translations.SaveError);
        }
    }
}
