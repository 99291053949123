import { inject, injectable, interfaces } from "inversify";
import { PageBase } from "../../../../skupno/src/ts/routing/page-base";
import { MainLayout } from "../../views/main-layout";
import { Router } from "../../../../skupno/src/ts/routing/router";
import { InternetMojDostopView } from "../../views/internet-moj-dostop-view";
import { HelpClient } from "../clients/help-client";
import { TranslationService } from "../translation-service";
import { Notyf } from "notyf";
import { HelpApiClient } from "../clients/help-api-client";
import { UserApiClient } from "../clients/users-api-client";
import { CurrentUserAccessor } from "../utilities/current-user-accessor";
import { ErrorLoadingView } from "../../views/error-loading-view";

@injectable()
export class InternetMojDostopPage extends PageBase {
    public name = "InternetMojDostopPage";
    private _mainLayoutProvider: interfaces.Provider<MainLayout>;
    private _router: Router;
    private _internetMojDostopView: InternetMojDostopView;
    private _errorLoadingView: ErrorLoadingView;

    public constructor(@inject("Provider<MainLayout>") mainLayoutProvider: interfaces.Provider<MainLayout>,
        @inject("Router") router: Router,
        @inject("HelpClient") helpClient: HelpClient,
        @inject("HelpApiClient") helpApiClient: HelpApiClient,
        @inject("TranslationService") private translationService: TranslationService,
        @inject("UserApiClient") userApiClient: UserApiClient,
        @inject("CurrentUserAccessor") currentUserAccessor: CurrentUserAccessor,
        @inject("Notyf") private notyf: Notyf        
    ) {
        super();
        this._mainLayoutProvider = mainLayoutProvider;
        this._router = router;
        this._errorLoadingView = new ErrorLoadingView(translationService);
        this._internetMojDostopView = new InternetMojDostopView(helpClient, helpApiClient, translationService, userApiClient, currentUserAccessor, notyf);
    }

    public async execute(): Promise<boolean> {
        var url = this._router.getVirtualUrl();
        if (!url.pathname.startsWith("/internet")) {
            return true;
        }

        var mainLayout = await this._mainLayoutProvider() as MainLayout; try {
            var translations = this.translationService.currentTranslations;
            mainLayout.pageTitle = translations.Internet + " - " + "<span class=\"purple\">" + translations.MyAccess + "</span>";
            mainLayout.htmlTitle = translations.Internet + " - " + translations.MyAccess;
            mainLayout.headerUserViewShow();
            mainLayout.profileSidebarViewHide();
            await this._internetMojDostopView.load();
        } catch (e) {            
            // Report failures
            console.log(e);
            this.notyf.error(this.translationService.currentTranslations.LoadingError);
            this._errorLoadingView.load();
        }

        return false;
    }
}