import { injectable } from "inversify";
import * as moment from "moment";
import * as Mustache from "mustache";
import template from "./selitve-view.html";
import { TranslationService } from "../ts/translation-service";
import { Notyf } from "notyf";
import { FullscreenLoader } from "./fullscreen-loader";
import { TableWidget, TableWidgetTableMap } from "../../../skupno/src/ts/widgets/table-widget";
import { SelitveApiClient } from "../ts/clients/selitve-api-client";
import { GetAddedRelocateRequest } from "../ts/models/get-added-relocate-request";

@injectable()
export class SelitveView {
    private _apiClient: SelitveApiClient;
    private _translationService: TranslationService;
    private _locations: any | null;
    private _relocationEnabled: boolean;
    private _relocateRequest: GetAddedRelocateRequest | null;

    public constructor(apiClient: SelitveApiClient, translationService: TranslationService, private notyf: Notyf, private loader: FullscreenLoader) {
        this._apiClient = apiClient; 
        this._translationService = translationService;
        this._relocationEnabled = false;
        this._relocateRequest = null;
    }

    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {

        try {
            console.log(this.notyf);
            this._locations = await this._apiClient.getAvailableLocations();
            var available = await this._apiClient.checkIfAvailable();
            if (available && available.success) {
                this._relocationEnabled = true
            }
            this._relocateRequest = await this._apiClient.getRelocateRequest();
            var translations = this._translationService.currentTranslations;

            // Render new content
            this._renderData();

            var rootElement = $("#main").find(".relocation-table-placeholder");
            var tableWidget = new TableWidget({
                tableMap: this.dataTableModel(translations),
                rootElement: rootElement,
                getData: async () => {
                    var loc = $("#location-select").val() as string;
                    if (loc) {
                        return await this._apiClient.list(loc);
                    }
                    else {
                        return await this._apiClient.list("-1");
                    }
                }
            });
            tableWidget.refresh(1, this._translationService.currentTranslations);
            this.initButton();

            $("#location-select").on("change", () => {
                tableWidget.refresh(1, this._translationService.currentTranslations);
                var loc = $("#location-select").val() as string;
                if (loc) {
                    $(".relocation-table-placeholder").show();
                }
                else {
                    $(".relocation-table-placeholder").hide();
                }
            });
            //this.loader.hide();            

        } catch (e) {

            // Clear previous content on error
            $('#main').text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }

        
    }

    public async initButton(): Promise<void> {
        $(document).on('click', '.btn-reserve', async (ev) => {
        //$(".btn-reserve").on('click', async (e) => {
            ev.preventDefault();
            this.loader.show();
            var blok: any = ev.target.getAttribute("data-block");
            var room: any = ev.target.getAttribute("data-room");
            if (blok && room) {
                
                var result = await this._apiClient.reserve(blok, room);
                if (result && result.success) {
                    $("#reservation-error").hide();
                    $("#reservation-success").show();
                    $("#reservationIsSuccess").val("1");

                }
                else if (result && result.message) {
                    $("#error-message").html(result.message);
                    $("#reservation-success").hide();
                    $("#reservation-error").show();
                    $("#reservationIsSuccess").val("0");
                }
                else {
                    $("#error-message").html(this._translationService.currentTranslations["Error"]);
                    $("#reservation-success").hide();
                    $("#reservation-error").show();
                    $("#reservationIsSuccess").val("0");
                }
            }
            else {
                $("#reservation-success").hide();
                $("#reservation-error").show();
                $("#reservationIsSuccess").val("0");
            }
            this.loader.hide();
            $("#reservation-modal").modal({ backdrop: 'static', keyboard: false });
            $("#reservation-modal").modal("show");
        });

        $(document).on('click', '.close-modal', async () => {
            if ($("#reservationIsSuccess").val() == "1") {
                window.location.reload();
            }
        });
    }

    private _renderData(): void {

        var relocateRequestExists = this._relocateRequest != undefined;

        var lang = document.documentElement.lang;
        var instructions = this._relocateRequest?.instuctions;
        if (lang == "en") {
            instructions = this._relocateRequest?.instuctionsEn;
        }

        // Build a view model from the API data
        const viewModel = {
            "imageUrlLogout": "../img/icon-logout.svg",
            "imageUrlDocument": "../img/icon-document.svg",
            "imageCircleAccept": "../img/icon-circle-accept.svg",
            "imageCircleDecline": "../img/icon-circle-decline.svg",
            "formators": this.getFormators(),
            "imageUrlVerticalDots": "../img/icon-vertical-dots.svg",
            "allLocations": this._locations.allLocations,
            "relocationEnabled": this._relocationEnabled,
            "relocateRequestExists": relocateRequestExists,
            "relocateRequestBlock": relocateRequestExists ? this._relocateRequest?.blok : "",
            "relocateRequestRoom": relocateRequestExists ? this._relocateRequest?.room : "",
            "relocateRequestInstuctions": instructions,
            translations: this._translationService.currentTranslations
        } as any;
        // Construct a template
        const htmlTemplate = template;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#main').html(html);
    }   

    private dataTableModel(translations: any): TableWidgetTableMap {
        const tableMap = {
            title: translations["AvailableRooms"] ?? "Sobe na voljo",
            classInsert: "relocation-table-placeholder",
            noDataContent: translations.NoRooms,
            columns: [
                {
                    name: translations["room"] ?? "Soba",
                    html: `{{ soba }}`
                },
                {
                    name: "",
                    html: `<a href="javascript:void(0)" class="btn btn-primary btn-sm btn-reserve float-end" data-block="{{blok}}" data-room="{{soba}}">Rezerviraj</a>`
                }

            ]
        };
        return tableMap;
    }

    //private _renderDetailsModal(subscription: any): void {

    //    // Build a view model from the API data
    //    const viewModel = {
    //        data: subscription,
    //        translations: this._translationService.currentTranslations,
    //        "admin": this.isAdmin,
    //    } as any;
    //    // Construct a template
    //    const htmlTemplate = subscriptionDetailsModalTemplate;

    //    // Update the main elemnent's content in a manner that handles dangerous characters correctly
    //    const html = Mustache.render(htmlTemplate, viewModel);
    //    $('#subscription-details-modal').html(html);
    //}

    //public initSubscribtionModalButton() {
    //    var self = this;
    //    $(".subscription-details-btn").on("click", function (ev) {
    //        const target = ev.currentTarget as HTMLInputElement;
    //        var dataId = target.getAttribute("data-id");
    //        if (!dataId) {
    //            return;
    //        }
    //        var id = parseInt(dataId);

    //        self._renderDetailsModal(subscription);
    //        $("#subscription-details-modal").modal("show");
    //    });
    //}    

    public getFormators() {
        const userLocale = document.documentElement.lang
            ? document.documentElement.lang
            : 'en';
        moment.locale(userLocale);
        var localeFormat = moment().creationData().locale.longDateFormat("L");
        return {
            dateFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format(localeFormat);
                };
            },
            dateTimeFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format(localeFormat + ' HH:mm');
                };
            },
            timeRange: function () {
                return function (timestamp: any, render: any) { //3. April (19:30 - 20:15)
                    var timeString: string[] = render(timestamp).split(" ");
                    var time: Date[] = [];
                    time[0] = new Date(timeString[0]);
                    time[1] = new Date(timeString[1]);
                    if (time[0].getDate() == time[1].getDate() && time[0].getMonth() == time[1].getMonth()) {
                        return moment((time[0])).format('DD. MMM (HH:mm') + " - " + moment((time[1])).format('HH:mm)')
                    } else {
                        return moment((time[0])).format('DD. MMM (HH:mm)') + " - " + moment((time[1])).format('DD. MMM (HH:mm)')
                    }
                };
            }
        };
    }    
}