import { inject, injectable, interfaces } from "inversify";
import { PageBase } from "../../../../skupno/src/ts/routing/page-base";
import { MainLayout } from "../../views/main-layout";
import { Router } from "../../../../skupno/src/ts/routing/router";
import { ManagementUporabnikiView } from "../../views/management-uporabniki-view";
import { UserApiClient } from "../clients/users-api-client";
import { TranslationService } from "../translation-service";
import { Notyf } from "notyf";
import { ErrorLoadingView } from "../../views/error-loading-view";

@injectable()
export class ManagementUporabnikiPage extends PageBase {
    private _mainLayoutProvider: interfaces.Provider<MainLayout>;
    private _router: Router;
    private _managementUporabnikiView: ManagementUporabnikiView;
    private _apiClient: UserApiClient;
    private _translationService: TranslationService;
    private _notyf: Notyf;
    private _errorLoadingView: ErrorLoadingView;

    public constructor(@inject("Provider<MainLayout>") mainLayoutProvider: interfaces.Provider<MainLayout>,
        @inject("UserApiClient") apiClient: UserApiClient,
        @inject("Router") router: Router,
        @inject("TranslationService") translationService: TranslationService,
        @inject("Notyf") notyf: Notyf
    ) {
        super();
        this._mainLayoutProvider = mainLayoutProvider;
        this._apiClient = apiClient;
        this._router = router;
        this._translationService = translationService;
        this._notyf = notyf;
        this._managementUporabnikiView = new ManagementUporabnikiView(this._apiClient, this._translationService);
        this._errorLoadingView = new ErrorLoadingView(this._translationService);
    }

    public async execute(): Promise<boolean> {
        var url = this._router.getVirtualUrl();
        var urlDecoded = decodeURIComponent(url.pathname);

        //if (!url.pathname.startsWith("/management/uporabniki")) {
        if (!url.pathname.startsWith("/management/uporabniki") && !urlDecoded.includes("/management/uporabniki?page")) {
            return true;
        }

        var mainLayout = await this._mainLayoutProvider() as MainLayout; try {
            var translations = this._translationService.currentTranslations;
            mainLayout.pageTitle = translations.Management + " - <span class=\"purple\">" + translations.Users + "</span>";
            mainLayout.htmlTitle = translations.Management + " - " + translations.Users;
            mainLayout.headerUserViewShow();
            mainLayout.profileSidebarViewHide();
            await this._managementUporabnikiView.load();
        } catch (e) {
            // Report failures
            this._notyf.error(this._translationService.currentTranslations.LoadingError);
            this._errorLoadingView.load();
        }

        return false;
    }
}