import { inject, injectable } from "inversify";
import * as Mustache from "mustache";
import { Notyf } from "notyf";
import { UserApiClient } from "../ts/clients/users-api-client";
import { TranslationService } from "../ts/translation-service";
import bodyTemplate from "./set-email-modal.html";
import "jquery-validation-unobtrusive";
import { FullscreenLoader } from "./fullscreen-loader";
import { CouponsApiClient } from "../ts/clients/coupons-api-client";

@injectable()
export class SetEmailModal {

    constructor(@inject("UserApiClient") private userApiClient: UserApiClient,
        @inject("TranslationService") private translationService: TranslationService,
        @inject("Notyf") private notyf: Notyf,
        @inject("FullscreenLoader") private loader: FullscreenLoader,
        @inject("CouponsApiClient") private couponsApiClient: CouponsApiClient) {
    }

    private async ensureModal(): Promise<JQuery<HTMLElement>> {
        var modalElement = $("body #email-modal");
        var modalElementExist = modalElement.length > 0;
        
        if (!modalElementExist) {   
            var data = await this.couponsApiClient.getCampaignUiMessage();
            var couponMessage = null;
            if (data && data.message) {
                couponMessage = data.message;
            }
            var modalHtml = Mustache.render(bodyTemplate, {
                translations: this.translationService.currentTranslations,
                couponMessage: couponMessage
            });
            modalElement = $(modalHtml);
            $("body").append(modalElement);
            var formElement = $("#change-email-modal-form");
            $.validator.unobtrusive.parse(formElement);
            formElement.validate();
            formElement.on("submit", (ev) => this.onChangeEmailSubmit(ev));  
        }
        modalElement.modal({ backdrop: 'static', keyboard: false });
        modalElement.modal("show");      
        return modalElement;
    }

    private onChangeEmailSubmit(ev: JQuery.SubmitEvent) {
        ev.preventDefault();
        var translations = this.translationService.currentTranslations;

        var formElement = $("#change-email-modal-form");
        if (!formElement.valid()) {
            this.notyf.error(translations.CheckAllFields);
            return;
        }
        this.loader.show();
        var newEmail = formElement.find("[name=newEmail]").val() as string;

        this.userApiClient.profileChangeEmail(newEmail)
            .then(() => {
                this.notyf.success(translations.SaveSuccessful);
                this.hide();
                this.loader.hide();
            })
            .catch((ex) => {
                if (ex.statusCode == 400) {
                    var errorMessage = ex.message;
                    if (errorMessage) {
                        this.notyf.error(errorMessage);
                    }
                    else {
                        this.notyf.error(translations.UnexpectedError);
                    }
                }
                else {
                    console.log(ex);
                    this.notyf.error(translations.UnexpectedError);
                }
                this.loader.hide();
            });
    }   

    public show() {
        this.ensureModal();
    }

    public hide() {
        var modalElement = $("body #email-modal");
        if (modalElement.length == 0) {
            return false;
        }
        modalElement.modal("hide");
    }
}