import { injectable } from "inversify";
import * as moment from "moment";
import * as Mustache from "mustache";
import { Notyf } from "notyf";
import { UrlHelper } from "../../../skupno/src/ts/utils/url-helper";
import { TableWidget, TableWidgetTableMap } from "../../../skupno/src/ts/widgets/table-widget";
import { StatementApiClient } from "../ts/clients/statements-api-client";
import { Statement } from "../ts/models/statement";
import { TranslationService } from "../ts/translation-service";
import template from "./izjave-seznam-view.html";

@injectable()
export class IzjaveSeznamView {
    private _apiClient: StatementApiClient; 
    private _translationService: TranslationService;
    private itemsPerPage = 10;

    public constructor(statementApiClient: StatementApiClient,
        translationService: TranslationService,
        private _notyf: Notyf) {
        this._apiClient = statementApiClient;         
        this._translationService = translationService;
    }
    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {

        try {
            // Try to get data
            //TODO: prebrati podatke
            this._renderData();
            var translations = this._translationService.currentTranslations;

            var tableWidget = new TableWidget({
                tableMap: this.dataTableModel(translations),
                rootElement: $("#main").find(".izjave-seznam-table-placeholder"),
                getData: async (currentPage) => {
                    if (currentPage == 1) {
                        var page = UrlHelper.getPageFromUrl();
                        if (page != 1) {
                            currentPage = page;
                        }
                    }
                    var statements = await this._apiClient.getItems(currentPage, this.itemsPerPage);
                    if (statements && statements.items) {
                        for (var i = 0; i < statements.items.length; i++) {
                            statements.items[i].isActive = this.isActive(statements.items[i]);
                        }
                    }
                    return statements;
                }
            });
            await tableWidget.refresh(1, this._translationService.currentTranslations);
            this.initButtons();

        } catch (e) {
            // Clear previous content on error
            $('#main').text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    public initButtons() {
        var translations = this._translationService.currentTranslations;

        $(".btn-download-statement").on('click', async (e) => {
            var id = Number(e.currentTarget.getAttribute("value"));
            var name = e.currentTarget.getAttribute("data-name") as string;
            if (id != null) {
                var downloadingNotyf = this._notyf.open({
                    type: "info",
                    message: translations.Downloading,
                    duration: 50000
                });
                await this._apiClient.downloadStatement(id)
                    .then((fileContent) => {
                        var blob = new Blob([fileContent.fileContent], { type: fileContent.fileType });
                        var url = URL.createObjectURL(blob);

                        var fileName: string = fileContent.fileName;
                        if (!fileName) {
                            fileName = name.toLowerCase().replace(/\s+/g, '_') + "_" + moment(new Date()).format("yyyyMMDDHHmm");
                            fileName = fileName.replace(/(_)_+|(\\.)\\.+|(-)-+/, "$1$2$3") + ".xlsx";
                        }

                        var fileLink = document.createElement('a');
                        fileLink.href = url;
                        fileLink.download = fileName;
                        fileLink.click();

                    })
                    .catch((ev) => {
                        console.log(ev)
                        this._notyf.error(translations.ErrorWhileDownloadingFile);
                    }).finally(() => {
                        this._notyf.dismiss(downloadingNotyf);
                    })
            }
        });
    }

    private isActive(item: Statement) {
        //var dateFrom = item.validFrom;
        var dateFrom = new Date(item.validFrom);
        //var dateTo = item.validTo;
        var dateTo = null;
        if (item.validTo) {
            dateTo = new Date(item.validTo);
        }
        var dateCheck = new Date();

        if (dateTo) {
            var r = dateCheck > dateFrom && dateCheck < dateTo;
            return r;
        }
        else {
            var r = dateCheck > dateFrom;
            return r;
        }
    }


    private _renderData(): void {
        // Build a view model from the API data
        const viewModel = {
            "imageUrlLogout": "../img/icon-logout.svg",
            "imageUrlDownload": "../img/icon-download.svg",
            "imageUrlDocument": "../img/icon-document.svg",
            "imageUrlGreenDot": "../img/icon-green-dot.svg",
            translations: this._translationService.currentTranslations
        } as any;

        // Construct a template
        const htmlTemplate = template;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#main').html(html);

    }

    private dataTableModel(translations: any): TableWidgetTableMap {
        const tableMap = {
            title: translations["Statements"] ?? "Izjave",
            classInsert: "izjave-table-placeholder",
            controls: '<a href="/#/izjave/dodaj" class="add">' + translations["AddStatement"] + '</a>',
            noDataContent: translations.NoData,
            columns: [
                {
                    name: translations["subject1"] ?? "Zadeva",
                    html: `{{ name }}`
                },
                //{
                //    name: "",
                //    html: '<a href="javascript:void(0)" class="btn btn-sm btn-outline-primary btn-download-statement" data-name="{{name}}" value="{{id}}">' + translations["Download1"] + '</a>',
                //},
                {
                    name: translations["CrmId"] ?? "Crm ID",
                    html: ` {{ crmId }}`
                },
                {
                    name: translations["ValidFrom"] ?? "Veljavno od",
                    html: `{{#formators}}{{#dateFormat}}{{validFrom}}{{/dateFormat}}{{/formators}}`
                },
                {
                    name: translations["ValidTo"] ?? "Veljavno do",
                    html: `{{#formators}}{{#dateFormat}}{{validTo}}{{/dateFormat}}{{/formators}}`
                },
                {
                    name: translations["Status"] ?? "Status",
                    html: `{{ #isActive }}
                                        <img src="../img/icon-green-dot-big.svg" class="img-fluid">
                                        {{ /isActive }}
                                        {{ ^isActive }}
                                        <img src="../img/icon-gray-dot-big.svg" class="img-fluid">
                                        {{ /isActive }}`
                },
                {
                    name: ` <img src="../img/icon-download.svg" class="img-fluid" />`,
                    html: ` <a href="javascript:void(0)" class="btn-download-statement" data-name="{{name}}" value="{{id}}">
                                                <img src="../img/icon-document.svg" class="img-fluid td-icon" />
                                                <span>XLS</span>
                                            </a>`
                },
                {
                    class: "text-center",
                    name: "&nbsp;",
                    html: "<a href=\"#/izjave/uredi/{{id}}\"><img src=\"../img/icon-edit.svg\" class= \"img-fluid td-icon\" /></a>"
                }

            ]
        };
        return tableMap;
    }
}