import { inject, injectable } from "inversify";
import { Configuration } from "../../../../skupno/src/ts/configuration/configuration";
import { HelpCategory } from "../models/help-category";

@injectable()
export class HelpClient  {
    public constructor(
        @inject("Configuration") private configuration: Configuration) {
    }

    public async getSlides(language: string): Promise<Array<HelpCategory>> {
        return await $.ajax({
            url: this.configuration.app.webOrigin + "/help/" + language +"/slides.json",
            type: "GET",
            crossDomain: true,
            dataType: "json"
        });
    }
} 