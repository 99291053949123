import { injectable } from "inversify";
import * as moment from "moment";
import * as Mustache from "mustache";
import { NotificationApiClient } from "../ts/clients/notifications-api-client";
import { Notification } from "../ts/models/notification";
import { TranslationService } from "../ts/translation-service";
import template from "./obvestila-podrobno-view.html";

@injectable()
export class ObvestilaPodrobnoView {
    private _apiClient: NotificationApiClient;
    private _translationService: TranslationService;

    public constructor(notificationApiClient: NotificationApiClient, translationService: TranslationService) {
        this._apiClient = notificationApiClient;
        this._translationService = translationService;
    }
    /*
     * Wait for data then render it
     */
    public async load(idNotification: any): Promise<void> {
        var translations = this._translationService.currentTranslations;
        try {
            // Try to get data
            //TODO: prebrati podatke
            await this._apiClient.envelope(idNotification)
                .then((list) => {
                    // Render new content
                    this._renderData(list);
                })
                .catch((ex) => {
                    if (ex.statusCode == 400) {
                        $('#main').text(translations[ex.message]);
                    }
                    else {
                        $('#main').text(translations["LoadingError"]);
                    }
                });

        } catch (e) {
            console.log(e);
            // Clear previous content on error
            $('#main').text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _renderData(data: Notification): void {
        // Build a view model from the API data
        const viewModel = {
            "subject": data.subject,
            "created":data.created,
            "author": data.author ? ( data.author.firstName + " " + data.author.lastName) : "",
            "body": data.body,
            "attachments": data.attachments,
            "hasAttachments": data.attachments && data.attachments.length > 0,
            translations: this._translationService.currentTranslations,
            formatDateTime: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format("L LT");
                };
            }
        } as any;

        // Construct a template
        const htmlTemplate = template;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#main').html(html);

        $('#main').find("[data-action=download]").on("click", async (ev) => {
            ev.preventDefault();
            var priponkaId = parseInt($(ev.currentTarget).attr("data-id") as string, 10);
            var fileContent = await this._apiClient.attachmentDownload(priponkaId);
            

            var blob = new Blob([fileContent.fileContent], { type: fileContent.fileType });
            var url = URL.createObjectURL(blob);

            var fileName: string = fileContent.fileName;

            if (!fileName) {
                fileName = $(ev.currentTarget).attr("download") as string;
                fileName = fileName.toLowerCase().replace(/\s+/g, '_');
                fileName = fileName.replace(/(_)_+|(\\.)\\.+|(-)-+/, "$1$2$3");
            }

            var fileLink = document.createElement('a');
            fileLink.href = url;
            fileLink.download = fileName;
            fileLink.click();

        });

    }
}