import { inject, injectable } from "inversify";
import * as moment from "moment";
import * as Mustache from "mustache";
//import { Notyf } from "notyf";
import { SportEventApiClient } from "../ts/clients/sport-event-api-client";
import { FitnessCardResponse } from "../ts/models/fitness-card-details-response";
import { TranslationService } from "../ts/translation-service";
import bodyTemplate from "./sport-fitness-kartica-log-modal.html"

@injectable()
export class SportFitnesKarticaLogModal {
    static html: string = `
    <div class="modal modal-sporfitnes-kartica-log details-log" tabindex="-1">
  <div class="modal-dialog modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-body">
      </div>
    </div>
  </div>
</div>`;

    private _logChanged: boolean = false;
    private _fitnessCardId: number | undefined = undefined;
    private _modalElement: JQuery<HTMLElement> = null!;
    private messageWindow: boolean = true;
 

    constructor(@inject("SportEventApiClient") private sportEventApiClient: SportEventApiClient,
        @inject("TranslationService") private translationService: TranslationService
        //@inject("Notyf") private notyf: Notyf
    ) {
    }

    private ensureModal(): JQuery<HTMLElement> {
        var modalElement = $("body .modal-sporfitnes-kartica-log");
        var modalElementExist = modalElement.length > 0;

        var modalHtml = Mustache.render(SportFitnesKarticaLogModal.html, {
            translations: this.translationService.currentTranslations
        });
        if (modalElementExist) {
            //modalElement.replaceWith(modalHtml); //duplicira se element iz neznanega razloga
        }
        else {
            modalElement = $(modalHtml);
            $("body").append(modalElement);
        }

        modalElement.find(".modal-body").html(`<div class="text-center"><div class="spinner-grow" style="width: 3rem; height: 3rem;" role="status"></div></div>`)
        modalElement.modal("show");
        this._modalElement = modalElement;        
        return modalElement;
    }

    private renderContent(response: FitnessCardResponse): void {
        let details: any = response.fitnessCardDetails;
        details.fitnessCardLogs.forEach((value: any) => {
            var item = value as any;
            item["logType" + value.logType] = true;
        });
        (details as any).translations = this.translationService.currentTranslations;
        (details as any).dateTimeFormat = function () {
            return function (timestamp: any, render: any) {
                return moment(render(timestamp).trim(), "HH:m").format("L LT");
            };
        }        
        var bodyHtml = Mustache.render(bodyTemplate,
            {
                "fitnessCardLogs": details.fitnessCardLogs,
                "fitnessCard": details.fitnessCard,
                "messageWindow": this.messageWindow
            });
        this._modalElement.find(".modal-body").html(bodyHtml);
        var imaSliko: boolean | undefined = response.streamImage != null;
        console.log(response.streamImage)
        console.log(imaSliko);
        if (imaSliko) this._modalElement.find("#pfp").attr("src", `data:image/png;base64,${response.streamImage}`);
    }

    private refreshContent(resolve:any, reject:any) {
        var modalElement = this._modalElement;
        this.sportEventApiClient.fitnessCardDetails(this._fitnessCardId!)
            .then((details) => {
                this.renderContent(details);
                modalElement.one("hide.bs.modal", () => {
                    resolve(this._logChanged);
                });

                var formElement = modalElement.find("#fitnes-log-form");
                formElement.on("submit", (ev) => {
                    ev.preventDefault();
                    var message = formElement.find("[name=message]").val() as string;
                    this.sportEventApiClient.fitnessCardCreateLog(this._fitnessCardId!, message)
                        .then(() => {
                            this.refreshContent(resolve,reject);
                        });
                })
                //$(".btn-download").on("click", (ev) => {                    
                //    ev.preventDefault();
                //    var downloadingNotyf = this.notyf.open({
                //        type: "info",
                //        message: this.translationService.currentTranslations.Downloading,
                //        duration: 50000
                //    });
                //    this.sportEventApiClient.fitnessCardDownload(this._fitnessCardId!)
                //        .then((fileContent) => {
                //            var blob = new Blob([fileContent.fileContent], { type: fileContent.fileType });
                //            var url = URL.createObjectURL(blob);

                //            var fileName: string = fileContent.fileName;

                //            if (!fileName) {
                //                fileName = "Fitness_Card_" + this._fitnessCardId;
                //            }

                //            var fileLink = document.createElement('a');
                //            fileLink.href = url;
                //            fileLink.download = fileName;
                //            fileLink.click();

                //        })
                //        .catch(() => {
                //            this.notyf.error(this.translationService.currentTranslations.ErrorWhileDownloadingFile);
                //        }).finally(() => {
                //            this.notyf.dismiss(downloadingNotyf);
                //        })
                //})

            })
            .catch((error) => {
                reject(error);
            });   
    }

    public show(fitnessCardId: number, messageWindow: boolean = true): Promise<boolean> {
        this.messageWindow = messageWindow;
        this._logChanged = false;
        this._fitnessCardId = fitnessCardId;
        this.ensureModal();
  
        var promise = new Promise<boolean>((resolve, reject) => {
            this.refreshContent(resolve, reject);
        });
        return promise;

        
    }

    public hide(): boolean {
        var modalElement = $("body .modal-sporfitnes-kartica-log");
        if (modalElement.length == 0) {
            return false;
        }
        modalElement.modal("hide");
        return this._logChanged;
    }
}