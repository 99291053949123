import { injectable } from "inversify";
import { UserApiClient } from "../ts/clients/users-api-client";
import * as Mustache from "mustache";
import mainView from "./profile-view.html";
import { Notyf } from "notyf";
import { TranslationService } from "../ts/translation-service";
import Quill from "quill";
import { CurrentUserInfo } from "../ts/models/current-user-info";
import { ProfileBasicDataSaveModel } from "../ts/models/profile-basic-data-save-model";

@injectable()
export class ProfileView {
    private _apiClient: UserApiClient;
    private _notyf: Notyf;
    private _oldLanguageCode: string | null = null;

    public constructor(userApiClient: UserApiClient, notyf: Notyf, private translationService: TranslationService) {
        this._apiClient = userApiClient;
        this._notyf = notyf;
    }
    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {
        try {

            this.loadUserDetails();

        } catch (e) {

            // Clear previous content on error
            $('#main').text(this.translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private async loadUserDetails(): Promise<void> {
        var userDetails: CurrentUserInfo = await this._apiClient.userDetails(0) as any;
        var viewModel = {
            user: userDetails,
            notificationTypes: this.razporediPoStolpcih(userDetails),
            translations: this.translationService.currentTranslations
        };
        const html = Mustache.render(mainView, viewModel);
        $("#main").html(html);

        $("#profile-basic-form").on("submit", (ev) => this.onBasicDataSubmit(ev));
        $("#uporabniki-vloge-form").on("submit", (ev) => this.onVlogeSubmit(ev, 0));
        $("#change-email-form").on("submit", (ev) => this.onChangeEmailSubmit(ev));
        $("#change-password-form").on("submit", (ev) => this.onChangePasswordSubmit(ev));
        
        this._initQuillEditor();
        this._oldLanguageCode = userDetails.languageCode;
    }

    private onBasicDataSubmit(ev: JQuery.SubmitEvent) {
        ev.preventDefault();
        var formElement = $("#profile-basic-form");
        var newLanguageCode = formElement.find("[name=language]").val() as string;

        var userData: ProfileBasicDataSaveModel = {
            phoneNumber: formElement.find("[name=phone]").val() as string,
            languageCode: newLanguageCode,
            notificationTypes: formElement.find("[name=notificationType]:checked").map((_index, element) => parseInt($(element).attr("value") as string, 10)).toArray(),
            signature: formElement.find(".editor-container .ql-editor").html()
        };

        var translations = this.translationService.currentTranslations;
        
        this._apiClient.profileSaveBasicData(userData)
            .then(() => {
                this._notyf.success(translations.SaveSuccessful);
                if (this._oldLanguageCode != newLanguageCode) {
                    window.location.reload();
                }
                else {
                    this.loadUserDetails();
                }
            })
            .catch((ex) => {
                if (ex.statusCode == 400) {
                    this._notyf.error(translations.SaveError + "\n" + ex.message);
                }
                else {
                    this._notyf.error(translations.SaveError);
                }
            });
    }

    private onVlogeSubmit(ev: JQuery.SubmitEvent, userId: number) {
        ev.preventDefault();

        var translations = this.translationService.currentTranslations;
        var izbraneVloge = $("#uporabniki-vloge-form [name=vloga]:checked").map((_index, element) => parseInt($(element).attr("value") as string, 10)).toArray();
        this._apiClient.userDetailsVlogeShrani(userId, izbraneVloge)
            .then(() => {
                this.loadUserDetails();
                this._notyf.success(translations.SaveSuccessful);
            })
            .catch((ex) => {
                if (ex.statusCode == 400) {
                    this._notyf.error(translations.SaveError + "\n" + ex.message);
                }
                else {
                    this._notyf.error(translations.SaveError);
                }
            });
    }

    private onChangeEmailSubmit(ev: JQuery.SubmitEvent) {
        ev.preventDefault();

        var translations = this.translationService.currentTranslations;
        var newEmail = $("#change-email-form [name=newEmail]").val() as string;

        this._apiClient.profileChangeEmail(newEmail)
            .then(() => {
                this.loadUserDetails();
                this._notyf.success(translations.SaveSuccessful);
            })
            .catch((ex) => {
                if (ex.statusCode == 400) {
                    this._notyf.error(translations.SaveError + "\n" + ex.message);
                }
                else {
                    this._notyf.error(translations.SaveError);
                }
            });
    }

    private onChangePasswordSubmit(ev: JQuery.SubmitEvent) {
        ev.preventDefault();

        var translations = this.translationService.currentTranslations;
        var newPassword = $("#change-password-form [name=newPassword]").val() as string;
        var repeatNewPassword = $("#change-password-form [name=repeatNewPassword]").val() as string;
        if (newPassword != repeatNewPassword) {
            this._notyf.error(translations.NewPasswordAndNewRepeatedPasswordAreNotTheSame);
            return;
        }
        
        this._apiClient.profileChangePassword(newPassword)
            .then(() => {
                this.loadUserDetails();
                this._notyf.success(translations.SaveSuccessful);
            })
            .catch((ex) => {
                if (ex.statusCode == 400) {
                    this._notyf.error(translations.SaveError + "\n" + ex.message);
                }
                else {
                    this._notyf.error(translations.SaveError);
                }
            });
    }

    //inicializira RTE
    private _initQuillEditor() {

        var ColorClass = Quill.import('attributors/class/color');
        var SizeStyle = Quill.import('attributors/style/size');
        var Bold = Quill.import('formats/bold');
        Quill.register(ColorClass, true);
        Quill.register(SizeStyle, true);
        Bold.tagName = 'B';   // Quill uses <strong> by default
        Quill.register(Bold, true);
        var toolbarOptions = [
            [{ container: "#toolbar-container" }],
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['blockquote', 'code-block'],

            [{ 'header': 1 }, { 'header': 2 }],               // custom button values
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
            [{ 'direction': 'rtl' }],                         // text direction

            [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            [{ 'font': [] }],
            [{ 'align': [] }],

            ['clean']                                         // remove formatting button
        ];

        let mainElement: JQuery<HTMLElement> = $('#main');

        let messageEditorElement = mainElement.find(".editor-container")[0];
        var editor = new Quill(messageEditorElement, {
            modules: {
                toolbar: toolbarOptions
            },
            theme: "snow"
        });
        $(messageEditorElement).data("quill", editor);
    }


    private razporediPoStolpcih(userDetails: CurrentUserInfo): Array<any> {
        var izbraneVloge = userDetails.selectedNotificationTypes;
        var vseVloge = userDetails.allNotificationTypes;
        var rezultat = new Array<any>(vseVloge.length);

        var trenutniStolpecIndex = 0;
        for (var i = 0; i < vseVloge.length; i++, trenutniStolpecIndex++) {
            var vloga = vseVloge[i];
            var vlogaSelected = izbraneVloge.indexOf(vloga.id) >= 0;

            rezultat[i] = {
                id: vloga.id,
                name: vloga.name,
                locked: vloga.isLocked == 1,
                selected: vlogaSelected
            };

        }
        return rezultat;
    }
}