import { inject, injectable, interfaces } from "inversify";
import { PageBase } from "../../../../skupno/src/ts/routing/page-base";
import { MainLayout } from "../../views/main-layout";
import { Router } from "../../../../skupno/src/ts/routing/router";
import { SportEventApiClient } from "../clients/sport-event-api-client";
import { TranslationService } from "../translation-service";
import { Notyf } from "notyf";
import { ErrorLoadingView } from "../../views/error-loading-view";
import { SportPaketPrijavljeniView } from "../../views/sport-paket-prijavljeni-view";

@injectable()
export class SportPaketPrijavljeniPage extends PageBase {
    public name = "SportPaketPrijavljeniPage";
    private _apiClient: SportEventApiClient;
    private _mainLayoutProvider: interfaces.Provider<MainLayout>;
    private _router: Router;
    private _translationService: TranslationService;
    private _sportPaketiPrijavljeniView: SportPaketPrijavljeniView;
    private _notyf: Notyf;
    private _errorLoadingView: ErrorLoadingView;

    public constructor(@inject("Provider<MainLayout>") mainLayoutProvider: interfaces.Provider<MainLayout>,
        @inject("SportEventApiClient") apiClient: SportEventApiClient,
        @inject("Router") router: Router,
        @inject("TranslationService") translationService: TranslationService,
        @inject("Notyf") notyf: Notyf
    ) {
        super();
        this._mainLayoutProvider = mainLayoutProvider;
        this._apiClient = apiClient;
        this._notyf = notyf;
        this._router = router;
        this._translationService = translationService;
        this._sportPaketiPrijavljeniView = new SportPaketPrijavljeniView(this._apiClient, this._translationService, this._notyf);
        this._errorLoadingView = new ErrorLoadingView(this._translationService);
    }

    public async execute(): Promise<boolean> {
        var url = this._router.getVirtualUrl();
        if (!url.pathname.startsWith("/sport/paket/prijavljeni/")) {
            return true;
        }
        var packageId: number | null = null;
        var packageIdString: string = url.pathname.substr("/sport/paket/prijavljeni/".length);
        if (packageIdString) {
            packageId = parseInt(packageIdString, 10);
        }
        if (!packageId) {
            return true;
        }

        var mainLayout = await this._mainLayoutProvider() as MainLayout; try {
            var item = await this._apiClient.getPackage(packageId);
            var translations = this._translationService.currentTranslations;
            mainLayout.pageTitle = translations.Sport + " - <span class=\"purple\">" + translations.Subscriptions + " - " + item.title + "</span>";
            mainLayout.htmlTitle = translations.Sport + " - " + translations.Subscriptions + "-" + translations.Overview;
            mainLayout.profileSidebarViewHide();
            await this._sportPaketiPrijavljeniView.load(item);
        } catch (e) {
            // Report failures
            console.log(e);
            this._notyf.error(this._translationService.currentTranslations.LoadingError);
            this._errorLoadingView.load();
        }

        return false;
    }
}