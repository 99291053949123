import { injectable } from "inversify";
import * as moment from "moment";
import * as Mustache from "mustache";
import { Notyf } from "notyf";
import { SportEventApiClient } from "../ts/clients/sport-event-api-client";
import { FitnessCardResponse } from "../ts/models/fitness-card-details-response";
import { TranslationService } from "../ts/translation-service";
import template from "./sport-moja-fitnes-kartica-view.html";


@injectable()
export class SportMojaFitnesKarticaView {
    private _apiClient: SportEventApiClient;
    private _translationService: TranslationService;
    private _file: Blob;
    private _uploadFilesElement: HTMLInputElement | null;    

    public constructor(sportEventApiClient: SportEventApiClient, translationService: TranslationService, private notyf: Notyf) {
        this._apiClient = sportEventApiClient;
        this._translationService = translationService;
        this._file = new Blob();
        this._uploadFilesElement = null;
        
    }
    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {

        try {
            // Try to get data
            var translations = this._translationService.currentTranslations;
            var data = null;
            try {
                data = await this._apiClient.fitnessCard();          
            } catch (e:any) {
                if (e.statusCode != 404) {
                    this.notyf.error(translations.RejectError);
                }
                console.log(e);
            }   

        } catch (e) {

            // Clear previous content on error
            $('#main').text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
        this._renderData(data);
        data = null;
    }


    private _renderData(details: FitnessCardResponse | null): void {
        if (details == null) {
            details = {
            };
        }
        if (details.fitnessCardDetails != null) {
            details.fitnessCardDetails!.fitnessCardLogs.forEach((value) => {
                var item = value as any;
                item["logType" + value.logType] = true;
            });
        }
        var card = details.fitnessCardDetails
        var logs: any = null;
        if (card != null) logs = card.fitnessCardLogs;
        var detail: any = null;
        if (card != null) detail = card.fitnessCard;
        (details as any).translations = this._translationService.currentTranslations;
        (details as any).dateTimeFormat = function () {
            return function (timestamp: any, render: any) {
                return moment(render(timestamp).trim(), "HH:m").format("L LT");
            };
        }
        
        var status = details.fitnessCardDetails?.fitnessCard.status!;
        var imaSliko: boolean | undefined = details.streamImage != null;
        var zavrnjeno: boolean | undefined = undefined;
        var uvodnaStran: boolean | undefined = undefined;
        if (!details.fitnessCardDetails) uvodnaStran = true;
        switch(details.fitnessCardDetails?.fitnessCard.status!){
            case 0:
                if (imaSliko != true) uvodnaStran = true;                
                break;
            case 1:
                zavrnjeno = true;
                break;
        }
        var bodyHtml = Mustache.render(template,
            {
                "fitnessCardLogs":logs,
                "fitnessCard": detail,
                "qrCode": details.qrCodeImage,
                "statusText": this.getStatusHTML(status),
                "zavrnjeno": zavrnjeno,
                "uvodnaStran": uvodnaStran,
                translations: this._translationService.currentTranslations
            });

        $("#main").html(bodyHtml); 
        if (imaSliko) $("#main").find(".fitnes-card-image").attr("src", `data:image/png;base64,${details.streamImage}`);
        if (uvodnaStran || zavrnjeno) this._initUploadField();
    }

    private _renderAttachments(): void {
        if (this._file.size != 0) {
            $("#main").find(".fitnes-card-image").attr("src", URL.createObjectURL(this._file));
        }
    }

    public _initUploadField() {
        this._uploadFilesElement = document.getElementById("attachments") as HTMLInputElement;
        this._uploadFilesElement.onchange = (ev: Event) => this._onUploadChange(ev);
        var addButton = document.getElementById("confirm-attachment-btn") as HTMLElement;
        addButton.addEventListener("click", () => this._onUploadConfirm());
    }


    public async _onUploadChange(ev: Event) {
        var th = this;
        const target = ev.target as HTMLInputElement;
        let list: FileList | null = target.files;
        if (list) {
            th._file = list[0];
        }
        $('.upload-accept-btn')[0].hidden = false;
        this._renderAttachments();

    }

    private async _onUploadConfirm() {
        var formData = new FormData();
        formData.append("attachments", this._file);
        await this._apiClient.UpdateImageFitnessCard(formData)
            .catch(() => {                
                this.notyf.error(this._translationService.currentTranslations.Error);
            });
        this.load();
    }

    private getStatusHTML(status: number) {
        switch (status) {
            case 1:
                return `<div class="rejected"></div>` //this._translationService.currentTranslations.ImageDenied
            case 2:
                return `<div class="slika"></div>`
            case 0:
                return `<div class="waiting"></div>` //this._translationService.currentTranslations.ImageWaiting
            default:
                
        }
    }


//    private insertBtns(): void {
//        console.log("insert butnos")
//        const buttonsHTML = `
//<div class="add-btn">
//                                <label for="attachments">
//                                    <a class="add-img-btn btn btn-primary">
//                                        {{translations.ChangeImage}}
//                                        <input type="file" id="attachments" name="attachments" style="display:none" />
//                                    </a>
//                                </label>
//                            </div>
//                            <div class="add-confirm" hidden>
//                                <a href="javascript:void(0)" class="upload-accept-btn btn btn-primary" id="confirm-attachment-btn">{{translations.SendRequest}}</a>
//                            </div>
//`;
//        var bodyHtml = Mustache.render(buttonsHTML,
//            {
//                translations: this._translationService.currentTranslations
//            });
//        $("#main").find(".modal-upload-buttons").html(bodyHtml);
//        this._renderAttachments();
//        this._initUploadField();
//    }
}