import { injectable } from "inversify";
import * as Mustache from "mustache";
import { TranslationService } from "../ts/translation-service";
import template from "./internet-zacasni-uporabniki-uredi-view.html";

@injectable()
export class InternetZacasniUporabnikiUrediView {
    private _translationService: TranslationService;

    public constructor(translationService: TranslationService) {
        this._translationService = translationService;
    }

    public async load(): Promise<void> {

        try {
            // Try to get data
            //TODO: prebrati podatke
            // Render new content
            this._renderData();

        } catch (e) {

            // Clear previous content on error
            $('#main').text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _renderData(): void {

        // Build a view model from the API data
        const viewModel = {
            "imageUrlLogout": "../img/icon-logout.svg",
            "imageUrlSoftwareAndroid": "../img/software-android.svg",
            "imageUrlSoftwareLinux": "../img/software-linux.svg",
            "imageUrlSoftwareOSX": "../img/software-osx.svg",
            "imageUrlSoftwareWindows": "../img/software-windows.svg",
            "imageUrlSoftwareIos": "../img/software-ios.svg",
            "imageUrlGreenDot": "../img/icon-green-dot.svg",
            "imageUrlEdit": "../img/icon-edit.svg",
            "imageUrlArrowDown": "../img/icon-arrow-down.svg",
            "imageUrlIconInfo": "../img/icon-info.svg",
            translations: this._translationService.currentTranslations
        } as any;

        // Construct a template
        const htmlTemplate = template;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#main').html(html);
    }
}