import { Configuration } from "./configuration";

export class ConfigurationLoader {

    public static async download(configurationPath: string): Promise<Configuration> {

        try {
            // You can make multiple ajax calls
            // Response data is stored in result
            return await $.getJSON(configurationPath);
        } catch (error) {
            console.log('Error reading configuration: ', error);
            throw new Error('Error reading configuration');
        }
    }
}