import { injectable } from "inversify";
import * as Mustache from "mustache";
import { UrlHelper } from "../../../skupno/src/ts/utils/url-helper";
import { TableWidget, TableWidgetTableMap } from "../../../skupno/src/ts/widgets/table-widget";
import { StatementApiClient } from "../ts/clients/statements-api-client";
import { TranslationService } from "../ts/translation-service";
import template from "./izjave-view.html";

@injectable()
export class IzjaveView {
    private _apiClient: StatementApiClient; 
    private _translationService: TranslationService;
    private itemsPerPage = 10;

    public constructor(statementApiClient: StatementApiClient, translationService: TranslationService) {
        this._apiClient = statementApiClient;         
        this._translationService = translationService;
    }
    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {

        try {
            // Try to get data
            //TODO: prebrati podatke
            this._renderData();
            var translations = this._translationService.currentTranslations;

            var tableWidget = new TableWidget({
                tableMap: this.dataTableModel(translations),
                rootElement: $("#main").find(".izjave-table-placeholder"),
                getData: async (currentPage) => {
                    if (currentPage == 1) {
                        var page = UrlHelper.getPageFromUrl();
                        if (page != 1) {
                            currentPage = page;
                        }
                    }
                    var response = await this._apiClient.getMyStatements(currentPage, this.itemsPerPage);
                    if (document?.documentElement?.lang == "en") {
                        for (var i = 0; i < response.items.length; i++) {
                            response.items[i].name = response.items[i].nameEn;
                        }
                    }
                    return response;
                }
            });
            tableWidget.refresh(1, this._translationService.currentTranslations);


        } catch (e) {
            // Clear previous content on error
            $('#main').text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }


    private _renderData(): void {
        // Build a view model from the API data
        const viewModel = {
            "imageUrlLogout": "../img/icon-logout.svg",
            "imageUrlDownload": "../img/icon-download.svg",
            "imageUrlDocument": "../img/icon-document.svg",
            "imageUrlGreenDot": "../img/icon-green-dot.svg",
            translations: this._translationService.currentTranslations
        } as any;

        // Construct a template
        const htmlTemplate = template;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#main').html(html);

    }

    private dataTableModel(translations: any): TableWidgetTableMap {
        const tableMap = {
            title: translations["MyStatements"] ?? "Moje izjave",
            classInsert: "izjave-table-placeholder",
            noDataContent: translations.NoData,
            columns: [
                //{
                //    name: translations["ValidStart"] ?? "Začetek",
                //    html: "{{#formators}}{{#dateFormat}}{{validFrom}}{{/dateFormat}}{{/formators}}"
                //},
                //{
                //    name: translations["ValidEnd"] ?? "Konec",
                //    html: "{{#formators}}{{#dateFormat}}{{validTo}}{{/dateFormat}}{{/formators}}"
                //},
                //{
                //    name: translations["Sender"] ?? "Pošiljatelj",
                //    value: ""
                //},
                {
                    name: translations["subject1"] ?? "Zadeva",
                    html: `<a href="/#/izjave/{{ id }}">{{ name }}</a>`
                },
                {
                    name: translations["Submitted"] ?? "Oddano",
                    html: `{{ #statementsSignings }}
                                        {{#formators}}{{#dateFormat}}{{signed}}{{/dateFormat}}{{/formators}}
                                        {{ /statementsSignings }}
                                        {{ ^statementsSignings }}
                                        /
                                        {{ /statementsSignings }}`
                },
                {
                    name: "",
                    html: ` {{ #statementsSignings }}
                                        <span class="text-muted">Oddano</span>
                                        {{ /statementsSignings }}
                                        {{ ^statementsSignings }}
                                        <a href="/#/izjave/{{ id }}" class="btn btn-primary btn-sm">Oddaj</a>
                                        {{ /statementsSignings }}`
                },
                {
                    name: translations["Status"] ?? "Status",
                    html: `{{ #statementsSignings }}
                                        <img src="../img/icon-green-dot.svg" class="img-fluid">
                                        {{ /statementsSignings }}
                                        {{ ^statementsSignings }}
                                        &nbsp;
                                        {{ /statementsSignings }}`
                }

            ]
        };
        return tableMap;
    }
}