import * as moment from "moment";
import { ListResponse } from "../list-response";
import { Action, Domain } from "./revision-enums";

export interface Revision {
    id: number; 
    createdTime: string; 
    createdUserId: number;
    createdUserFirstName: string;
    createdUserLastName: string;
    action: Action; 
    description: string;
    ipAddress: string;
    domain: Domain;
    domainDataId: number;
    domainData: string;
    changeDataNew: string,
    changeDataOld: string,
    accessPath: string

}

export function parseRevisions(response: ListResponse<any>, translations: any): ListResponse<Revision> {
    response.items = response.items.map((item: any) => ({
        id: item.id,
        createdTime: moment.utc(item.createdTime).local().format("L LT"),
        createdUserId: item.createdUserId,
        createdUserFirstName: item.createdUserFirstName,
        createdUserLastName: item.createdUserLastName,
        action: translations[item.action], 
        description: item.description,
        ipAddress: item.ipAddress,
        domain: translations[item.domain],
        domainDataId: item.domainDataId,
        domainData: item.domainData
    }));

    return response;
}


export function parseRevision(item: Revision, translations: any): any {

    var data = {
        id: item.id,
        createdTime: moment.utc(item.createdTime).local().format("L LT"),
        createdUserId: item.createdUserId,
        createdUserFirstName: item.createdUserFirstName,
        createdUserLastName: item.createdUserLastName,
        action: translations[item.action], 
        description: item.description,
        ipAddress: item.ipAddress,
        domain: translations[item.domain],
        domainDataId: item.domainDataId,
        domainData: item.domainData,
        changeDataNew: item.changeDataNew,
        changeDataOld: item.changeDataOld,
        accessPath: item.accessPath

    };

    return data;
}
