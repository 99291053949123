import { inject, injectable, interfaces } from "inversify";
import { PageBase } from "../../../../skupno/src/ts/routing/page-base";
import { Notyf } from "notyf";
import { Router } from "../../../../skupno/src/ts/routing/router";
import { ErrorLoadingView } from "../../views/error-loading-view";
import { MainLayout } from "../../views/main-layout";
import { TranslationService } from "../translation-service";
import { InvoiceApiClient } from "../clients/invoice-api-client";
import { InvoicesView } from "../../views/invoice/invoices.view";

@injectable()
export class RacuniPage extends PageBase {
    public name = "RacuniPage";
    private _mainLayoutProvider: interfaces.Provider<MainLayout>;
    private _router: Router;
    private _apiClient: InvoiceApiClient;
    private _translationService: TranslationService;
    private _invoiceView: InvoicesView;
    private _notyf: Notyf;
    private _errorLoadingView: ErrorLoadingView;

    public constructor(
        @inject("Provider<MainLayout>") mainLayoutProvider: interfaces.Provider<MainLayout>,
        @inject("Router") router: Router,
        @inject("InvoiceApiClient") apiClient: InvoiceApiClient,
        @inject("TranslationService") translationService: TranslationService,
        @inject("Notyf") notyf: Notyf
    ) {
        super();
        this._mainLayoutProvider = mainLayoutProvider;
        this._router = router;
        this._apiClient = apiClient;
        this._translationService = translationService;
        this._notyf = notyf;
        this._invoiceView = new InvoicesView(this._apiClient, this._translationService, notyf);
        this._errorLoadingView = new ErrorLoadingView(this._translationService);
    }

    public async execute(): Promise<boolean> {
        var url = this._router.getVirtualUrl();
        var urlDecoded = decodeURIComponent(url.pathname);
        if (!url.pathname.endsWith("/racuni") && !urlDecoded.includes("/racuni?page")) {
            return true;
        }

        var mainLayout = await this._mainLayoutProvider() as MainLayout; try {
            var translations = this._translationService.currentTranslations;
            mainLayout.pageTitle = translations.Invoices;
            mainLayout.htmlTitle = translations.Invoices;
            mainLayout.headerUserViewShow();
            mainLayout.profileSidebarViewHide();
            await this._invoiceView.load();
        } catch (e) {
            // Report failures
            this._notyf.error(this._translationService.currentTranslations.LoadingError);
            this._errorLoadingView.load();
        }

        return false;
    }
}